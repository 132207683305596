import React from "react"
import { googleMapsURL, isObjectEmpty } from './../helper/utils'

let google = ''

const initMapScript = () => {
  return new Promise((resolve, reject) => {
    const mapScript = document.createElement('script')
    document.body.appendChild(mapScript)
    mapScript.onload = resolve
    mapScript.onerror = reject
    mapScript.async = true
    mapScript.src = googleMapsURL
    mapScript.id = 'gmaps_script'
  })
}

class MapWrapper extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      center: (this.props.location && this.props.location.lat && this.props.location.lng )
        ? { lat: parseFloat(this.props.location.lat), lng: parseFloat(this.props.location.lng) } 
        : { lat: 13.7636035, lng: 100.5369498 },
      destination: null,
      isMoved: false
    }
  }

  componentDidMount() {
    initMapScript()
      .then(() => {
        google = window.google
        this.initMap()
        // set lat/lng to hidden fields
        if (!isObjectEmpty(this.props.location)) {
          document.getElementById(`${this.props.model}_lat`).value = parseFloat(this.props.location.lat)
          document.getElementById(`${this.props.model}_lng`).value = parseFloat(this.props.location.lng)
          this.setState({isMoved: true})
        }
      })
  }

  componentWillUnmount() {
    if (document.getElementById('gmaps_script')) document.getElementById('gmaps_script').remove()
  }

  initMap () {
    this.map = new google.maps.Map(document.getElementById('map_wrapper'), {
      center: this.props.center ? {lat: parseFloat(this.props.center.lat), lng: parseFloat(this.props.center.lng)} : this.state.center,
      zoom: this.props.zoom ? this.props.zoom : 12,
      fullscreenControl: false,
      mapTypeControl: false,
      scaleControl: false,
      zoomControl: true,
      keyboardShortcuts: false,
      streetViewControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP
      },
      clickableLabels: false,
      clickableIcons: false
    })

    this.marker = new google.maps.Marker({
      position: this.props.center ? {lat: parseFloat(this.props.center.lat), lng: parseFloat(this.props.center.lng)} : this.state.center,
      title: 'Drag to your position',
      map: this.map,
      draggable: this.props.editable ? true : false, 
      animation: google.maps.Animation.DROP
    })

    if (this.props.editable) {
      google.maps.event.addListener(this.marker, 'dragend', e => {
        this.setState({
          destination: { lat: e.latLng.lat(), lng: e.latLng.lng() },
          isMoved: true,
        },() =>{
          let latField = document.getElementById(`${this.props.model}_lat`)
          let lngField = document.getElementById(`${this.props.model}_lng`)
          latField.value = e.latLng.lat()
          lngField.value = e.latLng.lng()
        } )
      })
    }
  }

  updateMap () {
    let lat = document.getElementById(`${this.props.model}_lat`).value
    let lng = document.getElementById(`${this.props.model}_lng`).value
    let newCenter = new google.maps.LatLng(lat,lng)
    this.map.panTo(newCenter)
    this.marker.setPosition(newCenter)
    this.map.setZoom(12)
    this.setState({isMoved: true})
  }

  render () {
    return (
      <React.Fragment>
        <div id="map_wrapper" style={{height: this.props.height}}>
        </div>
        <input type="hidden" name={`${this.props.model}[lat]`} id={`${this.props.model}_lat`} />
        <input type="hidden" name={`${this.props.model}[lng]`} id={`${this.props.model}_lng`} />
        <button type="button" style={{display: 'none'}} id="map_update_action" onClick={(e) => this.updateMap()}>update map</button>
      </React.Fragment>
    );
  }
}

export default MapWrapper
