import React from "react"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import { isObjectEmpty, tinyFESettings } from './../helper/utils'

class SequenceProgramPackage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentNumber: 1,
      editFirstItem: false,
      items: []
    }
  }

  componentDidMount() {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    if ( !isObjectEmpty(this.props.items) && JSON.parse(this.props.items).length > 0) {
      this.setState({ items: JSON.parse(this.props.items) }, () => {
        // add button event to all items
        let items = wrapper.querySelectorAll('.sequence-item')
        let currentNumber = 0
        items.forEach((item, index) => {
          this.addButtonEventToItem(item)
          if (index === 0) item.querySelector('._action').classList.add('disabled')
          currentNumber += 1
        })
        // update currentnumber
        this.setState({currentNumber})
      })
    } else {
      // add on click event to first item
      let firstItem = wrapper.querySelector('.sequence-item[data-number="1"]')
      this.addButtonEventToItem(firstItem)
      firstItem.querySelector('._action').classList.add('disabled')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let firstItem = wrapper.querySelector('.sequence-item:first-child')
    nextState.editFirstItem
      ? firstItem.querySelector('._action').classList.remove('disabled')
      : firstItem.querySelector('._action').classList.add('disabled')

    return true
  }

  reOrderItems = () => {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let items = wrapper.querySelectorAll('.sequence-item')
    let lastItemNumber = 1
    items.forEach((el, index) => {
      let lastIndex = index + 1
      el.dataset.number = lastIndex
      lastItemNumber = lastIndex
    })
    return lastItemNumber
  }

  sortItems = () => {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let items = wrapper.querySelectorAll('.sequence-item')
    items = [].map.call(items, (elm) => elm)
    items.sort((a, b) => {
      if (parseInt(a.dataset.number) > parseInt(b.dataset.number)) return 1
      if (parseInt(a.dataset.number) < parseInt(b.dataset.number)) return -1
      return 0
    })
    items.forEach(item => wrapper.appendChild(item))
  }

  addMoreItem = (e) => {
    if (e) e.preventDefault()
    let newNumber = parseInt(this.state.currentNumber) + 1
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let newItem = this.packageSequenceItemTemplate(newNumber)
    wrapper.insertAdjacentHTML('beforeend', newItem)
    // add new event to last added element
    let recentAddedItem = wrapper.querySelector(`.sequence-item[data-number="${newNumber}"]`)
    this.addButtonEventToItem(recentAddedItem, newNumber)
    this.setState({ currentNumber: newNumber, editFirstItem: true })
  }

  addButtonEventToItem = (el, number) => {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    
    let removeButton = el.querySelector('.remove-sequence')
    let moveButton = el.querySelectorAll('.move-sequence')
    // init editor
    tinymce.init(tinyFESettings)
    // add field event to new added item
    let numberField = el.querySelector('.jd-numeric')
    if (numberField) {
      numberField.addEventListener('input', e => {
        let el = e.currentTarget
        el.value = el.value.replace(/[^0-9\.]/g, '')
      })
    }
    // remove event
    removeButton.addEventListener('click', () => {
      el.remove()
      let lastItemNumber = this.reOrderItems()
      wrapper.querySelectorAll('.sequence-item').length <= 1
        ? this.setState({ editFirstItem: false })
        : this.setState({ editFirstItem: true })
      this.setState({ currentNumber: lastItemNumber })
    })
    // move event
    moveButton.forEach(button => {
      button.addEventListener('click', () => {
        let thisSequence = parseInt(el.dataset.number)
        let direction = button.dataset.direction
        let newSequence = direction === 'up' ? thisSequence - 1 : thisSequence + 1
        let targetRow = direction === 'up' ? el.previousElementSibling : el.nextElementSibling
        // if target existed, swap the number
        if (targetRow) {
          targetRow.setAttribute('data-number', thisSequence)
          el.setAttribute('data-number', newSequence)
          this.sortItems()
        }
      })
    })
  }

  packageSequenceItemTemplate = (number, item = null) => {
    return `
      <div class="sequence-item" data-number="${number}">
        <div class="_action">
          <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
        </div>
        <div class="_form">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="control-label">ชื่อแพ็คเกจ <span class="req">*</span></label>
                <input type="text" class="form-control program-package-name" ${item ? `value="${item.name}"` : ''} />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="control-label">ราคาเริ่มต้น <span class="req">*</span></label>
                <input type="text" class="form-control program-package-price" ${item ? `value="${item.price}"` : ''} />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label class="control-label">รายละเอียดของแพ็คเกจ <span class="req">*</span></label>
                <input type="text" class="form-control program-package-details fe-editor" id="package_details_${number}" ${item ? `value="${item.details}"` : ''} />
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  render () {
    return (
      <React.Fragment>
        <div className="sequence-wrapper" id={`${this.props.model}_package`}>
        {
          this.state.items.length > 0
          ? this.state.items.map( (item,index) => {
            let number = index + 1
            return (
              <div className="sequence-item" data-number={number} key={number}>
                <div className="_action">
                  <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
                </div>
                <div className="_form">
                  <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="control-label">ชื่อแพ็คเกจ <span className="req">*</span></label>
                      <input type="text" className="form-control program-package-name" defaultValue={item.name} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="control-label">ราคาเริ่มต้น <span className="req">*</span></label>
                      <input type="text" className="form-control program-package-price" defaultValue={item.price} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="control-label">รายละเอียดของแพ็คเกจ <span className="req">*</span></label>
                      <input type="text" className="form-control program-package-details fe-editor" id={`package_details_${number}`} defaultValue={item.details} />
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            )
          })
          : ReactHtmlParser( this.packageSequenceItemTemplate(1) )
        }
        </div>
        <div className="sequence-add-item">
          <button className="btn btn-block add-sequence-item" type="button" onClick={(e) => this.addMoreItem(e)}>
            <i className="icon icon-plus"></i> เพิ่มแพ็คเกจใหม่
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SequenceProgramPackage
