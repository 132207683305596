import React from "react"
import PropTypes from "prop-types"
import { getExcerpt, formatThaiDate } from './../helper/utils'

class PostsList extends React.Component {
  render () {
    return this.props.posts.length > 0 ? (
      <div className="posts-list">
        {
          this.props.posts.map(post => (
            <div key={post["id"]} className="posts-item">
              <a href={`/blog/${post["slug"]}`} className="_image-wrapper" data-turbolinks="false">
                <img src={post["featured_image"] ? post["featured_image"]["url"] : '/img/placeholder250-141.jpg'} alt={post["name"]} />
              </a>
              <div className="_content">
                <h3>
                  <a href={`/blog/${post["slug"]}`} title={post["name"]} data-turbolinks="false">{post["name"]}</a>
                </h3>
                <p className="_meta">
                  <span>เขียนเมื่อ { formatThaiDate(post["created_at"]) }</span>
                  <span>ในหมวดหมู่ <a href={`/blogs/category/${post["blog_category"]["slug"]}`} title={post["blog_category"]["name"]}>{post["blog_category"]["name"]}</a></span>
                </p>
                <p className="_description">{getExcerpt(post["content"], 120)}</p>
              </div>
            </div>
          ))
        }
      </div>
    ) : null
  }
}

export default PostsList
