import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { isObjectEmpty, setRequireFieldAfterRemoveSequenceItem } from './../helper/utils'

class SequenceEducationWithEvidence extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      currentNumber: 1,
      editFirstItem: false,
      items: []
    }
  }

  componentDidMount () {
    if ( !isObjectEmpty(this.props.items) ) {
      if (JSON.parse(this.props.items).length > 0) {
        this.setState({ items: JSON.parse(this.props.items) }, () => {
          // add button event to all items
          let wrapper = document.getElementById(`${this.props.model}_education`)
          let items = wrapper.querySelectorAll('.sequence-item')
          let currentNumber = 0
          items.forEach((item, index) => {
            this.addButtonEventToItem(item)
            if (index === 0) item.querySelector('._action').classList.add('disabled')
            currentNumber += 1
          })
          // update currentnumber
          this.setState({currentNumber})
        })
      }
    } else {
      // add on click event to first item
      let firstItem = document.querySelector('.sequence-item[data-number="1"]')
      this.addButtonEventToItem(firstItem)
      firstItem.querySelector('._action').classList.add('disabled')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let wrapper = document.getElementById(`${this.props.model}_education`)
    let firstItem = wrapper.querySelector('.sequence-item:first-child')
    nextState.editFirstItem 
      ? firstItem.querySelector('._action').classList.remove('disabled')
      : firstItem.querySelector('._action').classList.add('disabled')

    return true
  }

  educationSequenceItemTemplate = (number, item = null) => {
    let today = new Date()
    let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
    let yearOptions = Array.from({length: thaiShortYear}, (x, i) => i + 2500); // start from 2500
    return `
      <div class="sequence-item" data-number="${number}">
        <div class="_action">
          <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
        </div>
        <div class="_form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">สถาบันการศึกษา <span class="req">*</span></label>
                <input type="text" class="form-control education-name" ${number === 1 ? `id="${this.props.model}_education_name"` : ''}" ${item ? `value="${item.name}"` : ''} />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ระดับการศึกษา ${number === 1 ? '<span class="req">*</span>' : ''}</label>
                <select class="form-control education-degree" ${number === 1 ? `id="${this.props.model}_education_degree"` : ''}>
                  <option value=""></option>
                  <option value="diploma" ${item && item.degree === "diploma" ? `selected` : ''}>ปวส / อนุปริญญาตรี</option>
                  <option value="bachelor" ${item && item.degree === "bachelor" ? `selected` : ''}>ปริญญาตรี</option>
                  <option value="master" ${item && item.degree === "master" ? `selected` : ''}>ปริญญาโท</option>
                  <option value="phd" ${item && item.degree === "phd" ? `selected` : ''}>ปริญญาเอก</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">สาขา ${number === 1 ? '<span class="req">*</span>' : ''}</label>
                <input type="text" class="form-control education-faculty" ${number === 1 ? `id="${this.props.model}_education_faculty"` : ''} ${item ? `value="${item.faculty}"` : ''} />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ปีพ.ศ. ที่จบ ${number === 1 ? '<span class="req">*</span>' : ''}</label>
                <select class="form-control education-year" ${number === 1 ? `id="${this.props.model}_education_year"` : ''}>
                  <option value=""></option>
                  ${yearOptions.map(i => `<option value=${i} ${item && item.year == i ? `selected` : ''}>${i}</option>`)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  reOrderItems = () => {
    let wrapper = document.getElementById(`${this.props.model}_education`)
    let items = wrapper.querySelectorAll('.sequence-item')
    let lastItemNumber = 1
    items.forEach( (el, index) => {
      let lastIndex = index + 1
      el.dataset.number = lastIndex
      lastItemNumber = lastIndex
      // add id and require filed to first item
      if (lastIndex === 1) {
        setRequireFieldAfterRemoveSequenceItem(el, '.education-degree', `${this.props.model}_education_degree`, 'ระดับการศึกษา <span class="req">*</span>')
        setRequireFieldAfterRemoveSequenceItem(el, '.education-faculty', `${this.props.model}_education_faculty`, 'สาขา <span class="req">*</span>')
        setRequireFieldAfterRemoveSequenceItem(el, '.education-year', `${this.props.model}_education_year`, 'ปีพ.ศ. ที่จบ <span class="req">*</span>')
      }
    })
    return lastItemNumber
  }

  sortItems = () => {
    let wrapper = document.getElementById(`${this.props.model}_education`)
    let items = wrapper.querySelectorAll('.sequence-item')
    items = [].map.call(items, (elm) => elm)
    items.sort( (a, b) => {
      if ( parseInt(a.dataset.number) > parseInt(b.dataset.number) ) return 1
      if ( parseInt(a.dataset.number) < parseInt(b.dataset.number) ) return -1
      return 0
    })
    items.forEach(item => wrapper.appendChild(item))
  }

  addMoreItem = (e) => {
    if (e) e.preventDefault()
    let newNumber = parseInt(this.state.currentNumber) + 1
    let wrapper = document.getElementById(`${this.props.model}_education`)
    let newItem = this.educationSequenceItemTemplate(newNumber)
    wrapper.insertAdjacentHTML('beforeend', newItem)
    // add new event to last added element
    let recentAddedItem = document.querySelector(`.sequence-item[data-number="${newNumber}"]`)
    this.addButtonEventToItem(recentAddedItem)
    this.setState({ currentNumber: newNumber, editFirstItem: true })
  }

  addButtonEventToItem = (el) => {
    let wrapper = document.getElementById(`${this.props.model}_education`)
    let removeButton = el.querySelector('.remove-sequence')
    let moveButton = el.querySelectorAll('.move-sequence')
    // remove event
    removeButton.addEventListener('click', () => {
      el.remove()
      let lastItemNumber = this.reOrderItems()
      wrapper.querySelectorAll('.sequence-item').length <= 1 
        ? this.setState({ editFirstItem: false }) 
        : this.setState({ editFirstItem: true })
      this.setState({currentNumber: lastItemNumber})
    } )
    // move event
    // moveButton.forEach(button => {
    //   button.addEventListener('click', () => {
    //     let thisSequence = parseInt(el.dataset.number)
    //     let direction = button.dataset.direction
    //     let newSequence = direction === 'up' ? thisSequence - 1 : thisSequence + 1
    //     let targetRow = direction === 'up' ? el.previousElementSibling : el.nextElementSibling
    //     // if target existed, swap the number
    //     if (targetRow) {
    //       targetRow.setAttribute('data-number', thisSequence)
    //       el.setAttribute('data-number', newSequence)
    //       this.sortItems()
    //     }
    //   })
    // })
  }

  render () {
    return (
      <React.Fragment>
        <div className="sequence-wrapper" id={`${this.props.model}_education`}>
          {
            this.state.items.length > 0 
              ? this.state.items.map( (item, index) => {
                let number = index + 1
                let today = new Date()
                let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
                let yearOptions = Array.from({length: thaiShortYear}, (x, i) => i + 2500); // start from 2500
                return (
                  <div className="sequence-item" data-number={number} key={number}>
                    <div className="_action">
                      <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
                    </div>
                    <div className="_form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">สถาบันการศึกษา <span className="req">*</span></label>
                            <input type="text" className="form-control education-name" defaultValue={item.name} id={number === 1 ? `${this.props.model}_education_name` : ''} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">ระดับการศึกษา {number === 1 ? <span className="req">*</span> : ''}</label>
                            <select className="form-control education-degree" id={number === 1 ? `${this.props.model}_education_degree` : ''} defaultValue={item.degree}>
                              <option value=""></option>
                              <option value="diploma">ปวส / อนุปริญญาตรี</option>
                              <option value="bachelor">ปริญญาตรี</option>
                              <option value="master">ปริญญาโท</option>
                              <option value="phd">ปริญญาเอก</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">สาขา {number === 1 ? <span className="req">*</span> : ''}</label>
                            <input type="text" className="form-control education-faculty" id={number === 1 ? `${this.props.model}_education_faculty` : ''} defaultValue={item.faculty} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">ปีพ.ศ. ที่จบ {number === 1 ? <span className="req">*</span> : ''}</label>
                            <select className="form-control education-year" id={number === 1 ? `${this.props.model}_education_year` : ''} defaultValue={item.year}>
                              <option value=""></option>
                              {yearOptions.map(i => <option key={i} value={i}>{i}</option>)}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
    
              } )
              : ReactHtmlParser( this.educationSequenceItemTemplate(1) )
          }
        </div>
        <div className="sequence-add-item">
          <button className="btn btn-block add-sequence-item" type="button" onClick={(e) => this.addMoreItem(e) }>
            <i className="icon icon-plus"></i> เพิ่มประวัติการศึกษา
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SequenceEducationWithEvidence
