import React from "react"
import PropTypes from "prop-types"

import { getExcerpt } from './../helper/utils'

class EntryRelatedPosts extends React.Component {
  render () {
    return this.props.posts.length > 0 ? (
      <div className="entry-related-posts-block">
        {
          this.props.posts.map(post => (
            <div className="card-169-image" key={post}>
              <a href={`/blog/${post.slug}`} className="_image" data-turbolinks="false">
                <img src={`${post.featured_image.url}`} alt={post.name}/>
              </a>
              <div className="_content">
                <h4 className="_title">
                  <a href={`/blog/${post.slug}`}>{post.name}</a>
                </h4>
                <p>{getExcerpt(post.content, 150)}</p>
              </div>
            </div>
          ))
        }
      </div>
    ) : null
  }
}

export default EntryRelatedPosts
