import React from "react"
import PropTypes from "prop-types"
import { isEmailValid, alertMessage } from './../helper/utils'
class ForgotPasswordModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      csrfToken: ''
    }
  }

  componentDidMount() {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    this.setState({ csrfToken })
  }

  handleChange = (e) => {
    let inputName = e.target.dataset.id
    this.setState({ [inputName]: e.target.value })
  }

  submitForm = (e) => {
    let form = document.getElementById('forgot_form')
    let popup = document.getElementById('forgot_modal')
    let preloader = popup.querySelector('.content-preloader')
    if (e) e.preventDefault()
    if (!this.state.email) {
      alertMessage('กรุณากรอกอีเมล์ของคุณ')
      return false
    }
    if (!isEmailValid(this.state.email)) {
      alertMessage('รูปแบบอีเมล์ที่คุณกรอกไม่ถูกต้อง')
      return false
    }
    preloader.classList.add('active')
    form.submit()
  }

  render () {
    return (
      <div className="jd-modal-block" id="forgot_modal">
        <div className="modal-white-box">
          <div className="content-preloader">
            <div className="_content-backdrop"></div>
            <img src="/img/preloader.gif" alt="Preloader" />
          </div>
          <a href="#" className="close-modal-button" title="ปิด">
            <i className="icon icon-close"></i>
          </a>
          <div className="modal-wrapper">
            <h3 className="modal-title">ลืมรหัสผ่าน</h3>
            <p>กรอกอีเมล์ที่สมัครเข้ามาในช่องด้านล่าง ระบบจะทำการส่งวิธีการตั้งรหัสผ่านใหม่กลับไป</p>
            <form action="/forgot" method="POST" className="form devise-form" id="forgot_form">
              <input type="hidden" name="authenticity_token" value={this.state.csrfToken} />
              <div className="form-group form-label-upper">
                <label htmlFor="" className="control-label">
                  อีเมล์ <i className="icon icon-user"></i>
                </label>
                <input type="text" className="form-control" name="user[email]" autoComplete="off" onChange={(e) => this.handleChange(e)} value={this.state.email} data-id="email" />
              </div>
              <div className="form-group action-group">
                <button className="btn btn-secondary" type="button" onClick={this.submitForm}>ยืนยันการขอข้อมูล</button>
              </div>
              <div className="modal-footer-form">
                <p>
                  ย้อนกลับไปหน้าเข้าสู่ระบบ <a href="#" className="go-login" title="คลิกที่นี่">คลิกที่นี่</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordModal
