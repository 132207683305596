import React from "react"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'

import { isObjectEmpty } from './../helper/utils'

class SequencePackage extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      currentNumber: 1,
      editFirstItem: false,
      items: []
    }
  }
  
  componentDidMount() {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    if ( !isObjectEmpty(this.props.items) && JSON.parse(this.props.items).length > 0) {
      this.setState({ items: JSON.parse(this.props.items) }, () => {
        // add button event to all items
        let items = wrapper.querySelectorAll('.sequence-item')
        let currentNumber = 0
        items.forEach((item, index) => {
          this.addButtonEventToItem(item, index)
          if (index === 0) item.querySelector('._action').classList.add('disabled')
          currentNumber += 1
        })
        // update currentnumber
        this.setState({currentNumber})
      })
    } else {
      // add on click event to first item
      let firstItem = wrapper.querySelector('.sequence-item[data-number="1"]')
      this.addButtonEventToItem(firstItem)
      firstItem.querySelector('._action').classList.add('disabled')
    }
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let firstItem = wrapper.querySelector('.sequence-item:first-child')
    nextState.editFirstItem
    ? firstItem.querySelector('._action').classList.remove('disabled')
    : firstItem.querySelector('._action').classList.add('disabled')
    
    return true
  }
  
  packageSequenceItemTemplate = (number, item = null) => {
    return `
    <div class="sequence-item" data-number="${number}">
      <div class="_action">
        <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
      </div>
      <div class="_form">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="" class="control-label">ชื่อแพ็คเกจ <span class="req">*</span></label>
              <input type="text" class="form-control package-name" ${item ? `value="${item.name}"` : ''} />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="" class="control-label">ราคาเริ่มต้น <span class="req">*</span></label>
              <input type="text" class="form-control package-price jd-numeric" placeholder="กรอกเป็นตัวเลขเท่านั้น เช่น: 3000" ${item ? `value="${item.price}"` : ''} />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="" class="control-label">รายละเอียดของแพ็คเกจ <span class="req">*</span></label>
              <textarea class="form-control fe-editor package-details" id="package_details_sequence_${number}" ${item ? `value="${item.details}"` : ''}></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    `
  }
  
  reOrderItems = () => {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let items = wrapper.querySelectorAll('.sequence-item')
    let lastItemNumber = 1
    items.forEach((el, index) => {
      let lastIndex = index + 1
      el.dataset.number = lastIndex
      lastItemNumber = lastIndex
    })
    return lastItemNumber
  }
  
  sortItems = () => {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let items = wrapper.querySelectorAll('.sequence-item')
    items = [].map.call(items, (elm) => elm)
    items.sort((a, b) => {
      if (parseInt(a.dataset.number) > parseInt(b.dataset.number)) return 1
      if (parseInt(a.dataset.number) < parseInt(b.dataset.number)) return -1
      return 0
    })
    items.forEach( (item, index) => {
      wrapper.appendChild(item)
    })
  }
  
  addMoreItem = (e) => {
    if (e) e.preventDefault()
    let newNumber = parseInt(this.state.currentNumber) + 1
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let newItem = this.packageSequenceItemTemplate(newNumber)
    wrapper.insertAdjacentHTML('beforeend', newItem)
    // add new event to last added element
    let recentAddedItem = wrapper.querySelector(`.sequence-item[data-number="${newNumber}"]`)
    this.addButtonEventToItem(recentAddedItem, newNumber)
    this.setState({ currentNumber: newNumber, editFirstItem: true })
  }
  
  initEditor = (editorId) => {
    tinymce.init({
      selector: editorId,
      plugins: ['lists', 'table', 'link', 'paste'],
      menu: {
        edit: { title: 'แก้ไข', items: 'undo redo | cut copy paste | selectall' },
        insert: { title: 'แทรก', items: 'link inserttable charmap emoticons hr anchor' },
        format: { title: 'แบบอักษร', items: 'bold italic underline strikethrough superscript subscript formats | forecolor backcolor | removeformat' },
        table: { title: 'ตาราง', items: 'inserttable | cell row column | tableprops deletetable' },
      },
      menubar: 'edit insert format table',
      toolbar: 'undo redo | bold italic link | alignleft aligncenter alignright alignjustify | outdent indent numlist bullist',
      oninit : 'setPlainText',
      skin: false,
      branding: false,
      language: 'th_TH',
      body_class: 'editor-class',
      content_css: '/editor.css',
      content_style: "body { font-family: 'cs_chatthaiuiregular'; font-size: 16px; line-height: 1.8 }",
      min_height: 480,
      paste_as_text: true,
      block_formats: false,
      default_link_target: '_blank',
      rel_list: [
        {title: 'External Link', value: 'nofollow ugc'},
        {title: 'Follow Link', value: 'dofollow'},
        {title: 'Sponsored Link', value: 'sponsored'}
      ],
      target_list: [
        {title: 'เปิดหน้าต่างใหม่', value: '_blank'},
      ],
      style_formats: [
        {
          title: 'พาดหัว 1',
          block: 'h1',
          styles: { "font-size": "28px", "line-height": "1.8" }
        },
        {
          title: 'พาดหัว 2',
          block: 'h2',
          styles: { "font-size": "20px", "line-height": "1.8" }
        },
        {
          title: 'เน้นข้อความ',
          block: 'blockquote',
          styles: { "background-color": "#F4f4f4", "padding": "24px 16px", "margin": "0px", "border-left": "3px solid #ececec" }
        },
        {
          title: 'ไฮไลท์ส้ม',
          inline: 'span',
          styles: { "color": "#FB6900" }
        },
        {
          title: 'ไฮไลท์น้ำเงิน',
          inline: 'span',
          styles: { "color": "#1D72B2" }
        },
        {
          title: 'ตัวอักษรธรรมดา',
          block: 'p',
          styles: { "color": "#000000", "font-size": "16px", "line-height": "1.8" }
        },
      ]
    })
  }
  
  addButtonEventToItem = (el, number) => {
    let wrapper = document.getElementById(`${this.props.model}_package`)
    let removeButton = el.querySelector('.remove-sequence')
    let moveButton = el.querySelectorAll('.move-sequence')
    // init editor
    this.initEditor(`#package_details_sequence_${number}`)
    // add field event to new added item
    let numberField = el.querySelector('.jd-numeric')
    if (numberField) {
      numberField.addEventListener('input', e => {
        let el = e.currentTarget
        el.value = el.value.replace(/[^0-9\.]/g, '')
      })
    }
    // remove event
    removeButton.addEventListener('click', () => {
      el.remove()
      let lastItemNumber = this.reOrderItems()
      wrapper.querySelectorAll('.sequence-item').length <= 1
      ? this.setState({ editFirstItem: false })
      : this.setState({ editFirstItem: true })
      this.setState({ currentNumber: lastItemNumber })
    })
  }
  
  render () {
    return (
      <React.Fragment>
      <div className="sequence-wrapper" id={`${this.props.model}_package`}>
      {
        this.state.items.length > 0
        ? this.state.items.map( (item,index) => {
          let number = index + 1
          return (
            <div className="sequence-item" data-number={number} key={number}>
            <div className="_action">
            <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
            </div>
            <div className="_form">
            <div className="row">
            <div className="col-md-6">
            <div className="form-group">
            <label className="control-label">ชื่อแพ็คเกจ <span className="req">*</span></label>
            <input type="text" className="form-control package-name" defaultValue={item.name} />
            </div>
            </div>
            <div className="col-md-6">
            <div className="form-group">
            <label className="control-label">ราคาเริ่มต้น <span className="req">*</span></label>
            <input type="text" className="form-control package-price jd-numeric" placeholder="กรอกเป็นตัวเลขเท่านั้น เช่น: 3000" defaultValue={item.price} />
            </div>
            </div>
            <div className="col-md-12">
            <div className="form-group">
            <label className="control-label">รายละเอียดของแพ็คเกจ <span className="req">*</span></label>
            <textarea className="form-control fe-editor package-details" id={`package_details_sequence_${number}`} defaultValue={item.details}></textarea>
            </div>
            </div>
            </div>
            </div>
            </div>
            )
          })
          : ReactHtmlParser( this.packageSequenceItemTemplate(1) )
        }
        </div>
        <div className="sequence-add-item">
        <button className="btn btn-block add-sequence-item" type="button" onClick={(e) => this.addMoreItem(e)}>
        <i className="icon icon-plus"></i> เพิ่มบริการใหม่
        </button>
        </div>
        </React.Fragment>
        );
      }
    }
    
    export default SequencePackage
    