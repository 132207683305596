import React from "react"
import axios from 'axios'

import {isUpgradedListing, courseTypeTH, formatThaiBaht, sortPostTier} from './../helper/utils'

let AdsResultItem = (props) => {
  return (
    <div className="result-ad-block">
      <div className="_image">
        <img src="/img/ads-results.png" alt="ลงประกาศ" />
      </div>
      <div className="_content">
        <p>สนใจโปรโมทคอร์สอบรม คลิก!</p>
        <a href="/listings/add" className="btn btn-primary">ลงประกาศ</a>
      </div>
    </div>
  )
}
class AuditorResultsList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      items: [],
      static: this.props.static ? this.props.static : false,
      saved: (this.props.saved && this.props.saved.length > 0) ? this.props.saved : []
    }
  }
  
  toggleFavourite = (type, id, e) => {
    if (e) e.preventDefault()
    let button = e.currentTarget
    axios.post('/listings/favourite', { listing_type: type, listing_id: id })
    .then((response) => {
      if (response.data.status === 200) {
        button.classList.contains('active') ? button.classList.remove('active') : button.classList.add('active')
      } else {
        alertMessage('ไม่สามารถบันทึกประกาศนี้ได้ กรุณาลองอีกครั้ง')
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    if (this.props.items && this.props.items.length > 0) {
      let items = this.props.items
      this.setState({items})
    }
  }

  render() {
    return (
      <div className="result-main-content">
        {
          this.state.items && this.state.items.length > 0
            ? this.state.items.map( (listing, i) => (
              <React.Fragment>
                {i == 2 && this.props.ads ? <AdsResultItem /> : null}
                <div key={listing.id} className={`course-result-type ${this.state.static ? 'static' : ''} ${isUpgradedListing(listing.post_tier) ? `premium ${listing.post_tier}` : ''}`}>
                  {/* image */}
                  <a href={`/listings/info/course/${listing.id}`} className="_image" alt={listing.title}>
                    <img src={listing.cover.thumb.url ? listing.cover.thumb.url : '/img/placeholder800.jpg' } alt={listing.title} />
                  </a>
                  {/* content */}
                  <div className="_content">
                    <h3 className="_title">
                      <a href={`/listings/info/course/${listing.id}`} title={listing.title}>{listing.title}</a>
                    </h3>
      
                    {
                      isUpgradedListing(listing.post_tier)
                      ? <React.Fragment>
                        <p className="_author">
                          โดย {listing.organizer}
                          <span style={{marginLeft: '16px'}}>
                            <i className="icon icon-tv"></i>{courseTypeTH(listing.course_types)}
                          </span>
                        </p>
                        <span className="_meta-list-icon">
                          <span className={`circle-icon ${listing.is_cpd_certificated ? 'green' : ''}`}>
                            <i className="icon icon-check"></i> 
                          </span> ผู้ทำบัญชี: {listing.is_cpd_certificated ? listing.cpd_hour_accounting : '00:00'} | อื่นๆ {listing.is_cpd_certificated ? listing.cpd_hour_other : '00:00'}
                        </span>
                        <span className="_meta-list-icon">
                          <span className={`circle-icon ${listing.is_cpa_certificated ? 'green' : ''}`}>
                          {listing.is_cpa_certificated 
                            ? <i className="icon icon-check"></i> 
                            : <i className="icon icon-close"></i>
                          }
                          </span> CPA บัญชี: {listing.is_cpa_certificated ? listing.cpd_cpa_accounting : '00:00'} | อื่นๆ {listing.is_cpa_certificated ? listing.cpd_cpa_other : '00:00'}
                        </span>
                        <span className="_meta-list-icon side">
                          <span>
                            <span className={`circle-icon ${listing.is_ta_certificated ? 'green' : ''}`}>
                              {listing.is_ta_certificated 
                                ? <i className="icon icon-check"></i> 
                                : <i className="icon icon-close"></i>
                              }
                            </span> TA: {listing.is_ta_certificated ? listing.ta_hour_count : "00:00"}
                          </span>
                          <span className="_side-price"><strong>ราคา {formatThaiBaht(listing.price)}</strong></span>
                        </span>
                      </React.Fragment>
                      : <React.Fragment>
                        <p className="_author">โดย {listing.organizer}</p>
                        <div className="_meta">
                          <span><i className="icon icon-tv"></i>{courseTypeTH(listing.course_types)}</span>
                          <span className="_price"><strong>ราคา {formatThaiBaht(listing.price)}</strong></span> <br className="d-none d-md-block"/>
                          <span className="_meta-list-icon">
                            <span className={`circle-icon ${listing.is_cpd_certificated ? 'green' : ''}`}>
                              <i className={`icon icon-${listing.is_cpd_certificated ? 'check' : 'close'}`}></i> 
                            </span> ผู้ทำบัญชี: {listing.is_cpd_certificated ? listing.cpd_hour_accounting : '00:00'} | อื่นๆ {listing.is_cpd_certificated ? listing.cpd_hour_other : '00:00'}
                          </span>
                          <span className="_meta-list-icon">
                            <span className={`circle-icon ${listing.is_cpa_certificated ? 'green' : ''}`}>
                            {listing.is_cpa_certificated 
                              ? <i className="icon icon-check"></i> 
                              : <i className="icon icon-close"></i>
                            }
                            </span> CPA บัญชี: {listing.is_cpa_certificated ? listing.cpd_cpa_accounting : '00:00'} | อื่นๆ {listing.is_cpa_certificated ? listing.cpd_cpa_other : '00:00'}
                          </span>
                          <span className="_meta-list-icon">
                            <span className={`circle-icon ${listing.is_ta_certificated ? 'green' : ''}`}>
                              {listing.is_ta_certificated 
                                ? <i className="icon icon-check"></i> 
                                : <i className="icon icon-close"></i>
                              }
                            </span> TA: {listing.is_ta_certificated ? listing.ta_hour_count : "00:00"}
                          </span>
                        </div>
                      </React.Fragment>
                    }
      
                    
                  </div>
                  {/* favorite */}
                  {
                    this.state.static
                      ? null
                      : (
                        <a href="#" className={`favourite-button ${this.state.saved.includes(listing.id) ? 'active' : ''} ${this.props.currentUserId ? "" : "go-login"}`} title="บันทึกเป็นรายการโปรด" onClick={ e => this.props.currentUserId ? this.toggleFavourite("Course", listing.id, e) : false}>
                          <span><i className="icon icon-heart"></i></span>
                        </a>
                      )
                  }
                </div>
              </React.Fragment>
            ))
            : (
              <div className="empty-listing-wrapper">
                <img src="/img/empty-listing.png" alt="ไม่พบประกาศใดๆในตอนนี้"/>
                <p>ยังไม่มีรายการในขณะนี้</p>
              </div>
            )
        }

        {/* navigation */}
        {
          this.state.static
            ? null
            : this.props.totalPages > 1 
              ? (
                <div className="page-navigation-block">
                  <a href="#" className="btn btn-secondary">หน้าที่แล้ว</a>
                  <a href="#" className="btn btn-secondary">หน้าถัดไป</a>
                </div>
              )
              : null
        }
      </div>
    );
  }
}

export default AuditorResultsList
