import React from "react"
import Slider from "react-slick"
import { isMobileOnly } from "react-device-detect"

import { operatingStatusTH, isUpgradedListing, auditorTypeTh, workingStatusTH, titleExcerpt } from './../helper/utils'

class CardGridFourth extends React.Component {
  render () {
    let cardListingSlider = {
      dots: false,
      arrows: false,
      autoplay: false,
      infinite: false,
      focusOnSelect: false,
      centerMode: true,
      centerPadding: '24px',
      slidesToShow: 1
    }
    return (
      <div className="fourth-grid-card-block">
        {
          isMobileOnly 
            ? <Slider {...cardListingSlider}>
                {
                  this.props.items.map(listing => (
                    <div key={listing.id}  className="card-slider-item">
                      <div className={`card-item ${isUpgradedListing(listing.post_tier) ? listing.post_tier : '' }`}>
                        <a href={`/listings/info/${this.props.itemType}/${listing.id}`} className="card-image-wrapper">
                          <img src={listing.cover.url ? listing.cover.url : '/img/placeholder400.jpg'} alt={listing.title} className="item-image" />
                        </a>
                        <div className="card-content-wrapper">
                          <h4><a href={`/listings/info/${this.props.itemType}/${listing.id}`} alt={listing.title}>{titleExcerpt(listing.title)}</a></h4>
                          <ul className="card-icon-list">
                            {
                              this.props.itemType === 'accountant' ? (
                                <React.Fragment>
                                  <li><i className="icon icon-location"></i><span>{listing.district}, {listing.province}</span></li>
                                  <li><i className="icon icon-user"></i><span>{
                                    listing.operating_status == 'individual' 
                                      ? `${operatingStatusTH(listing.operating_status)} | รับทำบัญชี ฟรีแลนซ์`
                                      : `สำนักงานบัญชี | ${listing.company_name}`
                                  }</span></li>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <li><i className="icon icon-location"></i><span>{listing.district}, {listing.province}</span></li>
                                  <li><i className="icon icon-certificate"></i><span>{auditorTypeTh[listing.auditor_type]}</span></li>
                                  <li><i className="icon icon-user"></i><span>{workingStatusTH(listing.auditor_working_status)} {listing.auditor_working_status === 'corporate' ? ` | ${listing.company_name}` : ''}</span></li>
                                </React.Fragment>
                              )
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Slider>
            : (
                this.props.items.map( (listing, i) => (
                  <div key={listing.id} className={`card-item ${isUpgradedListing(listing.post_tier) ? listing.post_tier : '' } ${i == 7 ? 'more' : ''} `} data-link={this.props.itemType === 'accountant' ? '/search/results/accountant' : '/search/results/auditor'}>
                    <a href={`/listings/info/${this.props.itemType}/${listing.id}`} className="card-image-wrapper" title={listing.title}>
                      <img src={listing.cover.url ? listing.cover.url : '/img/placeholder400.jpg'} alt="card-image" className="item-image" />
                    </a>
                    <div className='card-content-wrapper'>
                      <h4>
                        <a href={`/listings/info/${this.props.itemType}/${listing.id}`} title={listing.title}>{listing.title}</a>
                      </h4>
                      <ul className="card-icon-list">
                        {
                          this.props.itemType === 'accountant' ? (
                            <React.Fragment>
                              <li>
                                <i className="icon icon-location"></i>
                                <span>{listing.district}, {listing.province}</span>
                              </li>
                              <li>
                                <i className="icon icon-user"></i>
                                <span>
                                  {
                                    listing.operating_status == 'individual' 
                                      ? operatingStatusTH(listing.operating_status) 
                                      : listing.company_name
                                  }  
                                </span>
                              </li>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <li><i className="icon icon-location"></i><span>{listing.district}, {listing.province}</span></li>
                              <li><i className="icon icon-certificate"></i><span>{auditorTypeTh[listing.auditor_type]}</span></li>
                              <li>
                                <i className="icon icon-user"></i>
                                <span>{workingStatusTH(listing.auditor_working_status)} {listing.auditor_working_status === 'corporate' ? ` | ${listing.company_name}` : ''}</span>
                              </li>
                            </React.Fragment>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                ))
            )
        }

      </div>
    );
  }
}

export default CardGridFourth
