import React from "react"
import {getExcerpt, getModelImage} from './../helper/utils'
import { isMobileOnly } from "react-device-detect";
class BlogList extends React.Component {
  render () {
    return (
      <div className="row">
        {
          this.props.blogs && this.props.blogs.length > 0 
            ? this.props.blogs.length > 0 
              ? this.props.blogs.map(blog => (
                  <div key={blog.id} className="col-md-6 col-lg-3 col-xl-6">
                    <div className="grid-blog-item">
                      <a href={`/blog/${blog.slug}`} className="blog-image-wrapper" title={blog.name}>
                        <img src={getModelImage(blog.featured_image, isMobileOnly ? 'full' : 'thumb', true)} alt={blog.name} />
                      </a>
                      <div className="_content">
                        <h4 className="blog-title">
                          <a href={`/blog/${blog.slug}`} title={blog.name}>{blog.name}</a>
                        </h4>
                        <p>{blog.content ? `${getExcerpt(blog.content, 120)}.. [อ่านต่อ]` : ''}</p>
                      </div>
                    </div>
                  </div>
                ))
              : null
            : null
        }
      </div>
    );
  }
}

export default BlogList
