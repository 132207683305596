import React from "react"
import axios from 'axios'

import {isUpgradedListing, getExcerpt, sortPostTier} from './../helper/utils'
class InfrastructureResultsList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      items: [],
      static: this.props.static ? this.props.static : false,
      saved: (this.props.saved && this.props.saved.length > 0) ? this.props.saved : []
    }
  }

  toggleFavourite = (type, id, e) => {
    if (e) e.preventDefault()
    let button = e.currentTarget
    axios.post('/listings/favourite', { listing_type: type, listing_id: id })
    .then((response) => {
      if (response.data.status === 200) {
        button.classList.contains('active') ? button.classList.remove('active') : button.classList.add('active')
      } else {
        alertMessage('ไม่สามารถบันทึกประกาศนี้ได้ กรุณาลองอีกครั้ง')
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    if (this.props.items && this.props.items.length > 0) {
      let items = this.props.items
      this.setState({items})
    }
  }

  render() {
    return (
      <div className="result-main-content">
        {
          this.state.items && this.state.items.length > 0
          ? this.state.items.map(listing => (
            <div key={listing.id} className={`infrastructure-result-type ${this.state.static ? 'static' : ''} ${isUpgradedListing(listing.post_tier) ? `premium ${listing.post_tier}` : ''}`}>
              {/* image */}
              <a href={`/listings/info/consult/${listing.id}`} className="_image" alt={listing.title}>
                <img src={listing.cover.thumb.url ? listing.cover.thumb.url : '/img/placeholder800.jpg' } alt={listing.title} />
              </a>
              {/* content */}
              <div className="_content">
              <h3 className="_title"><a href={`/listings/info/consult/${listing.id}`} title={listing.title}>{listing.title}</a></h3>
                <p className="_author">โดย {listing.company_name}</p>
                <div className="_badge-and-location">
                  <div className="jd-badge">รับวางระบบบัญชี</div>
                  <span className="_location"><i className="icon icon-location"></i> {listing.district}, {listing.province}</span>
                </div>
                <p className="_description">
                  <span>{listing.content ? `${getExcerpt(listing.content, 120)}.. อ่านต่อ` : ''}</span>
                </p>
              </div>
              {/* favorite */}
              {
                this.state.static
                  ? null
                  : (
                    <a href="#" className={`favourite-button ${this.state.saved.includes(listing.id) ? 'active' : ''} ${this.props.currentUserId ? "" : "go-login"} `} title="บันทึกเป็นรายการโปรด" onClick={ e => this.props.currentUserId ? this.toggleFavourite("Infrastructure", listing.id, e) : false}>
                      <span><i className="icon icon-heart"></i></span>
                    </a>
                  )
              }
            </div>
          ))
          : (
            <div className="empty-listing-wrapper">
              <img src="/img/empty-listing.png" alt="ไม่พบประกาศใดๆในตอนนี้"/>
              <p>ยังไม่มีรายการในขณะนี้</p>
            </div>
          )
        }

        {/* navigation */}
        {
          this.state.static
            ? null
            : this.props.totalPages > 2 
              ? (
                <div className="page-navigation-block">
                  <a href="#" className="btn btn-secondary">หน้าที่แล้ว</a>
                  <a href="#" className="btn btn-secondary">หน้าถัดไป</a>
                </div>
              ) 
              : null
        }
      </div>
    );
  }
}

export default InfrastructureResultsList
