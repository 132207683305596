import React from "react"

import { isUpgradedListing, listingTypeTHToLink } from './../helper/utils'

class SidebarRelatedPosts extends React.Component {
  render () {
    return (
      <div className="sidebar-related-posts-block">
        {
          this.props.items.length > 0 
            ? this.props.items.map( (listing, i) => (
              <React.Fragment key={i}>
                {
                  (i % 3 === 0 && i === 3) ? (
                    <div className="blog-listing-ads">
                      <div className="_content">
                        <h3>ลงประกาศ ฟรี!</h3>
                        <p>สร้างโปรไฟล์บนเว็บไซต์ของเรา ไม่เสียค่าใช้จ่าย ลูกค้าเห็นเยอะ</p>
                        <a href="/listings/add" className="btn btn-primary">ลงประกาศ</a>
                      </div>
                    </div>
                  ) : null
                }
                <div className={`blog-small-list-item ${isUpgradedListing(listing.post_tier) ? `premium ${listing.post_tier}` : ''}`}>
                  <a href={`${listingTypeTHToLink(listing.post_type)}/${listing.id}`} className="_image-wrapper" alt={listing.title}>
                  <img src={listing.cover.thumb.url ? listing.cover.thumb.url : '/img/placeholder800.jpg' } alt={listing.title} />
                  </a>
                  <div className="_content">
                    <h3 className="_title">
                      <a href={`${listingTypeTHToLink(listing.post_type)}/${listing.id}`} className="_image-wrapper" alt={listing.title}>{listing.title}</a>
                    </h3>
                    {
                      listing.district && listing.province
                        ? (
                          <p className="_location">
                            <i className="icon icon-location"></i> {listing.district}, {listing.province}
                          </p>
                        ) : null
                    }
                    <p className="_type">{listing.post_type}</p>
                  </div>
                </div>
              </React.Fragment>
            )) : null
        }
        
      </div>
    );
  }
}

export default SidebarRelatedPosts
