import React from 'react'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'

import { isObjectEmpty } from './../helper/utils'

class SequenceExperienceAccountant extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentNumber: 1,
      editFirstItem: false,
      items: []
    }
  }

  checkingShowExperienceType = () => {
    // hide experience type in registration step
    // then change company and title column to 6 col width
    let registrationType = document.getElementById('registration_post_type')
    if (registrationType) {
      let allExperienceType = document.querySelectorAll('.experience-type-col')
      let allExperienceCompanyName = document.querySelectorAll('.experience-company-name-col')
      let allExperienceTitle = document.querySelectorAll('.experience-title-col')
      allExperienceType.forEach(el => el.remove())
      allExperienceCompanyName.forEach(el => {
        el.classList.remove('col-md-4')
        el.classList.add('col-md-6')
      })
      allExperienceTitle.forEach(el => {
        el.classList.remove('col-md-4')
        el.classList.add('col-md-6')
      })
    }
  }

  componentDidMount() {
    // add on click event to first item
    let wrapper = document.getElementById(`${this.props.model}_experience`)
    if ( !isObjectEmpty(this.props.items) && JSON.parse(this.props.items).length > 0) {
      this.setState({ items: JSON.parse(this.props.items) }, () => {
        // add button event to all items
        let items = wrapper.querySelectorAll('.sequence-item')
        let currentNumber = 0
        items.forEach((item, index) => {
          this.addButtonEventToItem(item)
          if (index === 0) item.querySelector('._action').classList.add('disabled')
          currentNumber += 1
        })
        // update currentnumber
        this.setState({currentNumber})
      })
    } else {
      // add on click event to first item
      let firstItem = wrapper.querySelector('.sequence-item[data-number="1"]')
      this.addButtonEventToItem(firstItem)
      firstItem.querySelector('._action').classList.add('disabled')
    }
    setTimeout( () => this.checkingShowExperienceType(), 1200)
  }

  shouldComponentUpdate(nextProps, nextState) {
    let wrapper = document.getElementById(`${this.props.model}_experience`)
    let firstItem = wrapper.querySelector('.sequence-item:first-child')
    nextState.editFirstItem
      ? firstItem.querySelector('._action').classList.remove('disabled')
      : firstItem.querySelector('._action').classList.add('disabled')

    return true
  }

  experienceSequenceItemTemplate = (number, item = null) => {
    let today = new Date()
    let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
    let yearOptions = Array.from({length: thaiShortYear}, (x, i) => i + 2500); // start from 2500
    return `
      <div class="sequence-item" data-number="${number}">
        <div class="_action">
          <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
        </div>
        <div class="_form">
          <div class="row">
            <div class="col-md-4 experience-company-name-col">
              <div class="form-group">
                <label for="" class="control-label">ชื่อกิจการ</label>
                <input type="text" class="form-control experience-company" ${item ? `value="${item.company}"` : ''} />
              </div>
            </div>
            <div class="col-md-4 experience-title-col">
              <div class="form-group">
                <label for="" class="control-label">ชื่อตำแหน่ง</label>
                <input type="text" class="form-control experience-role" ${item ? `value="${item.role}"` : ''} />
              </div>
            </div>
            <div class="col-md-4 experience-type-col">
              <div class="form-group">
                <label for="" class="control-label">ฐานะปฏิบัติงาน</label>
                ${
                  this.props.model === 'accountant' 
                    ? `
                      <select name="" class="form-control experience-type">
                        <option value=""></option>
                        <option value="individual" ${item && item.type === "individual" ? `selected` : ''}>ผู้รับทำบัญชีอิสระ</option>
                        <option value="corporate" ${item && item.type === "corporate" ? `selected` : ''}>สังกัดสำนักงานรับทำบัญชี</option>
                        <option value="employee" ${item && item.type === "employee" ? `selected` : ''}>พนักงานบัญชีของกิจการ</option>
                      </select>
                    ` : `
                      <select name="" class="form-control experience-type">
                        <option value=""></option>
                        <option value="big4" ${item && item.type === "big4" ? `selected` : ''}>สังกัดสนง. Big4</option>
                        <option value="sec-certified" ${item && item.type === "sec-certified" ? `selected` : ''}>สังกัดสนง. สอบบัญชีที่ได้รับความเห็นชอบจากกลต. (ไม่ใช่ Big4)</option>
                        <option value="other-company" ${item && item.type === "other-company" ? `selected` : ''}>สังกัดสนง. สอบบัญชีอื่นๆ</option>
                        <option value="individual" ${item && item.type === "individual" ? `selected` : ''}>อิสระ</option>
                      </select>
                    `
                }
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ปีพ.ศ. ที่เริ่ม</label>
                <select class="form-control experience-year-start">
                  <option value=""></option>
                  ${yearOptions.map(i => `<option value=${i} ${item && item.start == i ? `selected` : ''}>${i}</option>`)}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ปีพ.ศ.ที่จบ</label>
                <select class="form-control experience-year-end">
                  <option value=""></option>
                  ${yearOptions.map(i => `<option value=${i} ${item && item.end == i ? `selected` : ''}>${i}</option>`)}
                  <option value="ปัจจุบัน">ถึงปัจจุบัน</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <p class="form-note">ไม่บังคับกรอก แต่การเปิดเผยจะช่วยเพิ่มความน่าเชื่อถือของท่าน</p>
            </div>
          </div>
        </div>
      </div>
    `
  }

  reOrderItems = () => {
    let wrapper = document.getElementById(`${this.props.model}_experience`)
    let items = wrapper.querySelectorAll('.sequence-item')
    let lastItemNumber = 1
    items.forEach((el, index) => {
      let lastIndex = index + 1
      el.dataset.number = lastIndex
      lastItemNumber = lastIndex
    })
    return lastItemNumber
  }

  sortItems = () => {
    let wrapper = document.getElementById(`${this.props.model}_experience`)
    let items = wrapper.querySelectorAll('.sequence-item')
    items = [].map.call(items, (elm) => elm)
    items.sort((a, b) => {
      if (parseInt(a.dataset.number) > parseInt(b.dataset.number)) return 1
      if (parseInt(a.dataset.number) < parseInt(b.dataset.number)) return -1
      return 0
    })
    items.forEach(item => wrapper.appendChild(item))
  }

  addMoreItem = (e) => {
    if (e) e.preventDefault()
    let newNumber = parseInt(this.state.currentNumber) + 1
    let wrapper = document.getElementById(`${this.props.model}_experience`)
    let newItem = this.experienceSequenceItemTemplate(newNumber)
    wrapper.insertAdjacentHTML('beforeend', newItem)
    // add new event to last added element
    let recentAddedItem = wrapper.querySelector(`.sequence-item[data-number="${newNumber}"]`)
    this.addButtonEventToItem(recentAddedItem)
    this.checkingShowExperienceType()
    this.setState({ currentNumber: newNumber, editFirstItem: true })
  }

  addButtonEventToItem = (el) => {
    let wrapper = document.getElementById(`${this.props.model}_experience`)
    let removeButton = el.querySelector('.remove-sequence')
    let moveButton = el.querySelectorAll('.move-sequence')
    // remove event
    removeButton.addEventListener('click', () => {
      el.remove()
      let lastItemNumber = this.reOrderItems()
      wrapper.querySelectorAll('.sequence-item').length <= 1
        ? this.setState({ editFirstItem: false })
        : this.setState({ editFirstItem: true })
      this.setState({ currentNumber: lastItemNumber })
    })
    // move event
    moveButton.forEach(button => {
      button.addEventListener('click', () => {
        let thisSequence = parseInt(el.dataset.number)
        let direction = button.dataset.direction
        let newSequence = direction === 'up' ? thisSequence - 1 : thisSequence + 1
        let targetRow = direction === 'up' ? el.previousElementSibling : el.nextElementSibling
        // if target existed, swap the number
        if (targetRow) {
          targetRow.setAttribute('data-number', thisSequence)
          el.setAttribute('data-number', newSequence)
          this.sortItems()
        }
      })
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="sequence-wrapper" id={`${this.props.model}_experience`}>
          {
            this.state.items.length > 0
              ? this.state.items.map( (item, index) => {
                  let today = new Date()
                  let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
                  let yearOptions = Array.from({length: thaiShortYear}, (x, i) => i + 2500); // start from 2500
                  let number = index + 1
                  return (
                    <div className="sequence-item" data-number={number} key={number}>
                      <div className="_action">
                        <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
                      </div>
                      <div className="_form">
                        <div className="row">
                          <div className="col-md-4 experience-company-name-col">
                            <div className="form-group">
                              <label  className="control-label">ชื่อกิจการ</label>
                              <input type="text" className="form-control experience-company" defaultValue={item.company} />
                            </div>
                          </div>
                          <div className="col-md-4 experience-title-col">
                            <div className="form-group">
                              <label  className="control-label">ชื่อตำแหน่ง</label>
                              <input type="text" className="form-control experience-role" defaultValue={item.role} />
                            </div>
                          </div>
                          <div className="col-md-4 experience-type-col">
                            <div className="form-group">
                              <label className="control-label">ฐานะปฏิบัติงาน</label>
                              {
                                this.props.model === "accountant"
                                  ? (
                                    <select name="" className="form-control experience-type" defaultValue={item.type}>
                                      <option value=""></option>
                                      <option value="individual">ผู้รับทำบัญชีอิสระ</option>
                                      <option value="corporate">สังกัดสำนักงานรับทำบัญชี</option>
                                      <option value="employee">พนักงานบัญชีของกิจการ</option>
                                    </select>
                                  )
                                  : (
                                    <select name="" className="form-control experience-type" defaultValue={item.type}>
                                      <option value=""></option>
                                      <option value="big4">สังกัดสนง. Big4</option>
                                      <option value="sec-certified">สังกัดสนง. สอบบัญชีที่ได้รับความเห็นชอบจากกลต. (ไม่ใช่ Big4)</option>
                                      <option value="other-company">สังกัดสนง. สอบบัญชีอื่นๆ</option>
                                      <option value="individual">อิสระ</option>
                                    </select>
                                  )
                              }
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="control-label">ปีพ.ศ. ที่เริ่ม</label>
                              <select className="form-control experience-year-start" defaultValue={item.start}>
                                <option value=""></option>
                                {yearOptions.map(i => <option key={i} value={i}>{i}</option>)}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="control-label">ปีพ.ศ.ที่จบ</label>
                              <select className="form-control experience-year-end" defaultValue={item.end}>
                                <option value=""></option>
                                {yearOptions.map(i => <option key={i} value={i}>{i}</option>)}
                                <option value="ปัจจุบัน">ถึงปัจจุบัน</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <p className="form-note">ไม่บังคับกรอก แต่การเปิดเผยจะช่วยเพิ่มความน่าเชื่อถือของท่าน</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) 
              : ReactHtmlParser(this.experienceSequenceItemTemplate(1))
          }
        </div>
        <div className="sequence-add-item">
          <button className="btn btn-block add-sequence-item" type="button" onClick={(e) => this.addMoreItem(e)}>
            <i className="icon icon-plus"></i> เพิ่มประสบการณ์
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SequenceExperienceAccountant
