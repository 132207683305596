import React from "react"

import { 
  operatingStatusTH, 
  isUpgradedListing, 
  auditorTypeTh, 
  workingStatusTH,
  titleExcerpt
} from './../helper/utils'

class CardGridFourth extends React.Component {
  render () {
    return (
      <div className="fourth-grid-card-block two-cols">
        {
            this.props.items.map(listing => (
              <div key={listing.id}  className="card-item-wrapper">
                <div className={`card-item ${isUpgradedListing(listing.post_tier) ? listing.post_tier : '' }`}>
                  <a href={`/listings/info/${this.props.itemType}/${listing.id}`} className="card-image-wrapper">
                    <img src={listing.cover.url ? listing.cover.url : '/img/placeholder400.jpg'} alt={listing.title} className="item-image" />
                  </a>
                  <div className="card-content-wrapper">
                    <h4>
                      <a href={`/listings/info/${this.props.itemType}/${listing.id}`} alt={listing.title}>{titleExcerpt(listing.title)}</a>
                    </h4>
                    <ul className="card-icon-list">
                      {
                        this.props.itemType === 'accountant' ? (
                          <React.Fragment>
                            <li><i className="icon icon-location"></i><span>{listing.district}, {listing.province}</span></li>
                            <li><i className="icon icon-user"></i><span>{
                              listing.operating_status == 'individual' 
                                ? `${operatingStatusTH(listing.operating_status)}`
                                : `สำนักงานบัญชี | ${listing.company_name}`
                            }</span></li>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <li><i className="icon icon-location"></i><span>{listing.district}, {listing.province}</span></li>
                            { auditorTypeTh[listing.auditor_type] ? <li><i className="icon icon-certificate"></i><span>{auditorTypeTh[listing.auditor_type]}</span></li> : null }
                            <li><i className="icon icon-user"></i><span>{workingStatusTH(listing.auditor_working_status)} {listing.auditor_working_status === 'corporate' ? ` | ${listing.company_name}` : ''}</span></li>
                          </React.Fragment>
                        )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            ))
          }
      </div>
    );
  }
}

export default CardGridFourth
