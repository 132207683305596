import React from "react"
import { isEmailValid, alertMessage } from './../helper/utils'
class LoginModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      csrfToken: ''
    }
  }

  componentDidMount() {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    this.setState({ csrfToken })
  }

  handleChange = (e) => {
    let inputName = e.target.dataset.id
    this.setState({ [inputName] : e.target.value })
  }

  submitForm = (e) => {
    let form = document.getElementById('login_form')
    let popup = document.getElementById('login_modal')
    let preloader = popup.querySelector('.content-preloader')
    if (e) e.preventDefault()
    if (!this.state.email) {
      alertMessage('กรุณากรอกอีเมล์ของคุณ')
      return false
    }
    if (!isEmailValid(this.state.email)) {
      alertMessage('รูปแบบอีเมล์ที่คุณกรอกไม่ถูกต้อง')
      return false
    }
    if (!this.state.password) {
      alertMessage('กรุณากรอกอีเมล์ของคุณ')
      return false
    }
    preloader.classList.add('active')
    form.submit()
  }

  submitFacebookButton = (e) => {
    if (e) e.preventDefault()
    let form = document.getElementById('login_facebook_form')
    form.submit()
  }

  render () {
    return (
      <div className="jd-modal-block active" id="login_modal">
        <div className="modal-white-box">
          <div className="content-preloader">
            <div className="_content-backdrop"></div>
            <img src="/img/preloader.gif" alt="Preloader" />
          </div>
          <a href="#" className="close-modal-button" title="ปิด">
            <i className="icon icon-close"></i>
          </a>
          <div className="modal-wrapper">
            <h3 className="modal-title">เข้าสู่ระบบ</h3>
            <p>กรอกอีเมล์และรหัสผ่าน เพื่อเข้าสู่บัญชีของคุณ หากจำรหัสผ่านไม่ได้ <a href="#" className="go-forgot" title="ตั้งรหัสผ่านใหม่">คลิกที่นี่</a> เพื่อตั้งรหัสผ่านใหม่</p>
            <form action="/login" method="post" className="form devise-form" id="login_form">
              <input type="hidden" name="authenticity_token" value={this.state.csrfToken} />
              <div className="form-group form-label-upper">
                <label htmlFor="" className="control-label">
                  อีเมล์ <i className="icon icon-user"></i>
                </label>
                <input type="text" className="form-control" name="user[email]" autoComplete="off" onChange={(e) => this.handleChange(e)} value={this.state.email} data-id="email" />
              </div>
              <div className="form-group form-label-upper">
                <label htmlFor="" className="control-label">
                  รหัสผ่าน <i className="icon icon-lock"></i>
                </label>
                <input type="password" className="form-control" name="user[password]" onChange={(e) => this.handleChange(e)} value={this.state.password} data-id="password" />
              </div>
              <div className="form-group">
                <input type="checkbox" name="user[remember_me]" id="remember_me" className="jd-checkbox" value="1" />
                <label htmlFor="remember_me" className="jd-checkbox-label large">
                  จำการเข้าสู่ระบบเอาไว้
                </label>
              </div>
              <div className="form-group action-group">
                <button className="btn btn-secondary" type="button" name="commit" onClick={this.submitForm}>เข้าสู่ระบบ</button>
                <a href="#" className="btn btn-facebook" onClick={e => this.submitFacebookButton(e)}>
                  <i className="icon icon-facebook"></i> เข้าสู่ระบบด้วย Facebook
                </a>
              </div>
              <div className="modal-footer-form">
                <p>หากคุณยังไม่ได้เป็นสมาชิกของ Accounting Center</p>
                <a href="#" className="go-register btn btn-primary">สมัครสมาชิก ฟรี!</a>
              </div>
            </form>
            <form action="/auth/facebook" method="post" id="login_facebook_form" style={{display: 'none'}}>
              <input type="hidden" name="authenticity_token" value={this.state.csrfToken} />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginModal
