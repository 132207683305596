import React from "react"
import axios from 'axios'

import {isUpgradedListing, programTypesTH, programAttributeTH, getExcerpt, sortPostTier} from './../helper/utils'
class ProgramResultsList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      items: [],
      static: this.props.static ? this.props.static : false,
      saved: (this.props.saved && this.props.saved.length > 0) ? this.props.saved : []
    }
  }

  toggleFavourite = (type, id, e) => {
    if (e) e.preventDefault()
    let button = e.currentTarget
    axios.post('/listings/favourite', { listing_type: type, listing_id: id })
    .then((response) => {
      if (response.data.status === 200) {
        button.classList.contains('active') ? button.classList.remove('active') : button.classList.add('active')
      } else {
        alertMessage('ไม่สามารถบันทึกประกาศนี้ได้ กรุณาลองอีกครั้ง')
      }
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    if (this.props.items && this.props.items.length > 0) {
      let items = this.props.items
      this.setState({items})
    }
  }

  render() {
    return (
      <div className="result-main-content">
        {
          this.state.items && this.state.items.length > 0
          ? this.state.items.map(listing => (
            <div key={listing.id} className={`program-result-type ${this.state.static ? 'static' : ''} ${isUpgradedListing(listing.post_tier) ? `premium ${listing.post_tier}` : ''}`}>
              {/* image */}
              <a href={`/listings/info/program/${listing.id}`} className="_image" alt={listing.title}>
                <img src={listing.cover.thumb.url ? listing.cover.thumb.url : '/img/placeholder800.jpg' } alt={listing.title} />
              </a>
              {/* content */}
              <div className="_content">
                <h3 className="_title"><a href={`/listings/info/program/${listing.id}`} title={listing.program_name}>{listing.program_name}</a></h3>
                {
                  listing.program_types && listing.program_types.length > 0 
                    ? <p className="_author">{listing.program_types.map( (type, i) => `${i != 0 ? ', ' : ''} ${programTypesTH(type)}`)}</p>
                    : null
                }
                {
                  listing.program_attributes && listing.program_attributes.length > 0
                    ? <div className="_meta">
                        <span><i className="icon icon-monitor"></i>{listing.program_attributes.map( (attr, i) => `${i != 0 ? ', ' : ''} ${programAttributeTH(attr)}` )}</span>
                      </div>
                    : null
                }
                {
                  isUpgradedListing(listing.post_tier)
                    ? <p className="_description">{listing.content ? `${getExcerpt(listing.content, 150)}.. อ่านต่อ` : ''}</p>
                    : null
                }
              </div>
              {/* favorite */}
              {
                this.state.static
                  ? null
                  : (
                    <a href="#" className={`favourite-button ${this.state.saved.includes(listing.id) ? 'active' : ''} ${this.props.currentUserId ? "" : "go-login"} `} title="บันทึกเป็นรายการโปรด" onClick={ e => this.props.currentUserId ? this.toggleFavourite("Program", listing.id, e) : false}>
                      <span><i className="icon icon-heart"></i></span>
                    </a>
                  )
              }
            </div>
          ))
          : (
            <div className="empty-listing-wrapper">
              <img src="/img/empty-listing.png" alt="ไม่พบประกาศใดๆในตอนนี้"/>
              <p>ยังไม่มีรายการในขณะนี้</p>
            </div>
          )
        }
        {/* navigation */}
        {
          this.state.static
            ? null
            : this.props.totalPages > 0 
              ? (
                <div className="page-navigation-block">
                  <a href="#" className="btn btn-secondary">หน้าที่แล้ว</a>
                  <a href="#" className="btn btn-secondary">หน้าถัดไป</a>
                </div>
              )
              : null
        }
      </div>
    );
  }
}

export default ProgramResultsList
