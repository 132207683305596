tinymce.addI18n('th_TH',{
"Redo": "\u0e17\u0e33\u0e43\u0e2b\u0e21\u0e48\u0e2d\u0e35\u0e01",
"Undo": "\u0e40\u0e1b\u0e25\u0e35\u0e48\u0e22\u0e19\u0e01\u0e25\u0e31\u0e1a\u0e04\u0e37\u0e19",
"Cut": "\u0e15\u0e31\u0e14",
"Copy": "\u0e04\u0e31\u0e14\u0e25\u0e2d\u0e01",
"Paste": "\u0e27\u0e32\u0e07",
"Select all": "\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",
"New document": "\u0e40\u0e2d\u0e01\u0e2a\u0e32\u0e23\u0e43\u0e2b\u0e21\u0e48",
"Ok": "\u0e15\u0e01\u0e25\u0e07",
"Cancel": "\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01",
"Visual aids": "\u0e17\u0e31\u0e28\u0e19\u0e39\u0e1b\u0e01\u0e23\u0e13\u0e4c",
"Bold": "\u0e15\u0e31\u0e27\u0e2b\u0e19\u0e32",
"Italic": "\u0e15\u0e31\u0e27\u0e40\u0e2d\u0e35\u0e22\u0e07",
"Underline": "\u0e02\u0e35\u0e14\u0e40\u0e2a\u0e49\u0e19\u0e43\u0e15\u0e49",
"Strikethrough": "\u0e02\u0e35\u0e14\u0e04\u0e23\u0e48\u0e2d\u0e21",
"Superscript": "\u0e15\u0e31\u0e27\u0e22\u0e01",
"Subscript": "\u0e15\u0e31\u0e27\u0e2b\u0e49\u0e2d\u0e22",
"Clear formatting": "\u0e25\u0e49\u0e32\u0e07\u0e01\u0e32\u0e23\u0e08\u0e31\u0e14\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",
"Align left": "\u0e08\u0e31\u0e14\u0e0a\u0e34\u0e14\u0e0b\u0e49\u0e32\u0e22",
"Align center": "\u0e08\u0e31\u0e14\u0e01\u0e36\u0e48\u0e07\u0e01\u0e25\u0e32\u0e07",
"Align right": "\u0e08\u0e31\u0e14\u0e0a\u0e34\u0e14\u0e02\u0e27\u0e32",
"Justify": "\u0e40\u0e15\u0e47\u0e21\u0e41\u0e19\u0e27",
"Bullet list": "\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2b\u0e31\u0e27\u0e02\u0e49\u0e2d\u0e22\u0e48\u0e2d\u0e22",
"Numbered list": "\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e25\u0e33\u0e14\u0e31\u0e1a\u0e40\u0e25\u0e02",
"Decrease indent": "\u0e25\u0e14\u0e01\u0e32\u0e23\u0e40\u0e22\u0e37\u0e49\u0e2d\u0e07",
"Increase indent": "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e01\u0e32\u0e23\u0e40\u0e22\u0e37\u0e49\u0e2d\u0e07",
"Close": "\u0e1b\u0e34\u0e14",
"Formats": "\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",
"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "\u0e40\u0e1a\u0e23\u0e32\u0e27\u0e4c\u0e40\u0e0b\u0e2d\u0e23\u0e4c\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e01\u0e32\u0e23\u0e40\u0e02\u0e49\u0e32\u0e16\u0e36\u0e07\u0e42\u0e14\u0e22\u0e15\u0e23\u0e07\u0e44\u0e1b\u0e22\u0e31\u0e07\u0e04\u0e25\u0e34\u0e1b\u0e1a\u0e2d\u0e23\u0e4c\u0e14 \u0e01\u0e23\u0e38\u0e13\u0e32\u0e43\u0e0a\u0e49\u0e41\u0e1b\u0e49\u0e19\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e25\u0e31\u0e14 Ctrl+X\/C\/V \u0e41\u0e17\u0e19",
"Headers": "\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27",
"Header 1": "\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27 1",
"Header 2": "\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27 2",
"Header 3": "\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27 3",
"Header 4": "\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27 4",
"Header 5": "\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27 5",
"Header 6": "\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27 6",
"Headings": "\u0e2b\u0e31\u0e27\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07",
"Heading 1": "\u0e2b\u0e31\u0e27\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e17\u0e35\u0e48 1",
"Heading 2": "\u0e2b\u0e31\u0e27\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e17\u0e35\u0e48 2",
"Heading 3": "\u0e2b\u0e31\u0e27\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e17\u0e35\u0e48 3",
"Heading 4": "\u0e2b\u0e31\u0e27\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e17\u0e35\u0e48 4",
"Heading 5": "\u0e2b\u0e31\u0e27\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e17\u0e35\u0e48 5",
"Heading 6": "\u0e2b\u0e31\u0e27\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e17\u0e35\u0e48 6",
"Preformatted": "\u0e1f\u0e2d\u0e23\u0e4c\u0e41\u0e21\u0e15\u0e44\u0e27\u0e49\u0e01\u0e48\u0e2d\u0e19",
"Div": "Div",
"Pre": "Pre",
"Code": "\u0e23\u0e2b\u0e31\u0e2a",
"Paragraph": "\u0e22\u0e48\u0e2d\u0e2b\u0e19\u0e49\u0e32",
"Blockquote": "Blockquote",
"Inline": "\u0e41\u0e1a\u0e1a\u0e2d\u0e34\u0e19\u0e44\u0e25\u0e19\u0e4c",
"Blocks": "\u0e1a\u0e25\u0e4a\u0e2d\u0e04",
"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "\u0e01\u0e32\u0e23\u0e27\u0e32\u0e07\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49\u0e2d\u0e22\u0e39\u0e48\u0e43\u0e19\u0e42\u0e2b\u0e21\u0e14\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e18\u0e23\u0e23\u0e21\u0e14\u0e32 \u0e40\u0e19\u0e37\u0e49\u0e2d\u0e2b\u0e32\u0e08\u0e30\u0e16\u0e39\u0e01\u0e27\u0e32\u0e07\u0e40\u0e1b\u0e47\u0e19\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e18\u0e23\u0e23\u0e21\u0e14\u0e32\u0e08\u0e19\u0e01\u0e27\u0e48\u0e32\u0e04\u0e38\u0e13\u0e08\u0e30\u0e1b\u0e34\u0e14\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e19\u0e35\u0e49",
"Fonts": "\u0e41\u0e1a\u0e1a\u0e2d\u0e31\u0e01\u0e29\u0e23",
"Font Sizes": "\u0e02\u0e19\u0e32\u0e14\u0e41\u0e1a\u0e1a\u0e2d\u0e31\u0e01\u0e29\u0e23",
"Class": "\u0e0a\u0e31\u0e49\u0e19",
"Browse for an image": "\u0e40\u0e23\u0e35\u0e22\u0e01\u0e14\u0e39\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",
"OR": "OR",
"Drop an image here": "\u0e27\u0e32\u0e07\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e17\u0e35\u0e48\u0e19\u0e35\u0e48",
"Upload": "\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14",
"Block": "\u0e1a\u0e25\u0e47\u0e2d\u0e01",
"Align": "Align",
"Default": "\u0e04\u0e48\u0e32\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19",
"Circle": "\u0e27\u0e07\u0e01\u0e25\u0e21",
"Disc": "\u0e14\u0e34\u0e2a\u0e01\u0e4c",
"Square": "\u0e08\u0e31\u0e15\u0e38\u0e23\u0e31\u0e2a",
"Lower Alpha": "\u0e2d\u0e31\u0e25\u0e1f\u0e32\u0e17\u0e35\u0e48\u0e15\u0e48\u0e33\u0e01\u0e27\u0e48\u0e32",
"Lower Greek": "\u0e01\u0e23\u0e35\u0e01\u0e17\u0e35\u0e48\u0e15\u0e48\u0e33\u0e01\u0e27\u0e48\u0e32",
"Lower Roman": "\u0e42\u0e23\u0e21\u0e31\u0e19\u0e17\u0e35\u0e48\u0e15\u0e48\u0e33\u0e01\u0e27\u0e48\u0e32",
"Upper Alpha": "\u0e2d\u0e31\u0e25\u0e1f\u0e32\u0e17\u0e35\u0e48\u0e2a\u0e39\u0e07\u0e01\u0e27\u0e48\u0e32",
"Upper Roman": "\u0e42\u0e23\u0e21\u0e31\u0e19\u0e17\u0e35\u0e48\u0e2a\u0e39\u0e07\u0e01\u0e27\u0e48\u0e32",
"Anchor...": "\u0e08\u0e38\u0e14\u0e22\u0e36\u0e14...",
"Name": "\u0e0a\u0e37\u0e48\u0e2d",
"Id": "Id",
"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Id \u0e04\u0e27\u0e23\u0e08\u0e30\u0e02\u0e36\u0e49\u0e19\u0e15\u0e49\u0e19\u0e14\u0e49\u0e27\u0e22\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23 \u0e15\u0e32\u0e21\u0e14\u0e49\u0e27\u0e22\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23 \u0e15\u0e31\u0e27\u0e40\u0e25\u0e02 \u0e02\u0e35\u0e14\u0e01\u0e25\u0e32\u0e07 \u0e08\u0e38\u0e14 \u0e2d\u0e31\u0e12\u0e20\u0e32\u0e04 \u0e2b\u0e23\u0e37\u0e2d \u0e02\u0e35\u0e14\u0e25\u0e48\u0e32\u0e07",
"You have unsaved changes are you sure you want to navigate away?": "\u0e04\u0e38\u0e13\u0e21\u0e35\u0e01\u0e32\u0e23\u0e40\u0e1b\u0e25\u0e35\u0e48\u0e22\u0e19\u0e41\u0e1b\u0e25\u0e07\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01 \u0e04\u0e38\u0e13\u0e15\u0e49\u0e2d\u0e07\u0e01\u0e32\u0e23\u0e17\u0e35\u0e48\u0e08\u0e30\u0e2d\u0e2d\u0e01\u0e2b\u0e23\u0e37\u0e2d\u0e44\u0e21\u0e48?",
"Restore last draft": "\u0e04\u0e37\u0e19\u0e04\u0e48\u0e32\u0e41\u0e1a\u0e1a\u0e23\u0e48\u0e32\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",
"Special character...": "\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e1e\u0e34\u0e40\u0e28\u0e29...",
"Source code": "\u0e42\u0e04\u0e49\u0e14\u0e15\u0e49\u0e19\u0e09\u0e1a\u0e31\u0e1a",
"Insert\/Edit code sample": "\u0e41\u0e17\u0e23\u0e01\/\u0e41\u0e01\u0e49\u0e44\u0e02\u0e15\u0e31\u0e27\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e42\u0e04\u0e49\u0e14",
"Language": "\u0e20\u0e32\u0e29\u0e32",
"Code sample...": "\u0e15\u0e31\u0e27\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e42\u0e04\u0e49\u0e14...",
"Color Picker": "\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2a\u0e35",
"R": "\u0e41\u0e14\u0e07",
"G": "\u0e40\u0e02\u0e35\u0e22\u0e27",
"B": "\u0e19\u0e49\u0e33\u0e40\u0e07\u0e34\u0e19",
"Left to right": "\u0e0b\u0e49\u0e32\u0e22\u0e44\u0e1b\u0e02\u0e27\u0e32",
"Right to left": "\u0e02\u0e27\u0e32\u0e44\u0e1b\u0e0b\u0e49\u0e32\u0e22",
"Emoticons...": "\u0e2d\u0e35\u0e42\u0e21\u0e15\u0e34\u0e04\u0e2d\u0e19...",
"Metadata and Document Properties": "\u0e40\u0e21\u0e15\u0e32\u0e14\u0e32\u0e15\u0e49\u0e32\u0e41\u0e25\u0e30\u0e04\u0e38\u0e13\u0e2a\u0e21\u0e1a\u0e31\u0e15\u0e34\u0e02\u0e2d\u0e07\u0e40\u0e2d\u0e01\u0e2a\u0e32\u0e23",
"Title": "\u0e0a\u0e37\u0e48\u0e2d\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07",
"Keywords": "\u0e04\u0e33\u0e2a\u0e33\u0e04\u0e31\u0e0d",
"Description": "\u0e04\u0e33\u0e2d\u0e18\u0e34\u0e1a\u0e32\u0e22",
"Robots": "\u0e2b\u0e38\u0e48\u0e19\u0e22\u0e19\u0e15\u0e4c",
"Author": "\u0e1c\u0e39\u0e49\u0e40\u0e02\u0e35\u0e22\u0e19",
"Encoding": "\u0e01\u0e32\u0e23\u0e40\u0e02\u0e49\u0e32\u0e23\u0e2b\u0e31\u0e2a",
"Fullscreen": "\u0e40\u0e15\u0e47\u0e21\u0e08\u0e2d",
"Action": "\u0e01\u0e32\u0e23\u0e01\u0e23\u0e30\u0e17\u0e33",
"Shortcut": "\u0e17\u0e32\u0e07\u0e25\u0e31\u0e14",
"Help": "\u0e0a\u0e48\u0e27\u0e22\u0e40\u0e2b\u0e25\u0e37\u0e2d",
"Address": "\u0e17\u0e35\u0e48\u0e2d\u0e22\u0e39\u0e48",
"Focus to menubar": "\u0e42\u0e1f\u0e01\u0e31\u0e2a\u0e44\u0e1b\u0e17\u0e35\u0e48\u0e40\u0e21\u0e19\u0e39\u0e1a\u0e32\u0e23\u0e4c",
"Focus to toolbar": "\u0e42\u0e1f\u0e01\u0e31\u0e2a\u0e44\u0e1b\u0e17\u0e35\u0e48\u0e41\u0e16\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e21\u0e37\u0e2d",
"Focus to element path": "\u0e42\u0e1f\u0e01\u0e31\u0e2a\u0e44\u0e1b\u0e17\u0e35\u0e48\u0e40\u0e2a\u0e49\u0e19\u0e17\u0e32\u0e07\u0e02\u0e2d\u0e07\u0e2d\u0e07\u0e04\u0e4c\u0e1b\u0e23\u0e30\u0e01\u0e2d\u0e1a",
"Focus to contextual toolbar": "\u0e42\u0e1f\u0e01\u0e31\u0e2a\u0e44\u0e1b\u0e17\u0e35\u0e48\u0e41\u0e16\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e21\u0e37\u0e2d\u0e15\u0e32\u0e21\u0e1a\u0e23\u0e34\u0e1a\u0e17",
"Insert link (if link plugin activated)": "\u0e41\u0e17\u0e23\u0e01\u0e25\u0e34\u0e07\u0e01\u0e4c (\u0e2b\u0e32\u0e01\u0e40\u0e1b\u0e34\u0e14\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19\u0e25\u0e34\u0e07\u0e01\u0e4c)",
"Save (if save plugin activated)": "\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01 (\u0e2b\u0e32\u0e01\u0e40\u0e1b\u0e34\u0e14\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01)",
"Find (if searchreplace plugin activated)": "\u0e04\u0e49\u0e19\u0e2b\u0e32 (\u0e2b\u0e32\u0e01\u0e40\u0e1b\u0e34\u0e14\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19 searchreplace)",
"Plugins installed ({0}):": "\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19\u0e17\u0e35\u0e48\u0e15\u0e34\u0e14\u0e15\u0e31\u0e49\u0e07\u0e41\u0e25\u0e49\u0e27 ({0}):",
"Premium plugins:": "\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19\u0e1e\u0e23\u0e35\u0e40\u0e21\u0e35\u0e22\u0e21:",
"Learn more...": "\u0e40\u0e23\u0e35\u0e22\u0e19\u0e23\u0e39\u0e49\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21...",
"You are using {0}": "\u0e04\u0e38\u0e13\u0e01\u0e33\u0e25\u0e31\u0e07\u0e43\u0e0a\u0e49 {0}",
"Plugins": "\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19",
"Handy Shortcuts": "\u0e17\u0e32\u0e07\u0e25\u0e31\u0e14\u0e14\u0e49\u0e27\u0e22\u0e21\u0e37\u0e2d",
"Horizontal line": "\u0e40\u0e2a\u0e49\u0e19\u0e41\u0e19\u0e27\u0e19\u0e2d\u0e19",
"Insert\/edit image": "\u0e41\u0e17\u0e23\u0e01\/\u0e41\u0e01\u0e49\u0e44\u0e02\u0e23\u0e39\u0e1b",
"Image description": "\u0e04\u0e33\u0e2d\u0e18\u0e34\u0e1a\u0e32\u0e22\u0e23\u0e39\u0e1b",
"Source": "\u0e41\u0e2b\u0e25\u0e48\u0e07\u0e17\u0e35\u0e48\u0e21\u0e32",
"Dimensions": "\u0e02\u0e19\u0e32\u0e14",
"Constrain proportions": "\u0e08\u0e33\u0e01\u0e31\u0e14\u0e2a\u0e31\u0e14\u0e2a\u0e48\u0e27\u0e19",
"General": "\u0e17\u0e31\u0e48\u0e27\u0e44\u0e1b",
"Advanced": "\u0e02\u0e31\u0e49\u0e19\u0e2a\u0e39\u0e07",
"Style": "\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",
"Vertical space": "\u0e0a\u0e48\u0e2d\u0e07\u0e27\u0e48\u0e32\u0e07\u0e41\u0e19\u0e27\u0e15\u0e31\u0e49\u0e07",
"Horizontal space": "\u0e0a\u0e48\u0e2d\u0e07\u0e27\u0e48\u0e32\u0e07\u0e41\u0e19\u0e27\u0e19\u0e2d\u0e19",
"Border": "\u0e40\u0e2a\u0e49\u0e19\u0e02\u0e2d\u0e1a",
"Insert image": "\u0e41\u0e17\u0e23\u0e01\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",
"Image...": "\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e...",
"Image list": "\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",
"Rotate counterclockwise": "\u0e2b\u0e21\u0e38\u0e19\u0e17\u0e27\u0e19\u0e40\u0e02\u0e47\u0e21\u0e19\u0e32\u0e2c\u0e34\u0e01\u0e32",
"Rotate clockwise": "\u0e2b\u0e21\u0e38\u0e19\u0e15\u0e32\u0e21\u0e40\u0e02\u0e47\u0e21\u0e19\u0e32\u0e2c\u0e34\u0e01\u0e32",
"Flip vertically": "\u0e1e\u0e25\u0e34\u0e01\u0e15\u0e32\u0e21\u0e41\u0e19\u0e27\u0e15\u0e31\u0e49\u0e07",
"Flip horizontally": "\u0e1e\u0e25\u0e34\u0e01\u0e15\u0e32\u0e21\u0e41\u0e19\u0e27\u0e19\u0e2d\u0e19",
"Edit image": "\u0e41\u0e01\u0e49\u0e44\u0e02\u0e23\u0e39\u0e1b",
"Image options": "\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",
"Zoom in": "\u0e02\u0e22\u0e32\u0e22\u0e40\u0e02\u0e49\u0e32",
"Zoom out": "\u0e22\u0e48\u0e2d\u0e2d\u0e2d\u0e01",
"Crop": "\u0e04\u0e23\u0e2d\u0e1b\u0e15\u0e31\u0e14",
"Resize": "\u0e1b\u0e23\u0e31\u0e1a\u0e02\u0e19\u0e32\u0e14",
"Orientation": "\u0e01\u0e32\u0e23\u0e08\u0e31\u0e14\u0e27\u0e32\u0e07",
"Brightness": "\u0e04\u0e27\u0e32\u0e21\u0e2a\u0e27\u0e48\u0e32\u0e07",
"Sharpen": "\u0e04\u0e27\u0e32\u0e21\u0e04\u0e21",
"Contrast": "\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e23\u0e35\u0e22\u0e1a\u0e15\u0e48\u0e32\u0e07",
"Color levels": "\u0e23\u0e30\u0e14\u0e31\u0e1a\u0e2a\u0e35",
"Gamma": "\u0e41\u0e01\u0e21\u0e21\u0e32",
"Invert": "\u0e22\u0e49\u0e2d\u0e19\u0e01\u0e25\u0e31\u0e1a",
"Apply": "\u0e19\u0e33\u0e44\u0e1b\u0e43\u0e0a\u0e49",
"Back": "\u0e01\u0e25\u0e31\u0e1a",
"Insert date\/time": "\u0e41\u0e17\u0e23\u0e01\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\/\u0e40\u0e27\u0e25\u0e32",
"Date\/time": "\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\/\u0e40\u0e27\u0e25\u0e32",
"Insert\/Edit Link": "\u0e41\u0e17\u0e23\u0e01\/\u0e41\u0e01\u0e49\u0e44\u0e02\u0e25\u0e34\u0e07\u0e01\u0e4c",
"Insert\/edit link": "\u0e41\u0e17\u0e23\u0e01\/\u0e41\u0e01\u0e49\u0e44\u0e02\u0e25\u0e34\u0e07\u0e01\u0e4c",
"Text to display": "\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e2a\u0e14\u0e07",
"Url": "URL",
"Open link in...": "\u0e40\u0e1b\u0e34\u0e14\u0e25\u0e34\u0e07\u0e01\u0e4c\u0e43\u0e19...",
"Current window": "\u0e2b\u0e19\u0e49\u0e32\u0e15\u0e48\u0e32\u0e07\u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",
"None": "\u0e44\u0e21\u0e48\u0e21\u0e35",
"New window": "\u0e40\u0e1b\u0e34\u0e14\u0e2b\u0e19\u0e49\u0e32\u0e15\u0e48\u0e32\u0e07\u0e43\u0e2b\u0e21\u0e48",
"Remove link": "\u0e40\u0e2d\u0e32\u0e25\u0e34\u0e07\u0e01\u0e4c\u0e2d\u0e2d\u0e01",
"Anchors": "\u0e08\u0e38\u0e14\u0e22\u0e36\u0e14",
"Link...": "\u0e25\u0e34\u0e07\u0e01\u0e4c...",
"Paste or type a link": "\u0e27\u0e32\u0e07\u0e2b\u0e23\u0e37\u0e2d\u0e1b\u0e49\u0e2d\u0e19\u0e25\u0e34\u0e07\u0e01\u0e4c",
"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "URL \u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e23\u0e30\u0e1a\u0e38\u0e14\u0e39\u0e40\u0e2b\u0e21\u0e37\u0e2d\u0e19\u0e27\u0e48\u0e32\u0e40\u0e1b\u0e47\u0e19\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e41\u0e2d\u0e14\u0e40\u0e14\u0e23\u0e2a \u0e04\u0e38\u0e13\u0e15\u0e49\u0e2d\u0e07\u0e01\u0e32\u0e23\u0e43\u0e2a\u0e48 mailto: \u0e19\u0e33\u0e2b\u0e19\u0e49\u0e32\u0e2b\u0e23\u0e37\u0e2d\u0e44\u0e21\u0e48",
"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "URL \u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e23\u0e30\u0e1a\u0e38\u0e14\u0e39\u0e40\u0e2b\u0e21\u0e37\u0e2d\u0e19\u0e27\u0e48\u0e32\u0e40\u0e1b\u0e47\u0e19\u0e25\u0e34\u0e07\u0e01\u0e4c\u0e20\u0e32\u0e22\u0e19\u0e2d\u0e01 \u0e04\u0e38\u0e13\u0e15\u0e49\u0e2d\u0e07\u0e01\u0e32\u0e23\u0e43\u0e2a\u0e48 http:\/\/ \u0e19\u0e33\u0e2b\u0e19\u0e49\u0e32\u0e2b\u0e23\u0e37\u0e2d\u0e44\u0e21\u0e48",
"Link list": "\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e25\u0e34\u0e07\u0e01\u0e4c",
"Insert video": "\u0e41\u0e17\u0e23\u0e01\u0e27\u0e34\u0e14\u0e35\u0e42\u0e2d",
"Insert\/edit video": "\u0e41\u0e17\u0e23\u0e01\/\u0e41\u0e01\u0e49\u0e44\u0e02\u0e27\u0e34\u0e14\u0e35\u0e42\u0e2d",
"Insert\/edit media": "\u0e41\u0e17\u0e23\u0e01\/\u0e41\u0e01\u0e49\u0e44\u0e02\u0e2a\u0e37\u0e48\u0e2d",
"Alternative source": "\u0e41\u0e2b\u0e25\u0e48\u0e07\u0e17\u0e35\u0e48\u0e21\u0e32\u0e2a\u0e33\u0e23\u0e2d\u0e07",
"Alternative source URL": "URL \u0e41\u0e2b\u0e25\u0e48\u0e07\u0e17\u0e35\u0e48\u0e21\u0e32\u0e2a\u0e33\u0e23\u0e2d\u0e07",
"Media poster (Image URL)": "\u0e42\u0e1b\u0e2a\u0e40\u0e15\u0e2d\u0e23\u0e4c\u0e21\u0e35\u0e40\u0e14\u0e35\u0e22 (URL \u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e)",
"Paste your embed code below:": "\u0e27\u0e32\u0e07\u0e42\u0e04\u0e49\u0e14\u0e1d\u0e31\u0e07\u0e15\u0e31\u0e27\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e14\u0e49\u0e32\u0e19\u0e25\u0e48\u0e32\u0e07:",
"Embed": "\u0e1d\u0e31\u0e07",
"Media...": "\u0e21\u0e35\u0e40\u0e14\u0e35\u0e22...",
"Nonbreaking space": "\u0e0a\u0e48\u0e2d\u0e07\u0e27\u0e48\u0e32\u0e07\u0e44\u0e21\u0e48\u0e41\u0e22\u0e01",
"Page break": "\u0e15\u0e31\u0e27\u0e41\u0e1a\u0e48\u0e07\u0e2b\u0e19\u0e49\u0e32",
"Paste as text": "\u0e27\u0e32\u0e07\u0e40\u0e1b\u0e47\u0e19\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21",
"Preview": "\u0e41\u0e2a\u0e14\u0e07\u0e15\u0e31\u0e27\u0e2d\u0e22\u0e48\u0e32\u0e07",
"Print...": "\u0e1e\u0e34\u0e21\u0e1e\u0e4c...",
"Save": "\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01",
"Find": "\u0e04\u0e49\u0e19\u0e2b\u0e32",
"Replace with": "\u0e41\u0e17\u0e19\u0e17\u0e35\u0e48\u0e14\u0e49\u0e27\u0e22",
"Replace": "\u0e41\u0e17\u0e19\u0e17\u0e35\u0e48",
"Replace all": "\u0e41\u0e17\u0e19\u0e17\u0e35\u0e48\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",
"Previous": "\u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32\u0e19\u0e35\u0e49",
"Next": "\u0e16\u0e31\u0e14\u0e44\u0e1b",
"Find and replace...": "\u0e04\u0e49\u0e19\u0e2b\u0e32\u0e41\u0e25\u0e30\u0e41\u0e17\u0e19\u0e17\u0e35\u0e48...",
"Could not find the specified string.": "\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e2a\u0e15\u0e23\u0e34\u0e07\u0e17\u0e35\u0e48\u0e23\u0e30\u0e1a\u0e38",
"Match case": "\u0e15\u0e23\u0e07\u0e15\u0e32\u0e21\u0e15\u0e31\u0e27\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e43\u0e2b\u0e0d\u0e48-\u0e40\u0e25\u0e47\u0e01",
"Find whole words only": "\u0e04\u0e49\u0e19\u0e2b\u0e32\u0e17\u0e31\u0e49\u0e07\u0e04\u0e33\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",
"Spell check": "\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e04\u0e33\u0e1c\u0e34\u0e14",
"Ignore": "\u0e25\u0e30\u0e40\u0e27\u0e49\u0e19",
"Ignore all": "\u0e25\u0e30\u0e40\u0e27\u0e49\u0e19\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",
"Finish": "\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e34\u0e49\u0e19",
"Add to Dictionary": "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e43\u0e19\u0e1e\u0e08\u0e19\u0e32\u0e19\u0e38\u0e01\u0e23\u0e21",
"Insert table": "\u0e41\u0e17\u0e23\u0e01\u0e15\u0e32\u0e23\u0e32\u0e07",
"Table properties": "\u0e04\u0e38\u0e13\u0e2a\u0e21\u0e1a\u0e31\u0e15\u0e34\u0e02\u0e2d\u0e07\u0e15\u0e32\u0e23\u0e32\u0e07",
"Delete table": "\u0e25\u0e1a\u0e15\u0e32\u0e23\u0e32\u0e07",
"Cell": "\u0e40\u0e0b\u0e25\u0e25\u0e4c",
"Row": "\u0e41\u0e16\u0e27",
"Column": "\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",
"Cell properties": "\u0e04\u0e38\u0e13\u0e2a\u0e21\u0e1a\u0e31\u0e15\u0e34\u0e02\u0e2d\u0e07\u0e40\u0e0b\u0e25\u0e25\u0e4c",
"Merge cells": "\u0e1c\u0e2a\u0e32\u0e19\u0e40\u0e0b\u0e25\u0e25\u0e4c",
"Split cell": "\u0e41\u0e22\u0e01\u0e40\u0e0b\u0e25\u0e25\u0e4c",
"Insert row before": "\u0e41\u0e17\u0e23\u0e01\u0e41\u0e16\u0e27\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e19",
"Insert row after": "\u0e41\u0e17\u0e23\u0e01\u0e41\u0e16\u0e27\u0e14\u0e49\u0e32\u0e19\u0e25\u0e48\u0e32\u0e07",
"Delete row": "\u0e25\u0e1a\u0e41\u0e16\u0e27",
"Row properties": "\u0e04\u0e38\u0e13\u0e2a\u0e21\u0e1a\u0e31\u0e15\u0e34\u0e02\u0e2d\u0e07\u0e41\u0e16\u0e27",
"Cut row": "\u0e15\u0e31\u0e14\u0e41\u0e16\u0e27",
"Copy row": "\u0e04\u0e31\u0e14\u0e25\u0e2d\u0e01\u0e41\u0e16\u0e27",
"Paste row before": "\u0e27\u0e32\u0e07\u0e41\u0e16\u0e27\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e19",
"Paste row after": "\u0e27\u0e32\u0e07\u0e41\u0e16\u0e27\u0e14\u0e49\u0e32\u0e19\u0e25\u0e48\u0e32\u0e07",
"Insert column before": "\u0e41\u0e17\u0e23\u0e01\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c\u0e02\u0e49\u0e32\u0e07\u0e2b\u0e19\u0e49\u0e32",
"Insert column after": "\u0e41\u0e17\u0e23\u0e01\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c\u0e02\u0e49\u0e32\u0e07\u0e2b\u0e25\u0e31\u0e07",
"Delete column": "\u0e25\u0e1a\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",
"Cols": "\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",
"Rows": "\u0e41\u0e16\u0e27",
"Width": "\u0e04\u0e27\u0e32\u0e21\u0e01\u0e27\u0e49\u0e32\u0e07",
"Height": "\u0e04\u0e27\u0e32\u0e21\u0e2a\u0e39\u0e07",
"Cell spacing": "\u0e0a\u0e48\u0e2d\u0e07\u0e27\u0e48\u0e32\u0e07\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07\u0e40\u0e0b\u0e25\u0e25\u0e4c",
"Cell padding": "\u0e0a\u0e48\u0e2d\u0e07\u0e27\u0e48\u0e32\u0e07\u0e20\u0e32\u0e22\u0e43\u0e19\u0e40\u0e0b\u0e25\u0e25\u0e4c",
"Show caption": "\u0e41\u0e2a\u0e14\u0e07\u0e04\u0e33\u0e1a\u0e23\u0e23\u0e22\u0e32\u0e22",
"Left": "\u0e0b\u0e49\u0e32\u0e22",
"Center": "\u0e01\u0e36\u0e48\u0e07\u0e01\u0e25\u0e32\u0e07",
"Right": "\u0e02\u0e27\u0e32",
"Cell type": "\u0e0a\u0e19\u0e34\u0e14\u0e02\u0e2d\u0e07\u0e40\u0e0b\u0e25\u0e25\u0e4c",
"Scope": "\u0e02\u0e2d\u0e1a\u0e40\u0e02\u0e15",
"Alignment": "\u0e01\u0e32\u0e23\u0e08\u0e31\u0e14\u0e41\u0e19\u0e27",
"H Align": "\u0e01\u0e32\u0e23\u0e40\u0e23\u0e35\u0e22\u0e07\u0e43\u0e19\u0e41\u0e19\u0e27\u0e19\u0e2d\u0e19",
"V Align": "\u0e01\u0e32\u0e23\u0e40\u0e23\u0e35\u0e22\u0e07\u0e43\u0e19\u0e41\u0e19\u0e27\u0e15\u0e31\u0e49\u0e07",
"Top": "\u0e1a\u0e19",
"Middle": "\u0e01\u0e25\u0e32\u0e07",
"Bottom": "\u0e25\u0e48\u0e32\u0e07",
"Header cell": "\u0e40\u0e0b\u0e25\u0e25\u0e4c\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27",
"Row group": "\u0e01\u0e25\u0e38\u0e48\u0e21\u0e41\u0e16\u0e27",
"Column group": "\u0e01\u0e25\u0e38\u0e48\u0e21\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",
"Row type": "\u0e0a\u0e19\u0e34\u0e14\u0e02\u0e2d\u0e07\u0e41\u0e16\u0e27",
"Header": "\u0e2a\u0e48\u0e27\u0e19\u0e2b\u0e31\u0e27",
"Body": "\u0e40\u0e19\u0e37\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21",
"Footer": "\u0e2a\u0e48\u0e27\u0e19\u0e17\u0e49\u0e32\u0e22",
"Border color": "\u0e2a\u0e35\u0e02\u0e2d\u0e1a",
"Insert template...": "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e41\u0e21\u0e48\u0e41\u0e1a\u0e1a...",
"Templates": "\u0e41\u0e21\u0e48\u0e41\u0e1a\u0e1a",
"Template": "\u0e41\u0e21\u0e48\u0e41\u0e1a\u0e1a",
"Text color": "\u0e2a\u0e35\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21",
"Background color": "\u0e2a\u0e35\u0e1e\u0e37\u0e49\u0e19\u0e2b\u0e25\u0e31\u0e07",
"Custom...": "\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e40\u0e2d\u0e07",
"Custom color": "\u0e2a\u0e35\u0e17\u0e35\u0e48\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e40\u0e2d\u0e07",
"No color": "\u0e44\u0e21\u0e48\u0e21\u0e35\u0e2a\u0e35",
"Remove color": "\u0e25\u0e1a\u0e2a\u0e35",
"Table of Contents": "\u0e2a\u0e32\u0e23\u0e1a\u0e31\u0e0d",
"Show blocks": "\u0e41\u0e2a\u0e14\u0e07\u0e1a\u0e25\u0e47\u0e2d\u0e01",
"Show invisible characters": "\u0e41\u0e2a\u0e14\u0e07\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23\u0e17\u0e35\u0e48\u0e21\u0e2d\u0e07\u0e44\u0e21\u0e48\u0e40\u0e2b\u0e47\u0e19",
"Word count": "\u0e19\u0e31\u0e1a\u0e08\u0e33\u0e19\u0e27\u0e19\u0e04\u0e33",
"Count": "\u0e19\u0e31\u0e1a",
"Document": "\u0e40\u0e2d\u0e01\u0e2a\u0e32\u0e23",
"Selection": "\u0e01\u0e32\u0e23\u0e40\u0e25\u0e37\u0e2d\u0e01",
"Words": "\u0e04\u0e33",
"Words: {0}": "\u0e04\u0e33: {0}",
"{0} words": "{0} \u0e04\u0e33",
"File": "\u0e44\u0e1f\u0e25\u0e4c",
"Edit": "\u0e41\u0e01\u0e49\u0e44\u0e02",
"Insert": "\u0e41\u0e17\u0e23\u0e01",
"View": "\u0e21\u0e38\u0e21\u0e21\u0e2d\u0e07",
"Format": "\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",
"Table": "\u0e15\u0e32\u0e23\u0e32\u0e07",
"Tools": "\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e21\u0e37\u0e2d",
"Powered by {0}": "\u0e02\u0e31\u0e1a\u0e40\u0e04\u0e25\u0e37\u0e48\u0e2d\u0e19\u0e42\u0e14\u0e22 {0}",
"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48 Rich Text \u0e01\u0e14 ALT-F9 \u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e40\u0e21\u0e19\u0e39 \u0e01\u0e14 ALT-F10 \u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e41\u0e16\u0e1a\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e21\u0e37\u0e2d \u0e01\u0e14 ALT-0 \u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e0a\u0e48\u0e27\u0e22\u0e40\u0e2b\u0e25\u0e37\u0e2d",
"Image title": "\u0e0a\u0e37\u0e48\u0e2d\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",
"Border width": "\u0e04\u0e27\u0e32\u0e21\u0e01\u0e27\u0e49\u0e32\u0e07\u0e40\u0e2a\u0e49\u0e19\u0e02\u0e2d\u0e1a",
"Border style": "\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e40\u0e2a\u0e49\u0e19\u0e02\u0e2d\u0e1a",
"Error": "\u0e04\u0e27\u0e32\u0e21\u0e1c\u0e34\u0e14\u0e1e\u0e25\u0e32\u0e14",
"Warn": "\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e40\u0e15\u0e37\u0e2d\u0e19",
"Valid": "\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07",
"To open the popup, press Shift+Enter": "\u0e01\u0e14 Shift+Enter \u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e40\u0e1b\u0e34\u0e14\u0e1b\u0e4a\u0e2d\u0e1a\u0e2d\u0e31\u0e1e",
"Rich Text Area. Press ALT-0 for help.": "\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48 Rich Text \u0e01\u0e14 ALT-0 \u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e0a\u0e48\u0e27\u0e22\u0e40\u0e2b\u0e25\u0e37\u0e2d",
"System Font": "\u0e41\u0e1a\u0e1a\u0e2d\u0e31\u0e01\u0e29\u0e23\u0e02\u0e2d\u0e07\u0e23\u0e30\u0e1a\u0e1a",
"Failed to upload image: {0}": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e: {0}",
"Failed to load plugin: {0} from url {1}": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e42\u0e2b\u0e25\u0e14\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19: {0} \u0e08\u0e32\u0e01 url {1}",
"Failed to load plugin url: {0}": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e42\u0e2b\u0e25\u0e14 url \u0e02\u0e2d\u0e07\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19: {0}",
"Failed to initialize plugin: {0}": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19\u0e1b\u0e25\u0e31\u0e4a\u0e01\u0e2d\u0e34\u0e19: {0}",
"example": "\u0e15\u0e31\u0e27\u0e2d\u0e22\u0e48\u0e32\u0e07",
"Search": "\u0e04\u0e49\u0e19\u0e2b\u0e32",
"All": "\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",
"Currency": "\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19",
"Text": "\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21",
"Quotations": "\u0e43\u0e1a\u0e40\u0e2a\u0e19\u0e2d\u0e23\u0e32\u0e04\u0e32",
"Mathematical": "\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e01\u0e31\u0e1a\u0e04\u0e13\u0e34\u0e15\u0e28\u0e32\u0e2a\u0e15\u0e23\u0e4c",
"Extended Latin": "\u0e20\u0e32\u0e29\u0e32\u0e25\u0e32\u0e15\u0e34\u0e19\u0e2a\u0e48\u0e27\u0e19\u0e02\u0e22\u0e32\u0e22",
"Symbols": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c",
"Arrows": "\u0e25\u0e39\u0e01\u0e28\u0e23",
"User Defined": "\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e40\u0e2d\u0e07",
"dollar sign": "\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e14\u0e2d\u0e25\u0e25\u0e48\u0e32\u0e23\u0e4c",
"currency sign": "\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19",
"euro-currency sign": "\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e22\u0e39\u0e42\u0e23",
"colon sign": "\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e08\u0e38\u0e14\u0e04\u0e39\u0e48",
"cruzeiro sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e04\u0e23\u0e39\u0e40\u0e0b\u0e42\u0e35\u0e23",
"french franc sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e1f\u0e23\u0e31\u0e07\u0e01\u0e4c\u0e1d\u0e23\u0e31\u0e48\u0e07\u0e40\u0e28\u0e2a",
"lira sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e25\u0e35\u0e23\u0e32",
"mill sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e21\u0e34\u0e25\u0e25\u0e4c",
"naira sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e44\u0e19\u0e23\u0e32",
"peseta sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e40\u0e1b\u0e40\u0e0b\u0e15\u0e32",
"rupee sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e23\u0e39\u0e1b\u0e35",
"won sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e27\u0e2d\u0e19",
"new sheqel sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e19\u0e34\u0e27\u0e40\u0e0a\u0e40\u0e01\u0e25",
"dong sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e14\u0e2d\u0e07",
"kip sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e01\u0e35\u0e1a",
"tugrik sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e17\u0e39\u0e01\u0e23\u0e34\u0e01",
"drachma sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e14\u0e23\u0e31\u0e04\u0e21\u0e32",
"german penny symbol": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e40\u0e1e\u0e19\u0e19\u0e35\u0e40\u0e22\u0e2d\u0e23\u0e21\u0e31\u0e19",
"peso sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e40\u0e1b\u0e42\u0e0b",
"guarani sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e01\u0e27\u0e32\u0e23\u0e32\u0e19\u0e35",
"austral sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e2d\u0e2d\u0e2a\u0e15\u0e23\u0e31\u0e25",
"hryvnia sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e2e\u0e23\u0e34\u0e1f\u0e40\u0e19\u0e35\u0e22",
"cedi sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e40\u0e0b\u0e14\u0e35",
"livre tournois sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e1b\u0e2d\u0e19\u0e14\u0e4c\u0e15\u0e39\u0e23\u0e4c",
"spesmilo sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e2a\u0e40\u0e1b\u0e2a\u0e21\u0e34\u0e42\u0e25",
"tenge sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e40\u0e17\u0e07\u0e40\u0e08",
"indian rupee sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e23\u0e39\u0e1b\u0e35\u0e2d\u0e34\u0e19\u0e40\u0e14\u0e35\u0e22",
"turkish lira sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e25\u0e35\u0e23\u0e32\u0e15\u0e38\u0e23\u0e01\u0e35",
"nordic mark sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e21\u0e32\u0e23\u0e4c\u0e04\u0e19\u0e2d\u0e23\u0e4c\u0e14\u0e34\u0e01",
"manat sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e21\u0e32\u0e19\u0e31\u0e15",
"ruble sign": "\u0e2a\u0e31\u0e0d\u0e25\u0e31\u0e01\u0e29\u0e13\u0e4c\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e23\u0e39\u0e40\u0e1a\u0e34\u0e25",
"yen character": "\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e40\u0e22\u0e19",
"yuan character": "\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e2b\u0e22\u0e27\u0e19",
"yuan character, in hong kong and taiwan": "\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e2b\u0e22\u0e27\u0e19 \u0e43\u0e19\u0e2e\u0e48\u0e2d\u0e07\u0e01\u0e07\u0e41\u0e25\u0e30\u0e44\u0e15\u0e49\u0e2b\u0e27\u0e31\u0e19",
"yen\/yuan character variant one": "\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e40\u0e22\u0e19\/\u0e2b\u0e22\u0e27\u0e19 \u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e17\u0e35\u0e48 1",
"Loading emoticons...": "\u0e01\u0e33\u0e25\u0e31\u0e07\u0e42\u0e2b\u0e25\u0e14\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e41\u0e2a\u0e14\u0e07\u0e2d\u0e32\u0e23\u0e21\u0e13\u0e4c...",
"Could not load emoticons": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e42\u0e2b\u0e25\u0e14\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e41\u0e2a\u0e14\u0e07\u0e2d\u0e32\u0e23\u0e21\u0e13\u0e4c\u0e44\u0e14\u0e49",
"People": "\u0e1c\u0e39\u0e49\u0e04\u0e19",
"Animals and Nature": "\u0e2a\u0e31\u0e15\u0e27\u0e4c\u0e41\u0e25\u0e30\u0e18\u0e23\u0e23\u0e21\u0e0a\u0e32\u0e15\u0e34",
"Food and Drink": "\u0e2d\u0e32\u0e2b\u0e32\u0e23\u0e41\u0e25\u0e30\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e14\u0e37\u0e48\u0e21",
"Activity": "\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21",
"Travel and Places": "\u0e01\u0e32\u0e23\u0e17\u0e48\u0e2d\u0e07\u0e40\u0e17\u0e35\u0e48\u0e22\u0e27\u0e41\u0e25\u0e30\u0e2a\u0e16\u0e32\u0e19\u0e17\u0e35\u0e48",
"Objects": "\u0e27\u0e31\u0e15\u0e16\u0e38",
"Flags": "\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22",
"Characters": "\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23",
"Characters (no spaces)": "\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23 (\u0e44\u0e21\u0e48\u0e21\u0e35\u0e0a\u0e48\u0e2d\u0e07\u0e27\u0e48\u0e32\u0e07)",
"{0} characters": "{0} \u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30",
"Error: Form submit field collision.": "\u0e02\u0e49\u0e2d\u0e1c\u0e34\u0e14\u0e1e\u0e25\u0e32\u0e14: \u0e0a\u0e48\u0e2d\u0e07\u0e2a\u0e48\u0e07\u0e41\u0e1a\u0e1a\u0e1f\u0e2d\u0e23\u0e4c\u0e21\u0e02\u0e31\u0e14\u0e41\u0e22\u0e49\u0e07\u0e01\u0e31\u0e19",
"Error: No form element found.": "\u0e02\u0e49\u0e2d\u0e1c\u0e34\u0e14\u0e1e\u0e25\u0e32\u0e14: \u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e2d\u0e07\u0e04\u0e4c\u0e1b\u0e23\u0e30\u0e01\u0e2d\u0e1a\u0e02\u0e2d\u0e07\u0e1f\u0e2d\u0e23\u0e4c\u0e21",
"Update": "\u0e1b\u0e23\u0e31\u0e1a\u0e1b\u0e23\u0e38\u0e07\u0e43\u0e2b\u0e49\u0e17\u0e31\u0e19\u0e2a\u0e21\u0e31\u0e22",
"Color swatch": "\u0e41\u0e16\u0e1a\u0e2a\u0e35",
"Turquoise": "\u0e19\u0e49\u0e33\u0e40\u0e07\u0e34\u0e19\u0e2d\u0e21\u0e40\u0e02\u0e35\u0e22\u0e27",
"Green": "\u0e40\u0e02\u0e35\u0e22\u0e27",
"Blue": "\u0e19\u0e49\u0e33\u0e40\u0e07\u0e34\u0e19",
"Purple": "\u0e21\u0e48\u0e27\u0e07",
"Navy Blue": "\u0e19\u0e49\u0e33\u0e40\u0e07\u0e34\u0e19\u0e40\u0e02\u0e49\u0e21",
"Dark Turquoise": "\u0e2a\u0e35\u0e1f\u0e49\u0e32\u0e04\u0e23\u0e32\u0e21\u0e40\u0e02\u0e49\u0e21",
"Dark Green": "\u0e40\u0e02\u0e35\u0e22\u0e27\u0e40\u0e02\u0e49\u0e21",
"Medium Blue": "\u0e19\u0e49\u0e33\u0e40\u0e07\u0e34\u0e19\u0e1b\u0e32\u0e19\u0e01\u0e25\u0e32\u0e07",
"Medium Purple": "\u0e2a\u0e35\u0e21\u0e48\u0e27\u0e07\u0e01\u0e25\u0e32\u0e07\u0e46",
"Midnight Blue": "\u0e2a\u0e35\u0e1f\u0e49\u0e32\u0e40\u0e02\u0e49\u0e21",
"Yellow": "\u0e40\u0e2b\u0e25\u0e37\u0e2d\u0e07",
"Orange": "\u0e2a\u0e49\u0e21",
"Red": "\u0e41\u0e14\u0e07",
"Light Gray": "\u0e2a\u0e35\u0e40\u0e17\u0e32\u0e2d\u0e48\u0e2d\u0e19",
"Gray": "\u0e40\u0e17\u0e32",
"Dark Yellow": "\u0e2a\u0e35\u0e40\u0e2b\u0e25\u0e37\u0e2d\u0e07\u0e40\u0e02\u0e49\u0e21",
"Dark Orange": "\u0e2a\u0e49\u0e21\u0e40\u0e02\u0e49\u0e21",
"Dark Red": "\u0e41\u0e14\u0e07\u0e40\u0e02\u0e49\u0e21",
"Medium Gray": "\u0e2a\u0e35\u0e40\u0e17\u0e32\u0e01\u0e25\u0e32\u0e07\u0e46",
"Dark Gray": "\u0e2a\u0e35\u0e40\u0e17\u0e32\u0e40\u0e02\u0e49\u0e21",
"Light Green": "\u0e40\u0e02\u0e35\u0e22\u0e27\u0e2d\u0e48\u0e2d\u0e19",
"Light Yellow": "\u0e40\u0e2b\u0e25\u0e37\u0e2d\u0e07\u0e2d\u0e48\u0e2d\u0e19",
"Light Red": "\u0e41\u0e14\u0e07\u0e2d\u0e48\u0e2d\u0e19",
"Light Purple": "\u0e21\u0e48\u0e27\u0e07\u0e2d\u0e48\u0e2d\u0e19",
"Light Blue": "\u0e19\u0e49\u0e33\u0e40\u0e07\u0e34\u0e19\u0e2d\u0e48\u0e2d\u0e19",
"Dark Purple": "\u0e21\u0e48\u0e27\u0e07\u0e40\u0e02\u0e49\u0e21",
"Dark Blue": "\u0e19\u0e49\u0e33\u0e40\u0e07\u0e34\u0e19\u0e40\u0e02\u0e49\u0e21",
"Black": "\u0e14\u0e33",
"White": "\u0e02\u0e32\u0e27",
"Switch to or from fullscreen mode": "\u0e2a\u0e25\u0e31\u0e1a\u0e44\u0e1b\u0e22\u0e31\u0e07\u0e2b\u0e23\u0e37\u0e2d\u0e08\u0e32\u0e01\u0e42\u0e2b\u0e21\u0e14\u0e40\u0e15\u0e47\u0e21\u0e2b\u0e19\u0e49\u0e32\u0e08\u0e2d",
"Open help dialog": "\u0e40\u0e1b\u0e34\u0e14\u0e2b\u0e19\u0e49\u0e32\u0e01\u0e32\u0e23\u0e0a\u0e48\u0e27\u0e22\u0e40\u0e2b\u0e25\u0e37\u0e2d",
"history": "\u0e1b\u0e23\u0e30\u0e27\u0e31\u0e15\u0e34",
"styles": "\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",
"formatting": "\u0e01\u0e32\u0e23\u0e08\u0e31\u0e14\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",
"alignment": "\u0e01\u0e32\u0e23\u0e08\u0e31\u0e14\u0e41\u0e19\u0e27",
"indentation": "\u0e01\u0e32\u0e23\u0e08\u0e31\u0e14\u0e22\u0e48\u0e2d\u0e2b\u0e19\u0e49\u0e32",
"permanent pen": "\u0e1b\u0e32\u0e01\u0e01\u0e32\u0e40\u0e04\u0e21\u0e35\u0e0a\u0e19\u0e34\u0e14\u0e25\u0e1a\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49",
"comments": "\u0e02\u0e49\u0e2d\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19",
"Format Painter": "\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e40\u0e1e\u0e19\u0e40\u0e15\u0e2d\u0e23\u0e4c",
"Insert\/edit iframe": "\u0e41\u0e17\u0e23\u0e01\/\u0e41\u0e01\u0e49\u0e44\u0e02 iframe",
"Capitalization": "\u0e01\u0e32\u0e23\u0e43\u0e0a\u0e49\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23\u0e15\u0e31\u0e27\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e43\u0e2b\u0e0d\u0e48",
"lowercase": "\u0e15\u0e31\u0e27\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e40\u0e25\u0e47\u0e01",
"UPPERCASE": "\u0e15\u0e31\u0e27\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e43\u0e2b\u0e0d\u0e48",
"Title Case": "\u0e15\u0e31\u0e27\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e02\u0e2d\u0e07\u0e2b\u0e31\u0e27\u0e02\u0e49\u0e2d",
"Permanent Pen Properties": "\u0e04\u0e38\u0e13\u0e2a\u0e21\u0e1a\u0e31\u0e15\u0e34\u0e1b\u0e32\u0e01\u0e01\u0e32\u0e21\u0e32\u0e23\u0e4c\u0e04\u0e40\u0e01\u0e2d\u0e23\u0e4c",
"Permanent pen properties...": "\u0e04\u0e38\u0e13\u0e2a\u0e21\u0e1a\u0e31\u0e15\u0e34\u0e1b\u0e32\u0e01\u0e01\u0e32\u0e21\u0e32\u0e23\u0e4c\u0e04\u0e40\u0e01\u0e2d\u0e23\u0e4c...",
"Font": "\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23",
"Size": "\u0e02\u0e19\u0e32\u0e14",
"More...": "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21...",
"Spellcheck Language": "\u0e20\u0e32\u0e29\u0e32\u0e43\u0e19\u0e01\u0e32\u0e23\u0e15\u0e23\u0e27\u0e08\u0e01\u0e32\u0e23\u0e2a\u0e30\u0e01\u0e14",
"Select...": "\u0e40\u0e25\u0e37\u0e2d\u0e01...",
"Preferences": "\u0e04\u0e48\u0e32\u0e01\u0e33\u0e2b\u0e19\u0e14",
"Yes": "\u0e43\u0e0a\u0e48",
"No": "\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48",
"Keyboard Navigation": "\u0e01\u0e32\u0e23\u0e19\u0e33\u0e17\u0e32\u0e07\u0e14\u0e49\u0e27\u0e22\u0e41\u0e1b\u0e49\u0e19\u0e1e\u0e34\u0e21\u0e1e\u0e4c",
"Version": "\u0e23\u0e38\u0e48\u0e19",
"Anchor": "\u0e08\u0e38\u0e14\u0e22\u0e36\u0e14",
"Special character": "\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e1e\u0e34\u0e40\u0e28\u0e29",
"Code sample": "\u0e15\u0e31\u0e27\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e42\u0e04\u0e49\u0e14",
"Color": "\u0e2a\u0e35",
"Emoticons": "\u0e2d\u0e34\u0e42\u0e21\u0e15\u0e34\u0e04\u0e2d\u0e19",
"Document properties": "\u0e04\u0e38\u0e13\u0e2a\u0e21\u0e1a\u0e31\u0e15\u0e34\u0e02\u0e2d\u0e07\u0e40\u0e2d\u0e01\u0e2a\u0e32\u0e23",
"Image": "\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e",
"Insert link": "\u0e41\u0e17\u0e23\u0e01\u0e25\u0e34\u0e07\u0e01\u0e4c",
"Target": "\u0e40\u0e1b\u0e49\u0e32\u0e2b\u0e21\u0e32\u0e22",
"Link": "\u0e25\u0e34\u0e07\u0e01\u0e4c",
"Poster": "\u0e42\u0e1b\u0e2a\u0e40\u0e15\u0e2d\u0e23\u0e4c",
"Media": "\u0e2a\u0e37\u0e48\u0e2d",
"Print": "\u0e1e\u0e34\u0e21\u0e1e\u0e4c",
"Prev": "\u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32",
"Find and replace": "\u0e04\u0e49\u0e19\u0e2b\u0e32\u0e41\u0e25\u0e30\u0e41\u0e17\u0e19\u0e17\u0e35\u0e48",
"Whole words": "\u0e17\u0e31\u0e49\u0e07\u0e04\u0e33",
"Spellcheck": "\u0e15\u0e23\u0e27\u0e08\u0e01\u0e32\u0e23\u0e2a\u0e30\u0e01\u0e14",
"Caption": "\u0e1b\u0e49\u0e32\u0e22\u0e04\u0e33\u0e2d\u0e18\u0e34\u0e1a\u0e32\u0e22",
"Insert template": "\u0e41\u0e17\u0e23\u0e01\u0e41\u0e21\u0e48\u0e41\u0e1a\u0e1a"
});