import React from "react"
import Slider from "react-slick"
import { isMobileOnly } from "react-device-detect"

class HomepageBanner extends React.Component {
  render () {
    let sliderSettings = {
      dots: true,
      arrows: false,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 6000,
      focusOnSelect: false,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1
    }
    return (
      <section className="homepage-banner-block">
        <Slider {...sliderSettings}>
          {
            this.props.items.length > 0 
              ? this.props.items.map( (banner, i) => {
                let mobileBanner = banner["mobile"] ? banner["mobile"] : '/img/mockup-banner-mobile.jpg'
                let desktopBanner = banner["full"] ? banner["full"] : '/img/mockup-banner-web.jpg'
                return (
                  <div key={i}>
                    <a target="_blank" title="ดูรายละเอียดเพิ่มเติม" href={banner["link"]}>
                      <img src={isMobileOnly ? mobileBanner : desktopBanner} alt="Copyright by accountingcenter.co" />
                    </a>
                  </div>
                )
              })
              : null
          }
        </Slider>
      </section>
    );
  }
}

export default HomepageBanner
