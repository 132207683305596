const Swal = require('sweetalert2')
const googleMapsURL = `https://maps.googleapis.com/maps/api/js?&language=th&key=${process.env.GOOGLE_API}`
const is = require('is_js')
const axios = require('axios').default

const choicesOptions = {
  searchFields: ['label'],
  position: 'bottom',
  noResultsText: 'ไม่ตรงกับผลลัพธ์ใด',
  noChoicesText: 'ไม่มีรายการ',
  searchResultLimit: 60,
  shouldSort: false,
  itemSelectText: '',
  fuseOptions: {
    findAllMatches: false,
    distance: 0,
    threshold: 0
  }
}

const findTopOfElement = (element) => {
  let bodyRect = document.body.getBoundingClientRect()
  let elemRect = element.getBoundingClientRect()
  offetTop = elemRect.top - bodyRect.top
  return offetTop
}

const isEmailValid = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}

const alertMessage = (message, focusEl = null) => {
  Swal.fire({
    icon: 'error',
    title: 'การแจ้งเตือน',
    text: message,
    showClass: {
      popup: 'flip-in-hor-bottom',
      backdrop: 'swal2-noanimation'
    },
    hideClass: {
      popup: 'flip-out-hor-top',
      backdrop: 'swal2-noanimation'
    },
    onAfterClose: e => {
      if (focusEl) focusEl.focus()
    }
  })
}

const succeedMessage = (message) => {
  Swal.fire({
    icon: 'success',
    title: 'การแจ้งเตือน',
    text: message,
    showClass: {
      popup: 'flip-in-hor-bottom',
      backdrop: 'swal2-noanimation'
    },
    hideClass: {
      popup: 'flip-out-hor-top',
      backdrop: 'swal2-noanimation'
    },
  })
}

const getExcerpt = (content, length) => {
  let pureString = content.replace(/<\/?[^>]+(>|$)/g, '')
  pureString = pureString.replace(/\&nbsp;/g, '').substring(0,length)
  return pureString
}

const formatThaiDate = (d) => {
  let date = new Date(d)
  return date.toLocaleDateString('th-TH', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

const readAndPreviewImage = (input, previewEl) => {
  if (input.files && input.files[0]) {
    let reader = new FileReader()
    reader.onload = function(e) {
      previewEl.setAttribute('src', e.target.result)
    }
    reader.readAsDataURL(input.files[0])
  }
}

const setImagePlaceHolderEvent = (placeholderBlock) => {
  let link = placeholderBlock.querySelector('a')
  let file = placeholderBlock.querySelector('input[type=file]')
  let preview = placeholderBlock.querySelector('.preview')
  let mockupText = placeholderBlock.querySelector('.mockup')
  let resetButton = placeholderBlock.querySelector('._reset')
  let removeButton = placeholderBlock.querySelector('._remove')
  // check image was exist first!
  if (preview && preview.classList.contains('exists')) {
    if (mockupText) mockupText.classList.add('hide')
    // if (resetButton) resetButton.classList.remove('hide')
    if (removeButton) removeButton.classList.remove('hide')
  } else {
    if (mockupText) mockupText.classList.remove('hide')
    if (resetButton) resetButton.classList.add('hide')
    if (removeButton) removeButton.classList.add('hide')
  }
  link.addEventListener('click', e => {
    if (e) e.preventDefault()
    file.click()
  })
  file.addEventListener('change', e => {
    if (e) e.preventDefault()
    readAndPreviewImage(e.target, preview)
    if (e.target.files && e.target.files[0]) {
      if (mockupText) mockupText.classList.add('hide')
      if (resetButton) resetButton.classList.remove('hide')
    } else {
      if (mockupText) mockupText.classList.remove('hide')
      if (resetButton) resetButton.classList.add('hide')
    }
  })
  if (resetButton) {
    resetButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      preview.setAttribute('src', '/img/placeholder800.jpg')
      file.value = ''
      if (mockupText) mockupText.classList.remove('hide')
      if (resetButton) resetButton.classList.add('hide')
    })
  }
  if (removeButton) {
    removeButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      let conf = confirm("ต้องการลบรูปภาพหรือไม่? หากลบแล้วจะไม่สามารถกู้ข้อมูลคืนมาได้")
      if (conf) {
        let preloaderBlock = document.querySelector('.preloader-block')
        let listingId = e.currentTarget.dataset.id
        let listingType = e.currentTarget.dataset.type
        let imageType = e.currentTarget.dataset.imageType
        let imageIndex = e.currentTarget.dataset.imageIndex || null
        preloaderBlock.classList.add('active')
        axios.post(`/member/listings/${listingId}/remove-listing-image`, { 
          listing_type: listingType, 
          image_type: imageType,
          image_index: imageIndex ? imageIndex : null,
        }).then( response => {
          preloaderBlock.classList.remove('active')
          if (response.data.status === 200) {
            succeedMessage(response.data.payload)
            preview.setAttribute('src', '/img/placeholder800.jpg')
            file.value = ''
            if (mockupText) mockupText.classList.remove('hide')
            if (removeButton) removeButton.classList.add('hide')
          } else {
            alertMessage(response.data.payload)
            return false
          }
        }).catch( err => alertMessage(err) )
        
      }
    })
  }
}

const checkFieldIsEmpty = (el, message) => {
  if (el) {
    if (is.empty(el.value) || el.value == "NaN") { 
      el.classList.contains('form-control') ? el.classList.add('has-error') : null
      alertMessage(message, el)
      return true
    } else {
      return false
    }
  }
}

const noCheckboxChecked = (wrapperEl, message) => {
  if (wrapperEl.querySelectorAll('input[type=checkbox]:checked').length <= 0) {
    alertMessage(message)
    return true
  } else {
    return false
  }
}

const noRadioboxChecked = (wrapperEl, message) => {
  if (wrapperEl.querySelectorAll('input[type=radio]:checked').length <= 0) {
    alertMessage(message)
    return true
  } else {
    return false
  }
}

const operatingStatusTH = (operatingStatus) => {
  return  operatingStatus === 'individual' ? "รับจ้างทำบัญชีอิสระ / รับทำบัญชี ฟรีแลนซ์" : "สำนักงานบัญชี"
}

const workingStatusTH = (workingStatus) => {
  return workingStatus === 'individual' ? "รับตรวจสอบบัญชีอิสระ" : "สังกัดสำนักงานสอบบัญชี"
}

const companyCertifiedByTFAC = (flag) => {
  return (flag === 0 || flag === false) ? "" : "สำนักงานบัญชีคุณภาพ"
}

const companyCertifiedByDBD = (flag) => {
  return (flag === 0 || flag === false) ? "" : "สำนักงานบัญชีคุณภาพ"
}

const companyCertifiedByRd = (flag) => {
  return (flag === 0 || flag === false ) ? "" : "สำนักงานบัญชีตัวแทน"
}

const setRequireFieldAfterRemoveSequenceItem = (wrapper, targetClass, newId, requiredText) => {
  let sameClasses = document.querySelectorAll(targetClass)
  // clear all other require fields first
  if (sameClasses) {
    sameClasses.forEach(el => {
      el.removeAttribute('id')
      let label = el.closest('.form-group').querySelector('label')
      label.innerHTML = requiredText.replace('<span class="req">*</span>', '')
    })
  }
  // set target field to required
  let targetField = wrapper.querySelector(targetClass)
  if (targetField) {
    targetField.setAttribute('id', newId)
    let targetLabel = targetField.closest('.form-group').querySelector('label')
    targetLabel.innerHTML = requiredText
  }
}

const isUpgradedListing = (tier = null) => {
  return tier && tier != 'general'
}

const courseTypeTH = (courseType) => {
  let mapping = {
    "offline": "บรรยาย สัมมนา (offline)",
    "e-learning": "E-learning (online)",
    "live": "สอนสดผ่านโปรแกรมออนไลน์ (online)",
  }
  return mapping[courseType]
}

const formatThaiBaht = (price) => {
  return price ? `${new Intl.NumberFormat('th-TH').format(price)} บาท` : ''
}

const programTypesTH = (type) => {
  let mapping = {
    "accounting": "โปรแกรมบัญชี",
    "payroll_hr": "โปรแกรมเงินเดือน HR",
    "management": "โปรแกรมบริหารจัดการธุรกิจ",
    "erp": "โปรแกรม ERP",
    "other": "โปรแกรมอื่นๆ",
    "storefront": "โปรแกรมร้านค้า",
    "ecommerce": "โปรแกรมร้านค้าออนไลน์",
    "retails": "โปรแกรมตัวแทนจำหน่าย",
    "restaurant": "โปรแกรมร้านอาหาร",
    "service": "โปรแกรมธุรกิจบริการ",
    "hotel_and_resort": "โปรแกรมโรงแรม โฮสเทล",
    "apartment": "โปรแกรมหอพัก อพาร์ทเม้นท์",
    "juristic": "โปรแกรมนิติบุคคล, หมู่บ้านจัดสรร"
  }
  return mapping[type]
}

const programAttributeTH = (attr) => {
  let mapping = {
    "offline": "Offline",
    "web": "Web-based",
    "ios": "iOS Application",
    "android": "Android Application"
  }
  return mapping[attr]
}

const getModelImage = (imageObject, size = null, isBlog = false) => {
  if (imageObject) {
    return size && size === 'thumb' ? imageObject.thumb.url : imageObject.url
  } else {
    return size && size === 'thumb' ? 'https://www.placehold.it/180x180' : (isBlog ? '/img/placeholder800-450.jpg' : '/img/placeholder800.jpg')
  }
}

const isObjectEmpty = (obj) => {
  if (!obj) return true
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const tinyFESettings = {
  selector: '.fe-editor',
  plugins: ['lists', 'table', 'image', 'paste', 'link'],
  menu: {
    edit: { title: 'แก้ไข', items: 'undo redo | cut copy paste | selectall' },
    insert: { title: 'แทรก', items: 'inserttable charmap emoticons hr anchor' },
    format: { title: 'แบบอักษร', items: 'bold italic underline strikethrough superscript subscript formats | forecolor backcolor | removeformat' },
    table: { title: 'ตาราง', items: 'inserttable | cell row column | tableprops deletetable' },
  },
  menubar: 'edit insert format table',
  toolbar: 'undo redo | bold italic link image | alignleft aligncenter alignright alignjustify | outdent indent numlist bullist',
  oninit : 'setPlainText',
  skin: false,
  branding: false,
  language: 'th_TH',
  body_class: 'editor-class',
  content_css: '/editor.css',
  content_style: "body { font-family: 'cs_chatthaiuiregular'; font-size: 16px; line-height: 1.8 }",
  min_height: 480,
  paste_as_text: true,
  block_formats: false,
  default_link_target: '_blank',
  target_list: [
    {title: 'เปิดหน้าต่างใหม่', value: '_blank'},
  ],
  rel_list: [
    {title: 'External Link', value: 'nofollow ugc'},
    {title: 'Follow Link', value: 'dofollow'},
    {title: 'Sponsored Link', value: 'sponsored'}
  ],
  style_formats: [
    {
      title: 'พาดหัว 1',
      block: 'h1',
      styles: { "font-size": "28px", "line-height": "1.8" }
    },
    {
      title: 'พาดหัว 2',
      block: 'h2',
      styles: { "font-size": "20px", "line-height": "1.8" }
    },
    {
      title: 'เน้นข้อความ',
      block: 'blockquote',
      styles: { "background-color": "#F4f4f4", "padding": "24px 16px", "margin": "0px", "border-left": "3px solid #ececec" }
    },
    {
      title: 'ไฮไลท์ส้ม',
      inline: 'span',
      styles: { "color": "#FB6900" }
    },
    {
      title: 'ไฮไลท์น้ำเงิน',
      inline: 'span',
      styles: { "color": "#1D72B2" }
    },
    {
      title: 'ตัวอักษรธรรมดา',
      block: 'p',
      styles: { "color": "#000000", "font-size": "16px", "line-height": "1.8" }
    },
  ],
  images_upload_handler: (blobInfo, success, failure) => {
    let xhr, formData
    xhr = new XMLHttpRequest()
    xhr.open('POST', '/tiny-upload-images')
    // set csrf token
    let token = document.querySelector("meta[name=csrf-token]").content
    xhr.setRequestHeader('X-CSRF-Token', token)
    // init new form data 
    formData = new FormData()
    formData.append('file', blobInfo.blob(), blobInfo.filename())
    xhr.send(formData)
    // sending packet to server
    xhr.onload = () => {
      let json
      if (xhr.status != 200) {
        failure('HTTP Error: ' + xhr.status)
        return
      }
      json = JSON.parse(xhr.responseText)
      if (!json || typeof json.payload != 'string') {
        failure('Invalid JSON: ' + xhr.responseText)
        return
      }
      success(json.payload)
    }

  }
}

const sortPostTier = {
  'gold' : 0,
  'silver' : 1,
  'general' : 2
}

const auditorTypeTh = {
  'cpa' : 'ผู้สอบบัญชีรับอนุญาต (CPA)',
  'ta' : 'ผู้สอบบัญชีภาษีอากร (TA)',
}

const listingTypeTHToLink = (listingType) => {
  let mapping = {
    "ผู้ทำบัญชี": "/listings/info/accountant",
    "ผู้สอบบัญชี": "/listings/info/auditor",
    "คอร์สอบรม": "/listings/info/course",
    "วางระบบบัญชี": "/listings/info/consult",
    "โปรแกรมบัญชี": "/listings/info/program",
    "บริการจดทะเบียน": "/listings/info/registration",
  }
  return mapping[listingType]
}

const titleExcerpt = (title, length = 75) => {
  if (!title) return ''
  let moreText = ''
  if (title.length > length) moreText = '..'
  return `${title.substr(0, length)}${moreText}`
}

const registantTypeTH = (type) => {
  return  type === 'accountant' ? "ผู้ทำบัญชี" : "ผู้สอบบัญชี"
}

module.exports = {
  googleMapsURL,
  choicesOptions,
  findTopOfElement,
  isEmailValid,
  alertMessage,
  succeedMessage,
  getExcerpt,
  formatThaiDate,
  readAndPreviewImage,
  setImagePlaceHolderEvent,
  operatingStatusTH,
  companyCertifiedByTFAC,
  companyCertifiedByDBD,
  companyCertifiedByRd,
  noCheckboxChecked, 
  noRadioboxChecked, 
  checkFieldIsEmpty,
  setRequireFieldAfterRemoveSequenceItem,
  workingStatusTH,
  isUpgradedListing,
  courseTypeTH,
  formatThaiBaht,
  programTypesTH,
  programAttributeTH,
  getModelImage,
  isObjectEmpty,
  tinyFESettings,
  sortPostTier,
  auditorTypeTh,
  listingTypeTHToLink,
  titleExcerpt,
  registantTypeTH
}
