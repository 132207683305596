// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

const axios = require('axios').default
const Choices = require('choices.js')
const queryString = require('query-string')
const Swal = require('sweetalert2')
const imagesLoaded = require('imagesloaded')
const scrollToElement = require('scroll-to-element')
const currency = require('currency.js')
const serialize = require('form-serialize')

import Inputmask from 'inputmask'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import parse from 'date-fns/parse'
import th from 'date-fns/locale/th'
import add from 'date-fns/add'
import sub from 'date-fns/sub'

import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/paste'

import './../helper/th_TH'

import flatpickr from 'flatpickr'
import { Thai } from 'flatpickr/dist/l10n/th'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/airbnb.css'

import { 
  choicesOptions, 
  alertMessage, 
  succeedMessage,
  isEmailValid, 
  setImagePlaceHolderEvent,
  tinyFESettings
} from './../helper/utils'

import {
  checkingAccountantListingObject,
  checkingAuditorListingObject,
  checkingCourseListingObject, 
  checkingInfrastructureListingObject,
  checkingProgramListingObject,
  checkingRegistrationListingObject,
  assignDataToRegistrationForm,
} from './../helper/listings'

global.Choices = Choices
window.Choices = Choices
window.Swal = Swal
window.imagesLoaded = imagesLoaded
window.currency = currency
window.axios = axios

require('slick-carousel/slick/slick.css')
require('tippy.js/dist/tippy.css')
require('sweetalert2/src/sweetalert2.scss')

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

let scopeAreaSelect = null
let scopeDistrictSelect = null

const BAHT = value => currency(value, { 
  symbol: '', 
  decimal: '.', 
  separator: ',', 
  pattern: `#`, 
});

let setupCSRFToken = () => {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
}

let setDistrictOptions = (provinceId, defaultValue) => {
  axios.post('/search-district', { province_id: provinceId })
  .then((response) => {
    if (response.status === 200) {
      scopeDistrictSelect.clearChoices()
      scopeDistrictSelect.enable()
      let districtOptions = response.data.payload
      let options = [{ value: 'all', label: 'ทุกเขต', selected: true }]
      for (let i = 0; i < districtOptions.length; i++) {
        options.push({ value: districtOptions[i], label: districtOptions[i], selected: false })
      }
      scopeDistrictSelect.setChoices(options)
      if (defaultValue) scopeDistrictSelect.setChoiceByValue(defaultValue)
    }
  })
  .catch(err => console.log(err))
}

let setDeviseBlockActive = () => {
  let modalBlocks = document.querySelectorAll('.jd-modal-block')
  let deviseBlock = document.querySelector('.devise-block')
  let body = document.getElementsByTagName('body')[0]
  modalBlocks.forEach(el => el.classList.remove('active'))
  deviseBlock.classList.add('active')
  body.classList.add('anti-scroll')
}

let setCheckboxStateForDiploma = (wrapperId, allSequenceItems) => {
  let degreeSelector = allSequenceItems[0].querySelector(wrapperId)
  if (degreeSelector) {
    degreeSelector.addEventListener('change', e => {
      let restrictedOptions = document.querySelectorAll('.diploma-restricted')
      e.currentTarget.value === 'diploma' 
        ? restrictedOptions.forEach(cb => {
          cb.checked = false
          cb.setAttribute('disabled', 'disabled')
        }) 
        : restrictedOptions.forEach(cb => cb.removeAttribute('disabled'))
    })
    triggerCustomEventChange(degreeSelector)
  }
}

let validatePayerInfo = () => {
  let customerName = document.querySelector('input[name="customer_name"]')
  let customerAddress = document.querySelector('textarea[name="customer_address"]')
  let customerTaxId = document.querySelector('input[name="customer_tax_id"]')
  let issueType = document.querySelector('input[name="issue_type"]:checked')
  let isValid = true
  if ( !customerName.value ) {
    alertMessage('กรุณากรอกชื่อสำหรับออกใบเสร็จรับเงิน', customerName)
    isValid = false
    return false
  }
  if ( !issueType ) {
    alertMessage('กรุณาเลือกประเภทที่จะออกใบเสร็จรับเงิน')
    isValid = false
    return false
  }
  if ( !customerTaxId.value ) {
    alertMessage('กรุณากรอกเลขประจำตัวผู้เสียภาษี', customerTaxId)
    isValid = false
    return false
  }
  if ( !customerAddress.value ) {
    alertMessage('กรุณากรอกที่อยู่สำหรับออกใบเสร็จรับเงิน', customerAddress)
    isValid = false
    return false
  }
  return isValid
}

let triggerCustomEventChange = (element) => {
  let evt = document.createEvent('HTMLEvents');
  evt.initEvent('change', false, true);
  element.dispatchEvent(evt);
}

let initAllPagesScripts = () => {
  
  setupCSRFToken()
  let body = document.getElementsByTagName('body')[0]
  let deviseBlock = document.querySelector('.devise-block')
  let showContactButton = document.querySelector('.show-contact-details')
  let homeServiceList = document.querySelector('.badge-services-list')
  let TabsList = document.querySelector('.jd-tabs-list')
  let allBusinessCheckbox = document.querySelector('.all-business-categories')
  let paymentsList = document.querySelector('.payment-gateways-list')
  let preloaderBlock = document.querySelector('.preloader-block')
  let entryContentBlock = document.querySelector('.entry-content-block')
  let mobileToggleMenu = document.querySelector('.mobile-toggle-menu')
  let mobileCloseMenu = document.querySelector('.mobile-close-menu')
  let mobileBackdrop = document.querySelector('.mobile-backdrop')
  let blogSidebarAction = document.querySelector('.blog-sidebar-action')
  let favouriteTabs = document.querySelector('.member-favourites')
  let howList = document.querySelector('.how-list')
  let moreAuthorBlogs = document.querySelector('.details-item.more')
  let entryContent = document.querySelector('.entry-content')
  
  let collapseItems = document.querySelectorAll('.collapse-item')
  let checkboxGroups = document.querySelectorAll('._checkbox-item.checkbox-group')
  let provinceCheckboxes = document.querySelectorAll('.province-checkbox')
  let jdSearchSelects  = document.querySelectorAll('.jd-search-select')
  let closeModalbutton = document.querySelectorAll('.close-modal-button')
  let modalBlocks = document.querySelectorAll('.jd-modal-block')
  let goLoginLinks = document.querySelectorAll('.go-login')
  let goRegisterLinks = document.querySelectorAll('.go-register')
  let goForgotLinks = document.querySelectorAll('.go-forgot')
  let hasSubCheckboxes = document.querySelectorAll('.jd-checkbox.has-sub')
  let checkboxSubLists = document.querySelectorAll('.jd-checkbox-sub-list')
  let radioSubLists = document.querySelectorAll('.jd-radio-sub-list')
  let headerUserAvatar = document.querySelector('.header-user-avatar')
  let formEditors = document.querySelectorAll('.form-editor')
  let feEditors = document.querySelectorAll('.fe-editor')
  let formTags = document.querySelectorAll('.form-tags')
  let facebookShareButtons = document.querySelectorAll('.facebook-share-button')
  let lineShareButtons = document.querySelectorAll('.line-share-button')
  let imagePlaceHolders = document.querySelectorAll('.image-placeholder-block')
  let datePickers = document.querySelectorAll('.date-picker')
  let timePickers = document.querySelectorAll('.time-picker')
  let numericFields = document.querySelectorAll('.jd-numeric')
  let mobileSearchToggleButtons = document.querySelectorAll('.mobile-search-toggle')
  let mobileFilterToggleButtons = document.querySelectorAll('.mobile-filter-toggle')
  let removeGalleryImageButtons = document.querySelectorAll('.remove-gallery-image')
  let removePortFolioImageButtons = document.querySelectorAll('.remove-portfolio-image')
  let timeInputs = document.querySelectorAll('.time-input')
  let issueTypes = document.querySelectorAll('input[name=issue_type]')
  let toggleCheckboxes = document.querySelectorAll('.toggle-checkbox')
  let moreCardItems = document.querySelectorAll('.card-item.more')
  
  let loginModal = document.getElementById('login_modal')
  let forgotModal = document.getElementById('forgot_modal')
  let registerModal = document.getElementById('register_modal')
  let listingForm = document.getElementById('listing_form')
  let wholeKingdomCheckbox = document.getElementById('whole_kingdom')
  let searchScopeArea = document.getElementById('main_search_scope_area')
  let searchScopeDistrict = document.getElementById('main_search_scope_district')
  let searchServiceSelect = document.getElementById('search_service')
  let resultSearchButton = document.getElementById('result_search_button')
  let registerButton = document.getElementById('register_button')
  let forgotButton = document.getElementById('forgot_button')
  let blogSubmitButton = document.getElementById('blog_submit_button')
  let memberSettingsButton = document.getElementById('member_settings_button')
  let whTaxOption = document.getElementById('withholding_tax')
  let upgradeQuantitySelect = document.getElementById('upgrade_quantity_select')
  let goPaymentButton = document.getElementById('go_payment_button')
  let goPlaceOrderButton = document.getElementById('go_place_order')
  
  let chatMessageButton = document.getElementById('chat_message_button')
  let listingContactButton = document.getElementById('listing_contact_button')
  let accountantEducationWrapper = document.getElementById('accountant_education')
  let listingAuditorType = document.getElementById('auditor_auditor_type')
  let courseTypeSwitcher = document.getElementById('course_course_types')

  let submitAccountantButton = document.getElementById('listing_accountant_button')
  let submitAuditorButton = document.getElementById('listing_auditor_button')
  let submitCourseButton = document.getElementById('listing_course_button')
  let submitInfrastructureButton = document.getElementById('listing_infrastructure_button')
  let submitProgramButton = document.getElementById('listing_program_button')
  let submitRegistrationButton = document.getElementById('listing_registration_button')

  let sidebarFilterForm = document.getElementById('sidebar_filter_form')
  let sidebarFilterButton = document.getElementById('sidebar_filter_button')
  let blogListingId = document.getElementById('blog_listing_id')
  let blogListingType = document.getElementById('blog_listing_type')
  let contactForm = document.getElementById('contact_form')
  let confirmPaymentForm = document.getElementById('confirm_payment_form')
  let accessDashboardLink = document.getElementById('access_dashboard_link')
  
  let provinceComboSelect = document.querySelector('.province-combo-select')
  let topNavigationMenu = document.querySelector('.site-navigation')
  
  let copyRegistrationInfoButton = document.getElementById('copy_registration_info_button')

  const params = queryString.parse(location.search)

  goLoginLinks.forEach(el => {
    el.addEventListener('click', (e) => {
      if (e) e.preventDefault()
      setDeviseBlockActive(modalBlocks, deviseBlock)
      loginModal.classList.add('active')
    })
  })

  goRegisterLinks.forEach(el => {
    el.addEventListener('click', (e) => {
      if (e) e.preventDefault()
      setDeviseBlockActive(modalBlocks, deviseBlock)
      registerModal.classList.add('active')
    })
  })

  goForgotLinks.forEach(el => {
    el.addEventListener('click', (e) => {
      if (e) e.preventDefault()
      setDeviseBlockActive(modalBlocks, deviseBlock)
      forgotModal.classList.add('active')
    })
  })

  closeModalbutton.forEach(el => {
    el.addEventListener('click', (e) => {
      if (e) e.preventDefault()
      deviseBlock.classList.remove('active')
      body.classList.remove('anti-scroll')
    })
  })

  if (searchScopeArea) {
    // init choices element
    scopeAreaSelect = new Choices(searchScopeArea, choicesOptions)
    scopeDistrictSelect = new Choices(searchScopeDistrict, choicesOptions)
    if ( searchScopeArea.classList.contains('bangkok') ) {
      let defaultDistrict = searchScopeDistrict.dataset.district
      scopeDistrictSelect.enable()
      scopeAreaSelect.setChoiceByValue('กรุงเทพมหานคร')
      setDistrictOptions(2, defaultDistrict)
    }
    searchScopeArea.addEventListener('change', (e) => {
      scopeDistrictSelect.clearStore()
      scopeDistrictSelect.clearChoices()
      if (e.currentTarget.options[0].text === 'กรุงเทพมหานคร') {
        scopeDistrictSelect.enable()
        setDistrictOptions(e.detail.value)
      } else {
        scopeDistrictSelect.disable()
      }
    }, false)
  }

  if (homeServiceList) {
    let serviceItems = homeServiceList.querySelectorAll('li')
    serviceItems.forEach(item => {
      item.addEventListener('click', (e) => {
        if (e) e.preventDefault()
        let selectedType = item.dataset.value
        let homepageForm = document.getElementById('homepage_form')
        serviceItems.forEach(el => el.classList.remove('active'))
        homepageForm.setAttribute('action', `/search/results/${selectedType}`)
        item.classList.add('active')
      })
    })
  }

  if (TabsList) {
    let tabItems = TabsList.querySelectorAll('li')
    let tabContents = document.querySelectorAll('.jd-tab-content')
    tabItems.forEach(item => {
      item.addEventListener('click', e => {
        if (e) e.preventDefault()
        let targetContentId = item.querySelector('a').getAttribute('href').replace('#', '')
        let targetContentBlock = document.getElementById(targetContentId)
        tabContents.forEach(el => el.classList.remove('active'))
        tabItems.forEach(el => el.classList.remove('active'))
        item.classList.add('active');
        targetContentBlock.classList.add('active');
      })
    })
  }

  if (showContactButton) {
    let contactPointBlock = document.querySelector('._contact-point')
    showContactButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      e.currentTarget.style.display = 'none'
      contactPointBlock.classList.add('active')
      let postId = e.currentTarget.dataset.id
      let postType = e.currentTarget.dataset.type
      axios.post('/reveal', { post_type: postType, post_id: postId })
      .then((response) => { 
        // do nothing 
      })
      .catch(err => console.log(err))
    })
  }

  if (collapseItems) {
    collapseItems.forEach(item => {
      let link = item.querySelector('a')
      link.addEventListener('click', e => {
        e.preventDefault()
        collapseItems.forEach(el => el.classList.remove('active'))
        item.classList.add('active')
        scrollToElement(item, { offset: -16, duration: 300 })
      })
    })
  }

  if (checkboxGroups) {
    checkboxGroups.forEach(groupCheck => {
      groupCheck.addEventListener('change', (e) => {
        let provinceCheckboxes = []
        let groupValue = e.currentTarget.querySelector('input[type="checkbox"]').value
        let provinceGroup = groupCheck.nextElementSibling
        if (provinceGroup) {
          provinceCheckboxes = provinceGroup.querySelectorAll('input[type="checkbox"][id^="' + groupValue + '"]')
        }
        if (e.target.checked) {
          provinceCheckboxes.forEach(checkbox => checkbox.checked = true)
        } else {
          provinceCheckboxes.forEach(checkbox => checkbox.checked = false)
        }
      })
    })
  }

  if (provinceCheckboxes) {
    provinceCheckboxes.forEach(provinceCheckbox => {
      provinceCheckbox.addEventListener('change', (e) => {
        if (!e.target.checked) {
          let group = e.currentTarget.closest('.jd-checkbox-horizontal')
          let regionGroup = group.previousElementSibling
          regionGroup.querySelector('.region-group').checked = false
          wholeKingdomCheckbox.checked = false
        }
      })
    })
  }

  if (allBusinessCheckbox) {
    let businessCategoriesCheckboxes = document.querySelectorAll('.business-category-checkbox')
    allBusinessCheckbox.addEventListener('change', e => {
      let group = e.currentTarget.closest('.checkbox-group') 
      let categoryGroup = group.nextElementSibling
      let categoryCheckboxes = categoryGroup.querySelectorAll('.jd-checkbox')
      if (e.target.checked) {
        categoryCheckboxes.forEach(checkbox => checkbox.checked = true)
      } else {
        categoryCheckboxes.forEach(checkbox => checkbox.checked = false)
      }
    })
    businessCategoriesCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', e => {
        if (!e.target.checked) allBusinessCheckbox.checked = false
      })
    })
  }

  if (wholeKingdomCheckbox) {
    let collapseBody = wholeKingdomCheckbox.closest('._body')
    let allCheckboxes = collapseBody.querySelectorAll('.jd-checkbox')
    wholeKingdomCheckbox.addEventListener('change', e => {
      if (e.target.checked) {
        allCheckboxes.forEach(checkbox => checkbox.checked = true)
      } else {
        allCheckboxes.forEach(checkbox => checkbox.checked = false)
      }
    })
  }

  if (listingForm) {

    listingForm.addEventListener('keydown', e => {
      return e.key != 'Enter'
    })

    let serviceType = document.getElementById('listing_type')
    if (serviceType) {
      serviceType.addEventListener('change', e => {
        // if not login, show login modal
        if (!headerUserAvatar) {
          setDeviseBlockActive(modalBlocks, deviseBlock)
          loginModal.classList.add('active')
        } else {
          listingForm.setAttribute('action', window.location.href)
          listingForm.setAttribute('method', 'GET')
          preloaderBlock.classList.add('active')
          listingForm.submit()
        }
      })
    }
  }
  
  if (jdSearchSelects.length > 0) {
    jdSearchSelects.forEach(item => new Choices(item, choicesOptions))
  }

  // if scope text = bangkok, enable distrcit options
  if (params.province && searchScopeArea && searchScopeArea.options[0].text === 'กรุงเทพมหานคร') {
    setDistrictOptions(searchScopeArea.value, params.district)
  }

  // if service change to program = disable all search scope
  if (searchServiceSelect) {
    searchServiceSelect.addEventListener('change', (e) => {
      if (e.currentTarget.value === 'program') {
        scopeAreaSelect.clearChoices()
        scopeAreaSelect.clearStore()
        scopeDistrictSelect.clearStore()
        scopeAreaSelect.disable()
        scopeDistrictSelect.disable()
      } else {
        if (scopeAreaSelect.passedElement.isDisabled) {
          let existChoices = scopeAreaSelect._presetChoices
          existChoices = existChoices.map(choice => {
            choice.disabled = false
            return choice
          })
          scopeAreaSelect.clearChoices()
          scopeAreaSelect.setChoices(existChoices)
          scopeAreaSelect.enable()
          searchScopeArea.removeAttribute('disabled')
        }
      }
    })
  }

  // submiting search result form
  if (resultSearchButton) {
    resultSearchButton.addEventListener('click', (e) => {
      let serviceType = searchServiceSelect.value
      let newActionUrl = `/search/results/${serviceType}`
      let form = e.currentTarget.closest('form')
      form.setAttribute('action', newActionUrl)
      form.submit()
    })
  }

  // checkbox and subCheckbox event
  if (hasSubCheckboxes) {
    hasSubCheckboxes.forEach(groupCb => {
      let subCheckboxGroup = groupCb.closest('.jd-checkbox-vertical').querySelector('.sub-checkbox-panel')
      let subCheckboxes = subCheckboxGroup.querySelectorAll('.jd-checkbox')
      groupCb.addEventListener('change', e => {
        if (e.target.checked) {
          subCheckboxes.forEach(checkbox => checkbox.checked = true)
        } else {
          subCheckboxes.forEach(checkbox => checkbox.checked = false)
        }
      })
      subCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', e => {
          if (e.target.checked) {
            let checkedCheckbox = e.currentTarget.closest('.sub-checkbox-panel').querySelectorAll('.jd-checkbox:checked')
            if (checkedCheckbox.length == e.currentTarget.closest('.sub-checkbox-panel').querySelectorAll('.jd-checkbox').length) {
              groupCb.checked = true
            }
          } else {
            groupCb.checked = false
          }
        })
      })
    })
  }

  // checkbox with sub list (list of checkboxes inside)
  if (checkboxSubLists) {
    checkboxSubLists.forEach(list => {
      let primaryCheckbox = list.querySelector(':scope > ._checkbox-item > .jd-checkbox')
      let subList = list.querySelector('.jd-checkbox-horizontal')
      let subCheckboxes = subList.querySelectorAll('.jd-checkbox')
      if (primaryCheckbox) {
        primaryCheckbox.addEventListener('change', (e) => {
          if (e.target.checked){
            subCheckboxes.forEach(checkbox => checkbox.checked = true)
          } else {
            subCheckboxes.forEach(checkbox => checkbox.checked = false)
          }
        })
      }
      subCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', e => {
          if (e.target.checked) {
            let checkedCheckbox = subList.querySelectorAll('.jd-checkbox:checked')
            if (checkedCheckbox.length == subList.querySelectorAll('.jd-checkbox').length) {
              primaryCheckbox.checked = true
            }
          } else {
            primaryCheckbox.checked = false
          }
        })
      })
    })
  }

  if (radioSubLists) {
    radioSubLists.forEach(list => {
      let primaryRadio = list.querySelector(':scope > ._checkbox-item > .jd-radiobox')
      let subList = list.querySelector('.jd-checkbox-horizontal')
      let subRadioBoxes = subList.querySelectorAll('.jd-radiobox')
      if (primaryRadio) {
        primaryRadio.addEventListener('change', (e) => {
          if (e.target.checked){
            subRadioBoxes.forEach(checkbox => checkbox.checked = true)
          } else {
            subRadioBoxes.forEach(checkbox => checkbox.checked = false)
          }
        })
      }
    })
  }

  if (registerButton) {
    registerButton.addEventListener('click', e => {
      let button = e.currentTarget
      let form = button.closest('.form')
      let consentCheckbox = form.querySelector('#consent_terms')
      let email = form.querySelector('#user_email')
      let password = form.querySelector('#password')
      let firstName = form.querySelector('#user_first_name')
      let lastName = form.querySelector('#user_last_name')
      if (!consentCheckbox.checked) {
        alertMessage('คุณต้องติ๊กยอมรับข้อตกลงของเราก่อนสมัคร')
        return false
      }
      if (!email.value) {
        alertMessage('กรุณาระบุอีเมล์ของคุณ')
        return false
      }
      if (!isEmailValid(email.value)) {
        alertMessage('รูปแบบของอีเมล์ที่กรอกไม่ถูกต้อง')
        return false
      }
      if (!password.value || password.value.length < 6) {
        alertMessage('รหัสผ่านควรมีความยาวมากกว่า 6 ตัวอักษร')
        return false
      }
      if (!firstName.value) {
        alertMessage('กรุณาระบุชื่อจริงของคุณ')
        return false
      }
      if (!lastName.value) {
        alertMessage('กรุณาระบุนามสกุลของคุณ')
        return false
      }
      preloaderBlock.classList.add('active')
      form.submit()
    })
  }

  if (forgotButton) {
    forgotButton.addEventListener('click', e => {
      let button = e.currentTarget
      let form = button.closest('.form')
      let email = form.querySelector('#user_email')
      if (!email.value) {
        alertMessage('กรุณาระบุอีเมล์ที่คุณใช้สมัครสมาชิกกับเรา')
        return false
      }
      if (!isEmailValid(email.value)) {
        alertMessage('รูปแบบของอีเมล์ที่กรอกไม่ถูกต้อง')
        return false
      }
      preloaderBlock.classList.add('active')
      form.submit()
    })
  }

  if (paymentsList) {
    let gateways = paymentsList.querySelectorAll('.gateway-item')
    let form = document.getElementById('order_summary_form')
    // switch form action depends on gateway
    gateways.forEach(gateway => {
      let link = gateway.querySelector('a')
      link.addEventListener('click', e => {
        e.preventDefault()
        gateways.forEach(gw => gw.classList.remove('active'))
        gateway.classList.add('active')
        if ( link.dataset.channel === 'qr' ) {
          form.setAttribute('action', '#')
          goPaymentButton.innerHTML = 'ยืนยันคำสั่งซื้อ'
          goPaymentButton.style.display = 'inline-block'
          goPlaceOrderButton.style.display = 'none'
        } else if (link.dataset.channel === 'online') {
          form.setAttribute('action', '/member/order/omise-checkout')
          goPaymentButton.innerHTML = 'ยืนยันคำสั่งซื้อ'
          goPaymentButton.style.display = 'inline-block'
          goPlaceOrderButton.style.display = 'none'
        } else {
          form.setAttribute('action', '/member/order/placed')
          goPaymentButton.innerHTML = 'ยืนยันคำสั่งซื้อ'
          goPaymentButton.style.display = 'none'
          goPlaceOrderButton.style.display = 'inline-block'
        }
      })
    })
  }

  if (feEditors) {
    let relCheck = document.getElementById('rel_check')
    let relOptions = []
    let newSettings = tinyFESettings
    if (relCheck.value === 'freerel') {
      relOptions = [
        {title: 'External Link', value: 'nofollow ugc'},
        {title: 'Follow Link', value: 'dofollow'},
        {title: 'Sponsored Link', value: 'sponsored'}
      ]
    } else {
      newSettings.plugins = ['lists', 'table', 'image', 'paste']
      relOptions = [
        {title: 'External Link', value: 'nofollow ugc'},
      ]
    }
    newSettings["rel_list"] = relOptions
    tinymce.init(newSettings)
  }

  if (formEditors) {
    let relCheck = document.getElementById('rel_check')
    let adminCheck = document.getElementById('blog_is_admin')
    let defaultPlugins = ['lists', 'table', 'image', 'paste']
    let relOptions = []
    // if in blog form
    // check admin is editing or not, if yes enable link, else no
    if (window.location.pathname.includes('/member/blogs')) {
      if (adminCheck && (adminCheck.value === 'true')) {
        defaultPlugins.push('link')
      }
    } else {
      defaultPlugins.push('link')
    }

    // free rel or not ?
    if (relCheck.value === 'freerel') {
      relOptions = [
        {title: 'External Link', value: 'nofollow ugc'},
        {title: 'Follow Link', value: 'dofollow'},
        {title: 'Sponsored Link', value: 'sponsored'}
      ]
    } else {
      relOptions = [
        {title: 'External Link', value: 'nofollow ugc'},
      ]
    }

    tinymce.init({
      selector: '.form-editor',
      plugins: defaultPlugins,
      menu: {
        edit: { title: 'แก้ไข', items: 'undo redo | cut copy paste | selectall' },
        insert: { title: 'แทรก', items: 'link inserttable charmap emoticons hr anchor' },
        format: { title: 'แบบอักษร', items: 'bold italic underline strikethrough superscript subscript formats | forecolor backcolor | removeformat' },
        table: { title: 'ตาราง', items: 'inserttable | cell row column | tableprops deletetable' },
      },
      menubar: 'edit insert format table',
      toolbar: 'undo redo | styleselect | bold italic link image | alignleft aligncenter alignright alignjustify | outdent indent numlist bullist',
      oninit : 'setPlainText',
      style_formats: [
        {
          title: 'พาดหัว 1',
          block: 'h1',
          styles: { "font-size": "28px", "line-height": "1.8" }
        },
        {
          title: 'พาดหัว 2',
          block: 'h2',
          styles: { "font-size": "20px", "line-height": "1.8" }
        },
        {
          title: 'พาดหัว 3',
          block: 'h3',
          styles: { "font-size": "18px", "line-height": "1.8" }
        },
        {
          title: 'เน้นข้อความ',
          block: 'blockquote',
          styles: { "background-color": "#F4f4f4", "padding": "24px 16px", "margin": "0px", "border-left": "3px solid #ececec" }
        },
        {
          title: 'ไฮไลท์ส้ม',
          inline: 'span',
          styles: { "color": "#FB6900" }
        },
        {
          title: 'ไฮไลท์น้ำเงิน',
          inline: 'span',
          styles: { "color": "#1D72B2" }
        },
        {
          title: 'ตัวอักษรธรรมดา',
          block: 'p',
          styles: { "color": "#000000", "font-size": "16px", "line-height": "1.8" }
        },
      ],
      theme: 'silver',
      skin: false,
      branding: false,
      language: 'th_TH',
      body_class: 'editor-class',
      content_css: '/editor.css',
      content_style: "body { font-family: 'cs_chatthaiuiregular'; font-size: 16px; line-height: 1.8 }",
      min_height: 600,
      paste_as_text: true,
      default_link_target: '_blank',
      target_list: [
        {title: 'เปิดหน้าต่างใหม่', value: '_blank'},
      ],
      rel_list: relOptions,
      images_upload_handler: (blobInfo, success, failure) => {
        let xhr, formData
        xhr = new XMLHttpRequest()
        xhr.open('POST', '/tiny-upload-images')
        // set csrf token
        let token = document.querySelector("meta[name=csrf-token]").content
        xhr.setRequestHeader('X-CSRF-Token', token)
        // init new form data 
        formData = new FormData()
        formData.append('file', blobInfo.blob(), blobInfo.filename())
        xhr.send(formData)
        // sending packet to server
        xhr.onload = () => {
          let json
          if (xhr.status != 200) {
            failure('HTTP Error: ' + xhr.status)
            return
          }
          json = JSON.parse(xhr.responseText)
          if (!json || typeof json.payload != 'string') {
            failure('Invalid JSON: ' + xhr.responseText)
            return
          }
          success(json.payload)
        }

      }
    });
  }

  if (blogSubmitButton) {
    blogSubmitButton.addEventListener('click', e => {
      let name = document.getElementById('blog_name').value
      let content = tinymce.get('blog_content').getContent()
      let categories = document.getElementById('blog_blog_category_id').value
      let form = e.currentTarget.closest('form')
      // let keywords = document.getElementById('blog_keyword').value
      // let listing = document.getElementById('blog_listing_id').value
      if (!name || !content || !categories) {
        alertMessage('กรุณากรอก ชื่อหัวเรื่อง, เนื้อหา และเลือกหมวดหมู่ให้ครบถ้วนก่อนดำเนินการต่อ')
        return false
      }
      preloaderBlock.classList.add('active')
      form.submit()
    })
  }
  
  if (facebookShareButtons) {
    facebookShareButtons.forEach(fbShareButton => {
      fbShareButton.addEventListener('click', e => {
        if (e) e.preventDefault()
        let shareURL = window.location.href
        window.open(
          `https://www.facebook.com/sharer.php?u=${shareURL}`,
          'Share on Facebook',
          'left=50,top=50,width=600,height=350,toolbar=0',
        )
        return false
      })
    })
  }

  if (lineShareButtons) {
    lineShareButtons.forEach(lineButton => {
      lineButton.addEventListener('click', e => {
        if (e) e.preventDefault()
        let shareURL = window.location.href
        window.open(
          `https://lineit.line.me/share/ui?url=${shareURL}`,
          'Share On LINE',
          'left=50,top=50,width=600,height=600,toolbar=0',
        )
        return false
      })
    })
  }

  if (entryContentBlock) {
    let tables = entryContentBlock.querySelectorAll('table')
    if (tables.length > 0) {
      tables.forEach(table => {
        // add class to table
        table.classList.add('table', 'table-bordered', 'table-striped')
        var wrapper = document.createElement('div')
        wrapper.classList.add('table-responsive')
        table.parentNode.insertBefore(wrapper, table)
        wrapper.appendChild(table)
      })
    }
  }

  if (memberSettingsButton) {
    memberSettingsButton.addEventListener('click', (e) => {
      let form = e.currentTarget.closest('form')
      // checking firstname and lastname
      let firstName = form.querySelector('#user_first_name').value
      let lastName = form.querySelector('#user_last_name').value
      if (!firstName && firstName.length < 2) {
        alertMessage('กรุณาระบุชื่อจริง หรือรูปแบบชื่อของคุณไม่ถูกต้อง')
        return false
      }
      if (!lastName && lastName.length < 2) {
        alertMessage('กรุณาระบุชื่อจริง หรือรูปแบบชื่อของคุณไม่ถูกต้อง')
        return false
      }
      // checking email
      let email = form.querySelector('#user_email').value
      if (!email && !isEmailValid(email)) {
        alertMessage('กรุณาระบุอีเมล์ หรือรูปแบบอีเมล์ที่คุณกรอกไม่ถูกต้อง')
        return false
      }
      let passwordField = form.querySelector('#user_old_password').value
      let newPassword = form.querySelector('#user_password').value
      let confirmPassword = form.querySelector('#user_password_confirmation').value
      // checking password and new password
      if ( passwordField && (!newPassword || !confirmPassword) ) {
        alertMessage('หากต้องการเปลี่ยนรหัสผ่าน กรุณาระบุรหัสผ่านใหม่ทั้งสองช่องให้ครบ')
        return false
      }
      if ( (newPassword && !confirmPassword) || (!newPassword && confirmPassword) ) {
        alertMessage('กรุณาระบุรหัสผ่านใหม่ทั้งสองช่องก่อนดำเนินการต่อ')
        return false
      }
      if ( (newPassword && confirmPassword) && ( newPassword.value !== confirmPassword.value ) ) {
        alertMessage('รหัสผ่านใหม่ที่คุณกรอกทั้งสองครั้งไม่เหมือนกัน')
        return false
      }
      preloaderBlock.classList.add('active')
      form.submit()
    })
  }

  if (imagePlaceHolders) {
    imagePlaceHolders.forEach(placeholder => {
      setImagePlaceHolderEvent(placeholder)
    })
  }

  if (datePickers) {
    datePickers.forEach(dp => {
      flatpickr(dp, {
        enableTime: false,
        dateFormat: 'd/m/Y',
        minDate: dp.classList.contains('date-start') ? "today" : null,
        locale: Thai
      })
    })
  }

  if (timePickers) {
    timePickers.forEach(tp => {
      flatpickr(tp, {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        locale: Thai,
        time_24hr: true
      })
    })
  }

  if (numericFields) {
    numericFields.forEach(nf => {
      nf.addEventListener('input', e => {
        let el = e.currentTarget
        el.value = el.value.replace(/[^0-9\.]/g, '')
      })
    })
  }

  if (blogListingId) {
    blogListingId.addEventListener('change', e => {
      if (e.currentTarget.value) {
        let selectedType = e.currentTarget.options[e.currentTarget.selectedIndex].dataset.type
        blogListingType.value = selectedType
      } else {
        blogListingType.value = ''
      }
    })
  }

  if (submitAccountantButton) {
    let consentButtonWrapper = document.querySelector('.listing-consent-wrapper')
    let consentCheckboxes = consentButtonWrapper.querySelectorAll('input[type=checkbox]')
    consentCheckboxes.forEach(cb => {
      cb.addEventListener('change', e => {
        if (consentButtonWrapper.querySelectorAll('input[type=checkbox]:checked').length == consentCheckboxes.length) {
          submitAccountantButton.removeAttribute('disabled')
        } else {
          submitAccountantButton.setAttribute('disabled', 'disabled')
        }
      })
    })
    submitAccountantButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      let form = e.currentTarget.closest('#listing_form')
      checkingAccountantListingObject(form)
    })
  }

  if (submitAuditorButton) {
    let consentButtonWrapper = document.querySelector('.listing-consent-wrapper')
    let consentCheckboxes = consentButtonWrapper.querySelectorAll('input[type=checkbox]')
    consentCheckboxes.forEach(cb => {
      cb.addEventListener('change', e => {
        if (consentButtonWrapper.querySelectorAll('input[type=checkbox]:checked').length == consentCheckboxes.length) {
          submitAuditorButton.removeAttribute('disabled')
        } else {
          submitAuditorButton.setAttribute('disabled', 'disabled')
        }
      })
    })
    submitAuditorButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      let form = e.currentTarget.closest('#listing_form')
      checkingAuditorListingObject(form)
    })
  }

  if (submitCourseButton) {
    let consentButtonWrapper = document.querySelector('.listing-consent-wrapper')
    let consentCheckboxes = consentButtonWrapper.querySelectorAll('input[type=checkbox]')
    consentCheckboxes.forEach(cb => {
      cb.addEventListener('change', e => {
        if (consentButtonWrapper.querySelectorAll('input[type=checkbox]:checked').length == consentCheckboxes.length) {
          submitCourseButton.removeAttribute('disabled')
        } else {
          submitCourseButton.setAttribute('disabled', 'disabled')
        }
      })
    })
    submitCourseButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      let form = e.currentTarget.closest('#listing_form')
      checkingCourseListingObject(form)
    })
  }

  if (submitInfrastructureButton) {
    let consentButtonWrapper = document.querySelector('.listing-consent-wrapper')
    let consentCheckboxes = consentButtonWrapper.querySelectorAll('input[type=checkbox]')
    consentCheckboxes.forEach(cb => {
      cb.addEventListener('change', e => {
        if (consentButtonWrapper.querySelectorAll('input[type=checkbox]:checked').length == consentCheckboxes.length) {
          submitInfrastructureButton.removeAttribute('disabled')
        } else {
          submitInfrastructureButton.setAttribute('disabled', 'disabled')
        }
      })
    })
    submitInfrastructureButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      let form = e.currentTarget.closest('#listing_form')
      checkingInfrastructureListingObject(form)
    })
  }

  if (submitProgramButton) {
    let consentButtonWrapper = document.querySelector('.listing-consent-wrapper')
    let consentCheckboxes = consentButtonWrapper.querySelectorAll('input[type=checkbox]')
    consentCheckboxes.forEach(cb => {
      cb.addEventListener('change', e => {
        if (consentButtonWrapper.querySelectorAll('input[type=checkbox]:checked').length == consentCheckboxes.length) {
          submitProgramButton.removeAttribute('disabled')
        } else {
          submitProgramButton.setAttribute('disabled', 'disabled')
        }
      })
    })
    submitProgramButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      let form = e.currentTarget.closest('#listing_form')
      checkingProgramListingObject(form)
    })
  }

  if (submitRegistrationButton) {
    let consentButtonWrapper = document.querySelector('.listing-consent-wrapper')
    let consentCheckboxes = consentButtonWrapper.querySelectorAll('input[type=checkbox]')
    consentCheckboxes.forEach(cb => {
      cb.addEventListener('change', e => {
        if (consentButtonWrapper.querySelectorAll('input[type=checkbox]:checked').length == consentCheckboxes.length) {
          submitRegistrationButton.removeAttribute('disabled')
        } else {
          submitRegistrationButton.setAttribute('disabled', 'disabled')
        }
      })
    })
    submitRegistrationButton.addEventListener('click', e => {
      if (e) e.preventDefault()
      let form = e.currentTarget.closest('#listing_form')
      checkingRegistrationListingObject(form)
    })
  }

  if (mobileToggleMenu) {
    let navigation = document.querySelector('.site-navigation')
    mobileToggleMenu.addEventListener('click', e => {
      if (e) e.preventDefault()
      navigation.classList.toggle('active')
    })
    mobileCloseMenu.addEventListener('click', e => {
      if (e) e.preventDefault()
      navigation.classList.toggle('active')
    })
    mobileBackdrop.addEventListener('click', e => {
      navigation.classList.remove('active')
    })
  }

  if (blogSidebarAction) {
    blogSidebarAction.addEventListener('click', e => {
      if (e) e.preventDefault()
      let sidebar = document.querySelector('.blog-sidebar-block')
      sidebar.classList.toggle('active')
    })
  }

  if (mobileSearchToggleButtons) {
    mobileSearchToggleButtons.forEach( btn => {
      btn.addEventListener('click', e => {
        if (e) e.preventDefault()
        let searchFormWrapper = document.querySelector('.result-main-search-block')
        searchFormWrapper.classList.toggle('active')
      })
    })
  }

  if (mobileFilterToggleButtons) {
    mobileFilterToggleButtons.forEach( btn => {
      btn.addEventListener('click', e => {
        if (e) e.preventDefault()
        let resultContentBlock = document.querySelector('.result-content-block')
        resultContentBlock.classList.toggle('active')
        if ( e.currentTarget.classList.contains('active') ) {
          e.currentTarget.classList.remove('active')
          e.currentTarget.innerHTML = '<i class="icon icon-settings"></i> ตัวกรองผลลัพธ์'
        } else {
          e.currentTarget.classList.add('active')
          e.currentTarget.innerHTML = '<i class="icon icon-close"></i> ปิดตัวกรอง'
        }
      })
    })
  }

  if (removeGalleryImageButtons) {
    removeGalleryImageButtons.forEach(btn => {
      btn.addEventListener('click', e => {
        if (e) e.preventDefault()
        let conf = confirm("ต้องการลบรูปภาพนี้หรือไม่? ถ้าลบแล้วจะไม่สามารถแก้ไขได้")
        if (conf) {
          let listingId = e.currentTarget.dataset.listing
          let listingType = e.currentTarget.dataset.type
          let removeURL = e.currentTarget.dataset.url
          let figure = e.currentTarget.closest('figure')
          let formGroup = e.currentTarget.closest('.form-group')
          axios.post(`/member/listings/${listingId}/remove-gallery`, {
            remove_url: removeURL, 
            listing_type: listingType
          })
          .then((response) => {
            if (response.data.status === 200) {
              let fileFields = formGroup.querySelectorAll('input[type="file"]')
              let lastFileField = fileFields[fileFields.length - 1]
              let lastId = lastFileField.getAttribute('id')
              let newId = `${lastId.slice(0, -1)}${fileFields.length}`
              let cloneField = lastFileField.cloneNode(true)
              cloneField.setAttribute('id', newId)
              // create new div wrapper
              let newEl = document.createElement('div')
              newEl.classList.add('browse-input')
              newEl.appendChild(cloneField)
              // append div wrapper to file group
              document.querySelector('.browse-input-groups').appendChild(newEl)
              figure.remove()
              succeedMessage(response.data.payload)
            }
          })
          .catch(err => alertMessage(`เกิดข้อผิดพลาดในการลบ กรุณาติดต่อเจ้าหน้าที่ ${err}`))
        }
      })
    })
  }

  if (removePortFolioImageButtons) {
    removePortFolioImageButtons.forEach(btn => {
      btn.addEventListener('click', e => {
        if (e) e.preventDefault()
        let conf = confirm("ต้องการลบรูปภาพนี้หรือไม่? ถ้าลบแล้วจะไม่สามารถแก้ไขได้")
        if (conf) {
          let listingId = e.currentTarget.dataset.listing
          let listingType = e.currentTarget.dataset.type
          let removeURL = e.currentTarget.dataset.url
          let figure = e.currentTarget.closest('figure')
          let formGroup = e.currentTarget.closest('.form-group')
          axios.post(`/member/listings/${listingId}/remove-portfolio`, {
            remove_url: removeURL, 
            listing_type: listingType
          })
          .then((response) => {
            if (response.data.status === 200) {
              let fileFields = formGroup.querySelectorAll('input[type="file"]')
              let lastFileField = fileFields[fileFields.length - 1]
              let cloneField = lastFileField.cloneNode(true)
              cloneField.setAttribute('id', `infrastructure_portfolio_gallery_${fileFields.length}`)
              // create new div wrapper
              let newEl = document.createElement('div')
              newEl.classList.add('col-lg-6')
              newEl.style.marginBottom = '16px'
              newEl.appendChild(cloneField)
              // append div wrapper to file group
              document.getElementById('portfolio_file_fields').appendChild(newEl)
              figure.remove()
              succeedMessage(response.data.payload)
            }
          })
          .catch(err => alertMessage(`เกิดข้อผิดพลาดในการลบ กรุณาติดต่อเจ้าหน้าที่ ${err}`))
        }
      })
    })
  }

  if (favouriteTabs) {
    let tabLinks = favouriteTabs.querySelectorAll('.jd-tabs-list a')
    tabLinks.forEach(link => {
      link.addEventListener('click', e => {
        if (e) e.preventDefault()
        let listingType = e.currentTarget.dataset.type
        let currentUrl = window.location.href
        let replacestring = '?tabs='
        if ( currentUrl.includes(replacestring) ) {
          let cutPos = currentUrl.indexOf("?tabs=")
          currentUrl = `${currentUrl.slice(0, cutPos)}?tabs=${listingType}`
        } else {
          currentUrl += `?tabs=${listingType}`
        }
        preloaderBlock.classList.add('active')
        window.location.href = currentUrl
      })
    })
  }

  if (sidebarFilterForm) {
    sidebarFilterButton.addEventListener('click', e => {
      preloaderBlock.classList.add('active')
      let province = document.getElementById('main_search_scope_area')
      let district = document.getElementById('main_search_scope_district')
      let courseName = document.getElementById('main_search_course_name')
      document.getElementById('filter_province').value = province.value
      document.getElementById('filter_district').value = district.value
      if (courseName) document.getElementById('filter_course_name').value = courseName.value
      sidebarFilterForm.submit()
    })
  }

  if (timeInputs) {
    timeInputs.forEach(input => {
      let timeMaskFormat = new Inputmask({
        mask: '99:99',
        showMaskOnHover: false,
        showMaskOnFocus: false,
        skipOptionalPartCharacter: " ",
        clearMaskOnLostFocus: true,
        removeMaskOnSubmit: true
      })
      timeMaskFormat.mask(input)
    })
  }

  if (listingContactButton) {
    listingContactButton.addEventListener('click', e => {
      let message = document.getElementById('listing_contact_message')
      let sender = document.getElementById('listing_contact_sender')
      let contactInfo = document.getElementById('listing_contact_info')
      let senderId = document.getElementById('listing_sender_id')
      let receiverId = document.getElementById('author_id')
      let listingTitle = document.querySelector('.listing-title').innerHTML
      if ( !message.value || !sender.value || !contactInfo.value ) {
        alertMessage('กรุณากรอกข้อมูลการติดต่อให้ครบถ้วน')
        return false
      }
      if ( (message.value).length < 10 ) {
        alertMessage('ข้อความของคุณสั้นเกินไป กรุณาระบุให้ชัดเจน')
        return false
      }
      preloaderBlock.classList.add('active')
      // build message
      let contactMessage = `
        ${message.value}
        ชื่อ: ${sender.value} ติดต่อ ${contactInfo.value}
        --
        ประกาศที่อ้างถึง: ${listingTitle}
        ลิงค์: ${location.href}
      `
      axios.post('/contact/send-message', { 
        message: contactMessage.trim(),
        sender_id: senderId.value,
        receiver_id: receiverId.value
      })
      .then((response) => {
        preloaderBlock.classList.remove('active')
        message.value = ''
        sender.value = ''
        contactInfo.value = ''
        succeedMessage(response.data.payload)
      })
      .catch(err => {
        preloaderBlock.classList.remove('active')
        alertMessage('เกิดข้อผิดพลาดในการส่งข้อมูล กรุณาลองใหม่​ : ', err)
        return false
      })
    })
  }

  if (chatMessageButton) {
    let scrollerWrapper = document.querySelector('.chat-body-scroller')
    let message = document.getElementById('chat_message_input')
    let roomId = document.getElementById('room_id')
    let senderId = document.getElementById('sender_id')
    let receiverId = document.getElementById('receiver_id')
    
    if (scrollerWrapper) scrollerWrapper.scrollTop = scrollerWrapper.scrollHeight
    // getting new message every 5 seconds
    setInterval(() => {
      axios.post('/contact/get-messages', {
        room_id: roomId.value
      })
      .then(response => {
        if (response.data.status === 200) {
          if (response.data.payload.length == 0) return false
          let chatScreen = document.querySelector('.chat-history')
          let inScreenbubbles = chatScreen.querySelectorAll('li')
          // if server got new messages (length not eq)
          // get diff messages and appended to chat body
          if (response.data.payload.length > inScreenbubbles.length) {
            let newMessages = response.data.payload.slice(inScreenbubbles.length)
            newMessages.forEach(newMessage => {
              let formattedtime = format(parseISO(newMessage.created_at), 'dd/MM/yyyy HH:mm')
              let newBubbleHTML = `
              <li class="receiver">
                <div class="_message">
                  <span>${newMessage.body}</span>
                  <small>${formattedtime}</small>
                </div>
              </li>
              `
              chatScreen.insertAdjacentHTML('beforeend', newBubbleHTML)
              // update latest message time
              let lastMessages = document.querySelectorAll(`.last-messages-${roomId.value}`)
              lastMessages.forEach(text => text.innerHTML = formattedtime)
              if (scrollerWrapper) scrollerWrapper.scrollTop = scrollerWrapper.scrollHeight
            })
          }
        }
      })
      .catch(err => console.log('fetch new message error: ', err))
    }, 5000)

    // add submit message event
    chatMessageButton.addEventListener('click', e => {
      if ( !message.value ) {
        alertMessage('กรุณากระบุข้อความ')
        return false
      }
      axios.post('/contact/send-message', { 
        message: message.value.trim(),
        sender_id: senderId.value,
        receiver_id: receiverId.value,
        room_id: roomId.value
      })
      .then(response => {
        if (response.data.status === 200) {
          let chatScreen = document.querySelector('.chat-history')
          let newBubbleHTML = `
          <li class="sender">
            <div class="_message">
              <span>${message.value.trim()}</span>
              <small>${format(new Date(), 'dd/MM/yyyy HH:mm')}</small>
            </div>
          </li>
          `
          chatScreen.insertAdjacentHTML('beforeend', newBubbleHTML)
          scrollerWrapper.scrollTop = scrollerWrapper.scrollHeight
          message.value = ''
        } else {
          alertMessage('เกิดข้อผิดพลาดในการส่งข้อมูล กรุณาลองใหม่​')
        }
      })
      .catch(err => alertMessage('เกิดข้อผิดพลาดในการส่งข้อมูล', err))
    })

    // add enter event to input
    message.addEventListener('keypress', e => {
      if (e.key === 'Enter') chatMessageButton.click()
    })
  }

  if (accountantEducationWrapper) {
    let sequenceItems = accountantEducationWrapper.querySelectorAll(':scope > .sequence-item')
    if (sequenceItems.length === 1) {
      setCheckboxStateForDiploma('#accountant_education_degree', sequenceItems)
    }
  }

  if (courseTypeSwitcher) {
    let eLearningItems = document.querySelectorAll('.course-elearning-items')
    let onlineItems = document.querySelectorAll('.course-online-items')
    let offlineItems = document.querySelectorAll('.course-offline-items')
    courseTypeSwitcher.addEventListener('change', e => {
      if (e.currentTarget.value === 'e-learning') {
        onlineItems.forEach(item => item.style.display = 'none')
        offlineItems.forEach(item => item.style.display = 'none')
        eLearningItems.forEach(item => item.style.display = 'block')
      } else if (e.currentTarget.value === 'live') {
        eLearningItems.forEach(item => item.style.display = 'none')
        offlineItems.forEach(item => item.style.display = 'none')
        onlineItems.forEach(item => item.style.display = 'block')
      } else {
        eLearningItems.forEach(item => item.style.display = 'none')
        onlineItems.forEach(item => item.style.display = 'none')
        offlineItems.forEach(item => item.style.display = 'block')
      }
    })
    // trigger select change at first time loaded
    triggerCustomEventChange(courseTypeSwitcher)
  }

  // if (auditorEducationWrapper) {
  //   let sequenceItems = auditorEducationWrapper.querySelectorAll(':scope > .sequence-item')
  //   if (sequenceItems.length === 1) {
  //     setCheckboxStateForDiploma('#auditor_education_degree', sequenceItems)
  //   }
  // }

  if (howList) {
    let navLinks = howList.querySelectorAll('a')
    let allContentBlocks = document.querySelectorAll('.how-content-block')
    navLinks.forEach(link => {
      link.addEventListener('click', e => {
        if (e) e.preventDefault()
        let willActiveId = e.currentTarget.dataset.content
        let willActiveBlock = document.getElementById(willActiveId)
        // remove all active tab link
        navLinks.forEach(l => l.closest('li').classList.remove('active'))
        e.currentTarget.closest('li').classList.add('active')
        // remove all active tab content
        allContentBlocks.forEach(block => block.classList.remove('active'))
        willActiveBlock.classList.add('active')
      })
    })
  }

  if (upgradeQuantitySelect) {
    let listingId = document.getElementById('upgrade_listing_id')
    let listingType = document.getElementById('upgrade_listing_type')
    let startDate = document.getElementById('upgrade_start_date')
    let endDate = document.getElementById('upgrade_end_date')
    let whtCheckbox = document.getElementById('withholding_tax')
    let currentDate = new Date()
    let discountPrice = document.getElementById('upgrade_system_discount')
    let discountBlock = document.getElementById('upgrade_system_discount_block')
    let promotionBlock = document.getElementById('upgrade_promotion_discount_block')
    let promotionAppliedCode = document.getElementById('upgrade_promotion_applied_code')
    let promotionPrice = document.getElementById('upgrade_promotion_discount')
    let monthlyPlanPrice = document.getElementById('monthly_plan_price')
    let planPrice = document.getElementById('upgrade_plan_price')
    let subTotalPrice = document.getElementById('upgrade_sub_total')
    let totalPrice = document.getElementById('upgrade_total')
    let promotionCode = document.getElementById('upgrade_promotion_code')
    let applyCodeButton = document.getElementById('apply_code_button')
    let whtPrice = document.getElementById('upgrade_wht')
    let whtRate = 0.02

    // format date
    let formattedStartDate = parse(
      startDate.innerHTML, 
      "dd/MM/yyyy", 
      new Date(currentDate.getFullYear() + 543,0,1), { locale: th }
    )
    let formattedEndDate;

    let calculateEndDate = () => {
      formattedEndDate = sub(add(formattedStartDate, { months: upgradeQuantitySelect.value }), {days: 1})
      endDate.innerHTML = `ถึง ${format(formattedEndDate, 'dd/MM/yyyy')}`
    }

    let calculatePrice = () => {
      // calculate price
      let totalPlanPrice = upgradeQuantitySelect.value * monthlyPlanPrice.value
      // setting value text
      planPrice.innerHTML = BAHT(Math.round(totalPlanPrice)).format()
      subTotalPrice.innerHTML = BAHT( 
          Math.round(totalPlanPrice) 
          - Math.ceil(currency(discountPrice.innerHTML).value)
          - Math.ceil(currency(promotionPrice.innerHTML).value) 
        )
        .format()
      totalPrice.innerHTML = BAHT( 
        currency(subTotalPrice.innerHTML).subtract(currency(whtPrice.innerHTML).value) 
      ).format()
    }

    // init end date when page loaded;
    calculateEndDate()
    calculatePrice()

    upgradeQuantitySelect.addEventListener('change', e => {
      // trigger wht change
      let whtCheckbox = document.getElementById('withholding_tax')
      let evt = document.createEvent('HTMLEvents')
      whtCheckbox.checked = false
      evt.initEvent('change', false, true)
      whtCheckbox.dispatchEvent(evt)
      axios
        .post(`/member/listings/${listingId.value}/dc`, { listing_type: listingType.value, quantity: upgradeQuantitySelect.value })
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data.payload != '') {
              let systemDCRate = response.data.payload / 100
              let totalPlanPrice = upgradeQuantitySelect.value * monthlyPlanPrice.value
              discountPrice.innerHTML = BAHT( Math.ceil(totalPlanPrice * systemDCRate) ).format()
              discountBlock.style.display = 'flex'
            } else {
              discountPrice.innerHTML = '0.00'
              discountBlock.style.display = 'none'
            }
          }
          calculateEndDate()
          calculatePrice()
        })
        .catch(err => console.log(err))
      
    })

    applyCodeButton.addEventListener('click', e => {
      if (!promotionCode.value) {
        alertMessage('กรุณาระบุรหัสคูปองส่วนลดก่อน', promotionCode)
        return false
      }
      axios
        .post(`/member/listings/${listingId.value}/apply-code`, { code: promotionCode.value })
        .then(response => {
          if (response.data.status === 200)  {
            if (response.data.payload != '') {
              promotionAppliedCode.innerHTML = `(${promotionCode.value})`
              promotionPrice.innerHTML = BAHT(response.data.payload).format()
              promotionBlock.style.display = 'flex'
            } else {
              promotionCode.value = ''
              promotionAppliedCode.innerHTML = ''
              promotionPrice.innerHTML = '0.00'
              promotionBlock.style.display = 'none'
              alertMessage('รหัสคูปองส่วนลดไม่ถูกต้อง หรือหมดอายุไปแล้ว 😕', promotionCode)
            }
          }
          calculatePrice()
        })
        .catch(err => console.log(err))

    })

    whtCheckbox.addEventListener('change', e => {
      if (e.currentTarget.checked) {
        let whtDiscount = currency(subTotalPrice.innerHTML).multiply(whtRate)
        whtPrice.innerHTML = whtDiscount
      } else {
        whtPrice.innerHTML = '0.00'
      }
      calculatePrice()
    })

    whTaxOption.addEventListener('change', e => {
      let priceGroup = e.currentTarget.closest('.summary-price')
      let whtPrice = priceGroup.querySelector('._wht')
      if (e.target.checked) {
        whtPrice.classList.add('active')
        issueTypes.forEach( rb => rb.checked = false )
      } else {
        whtPrice.classList.remove('active')
      }
    })

    issueTypes.forEach(rb => {
      rb.addEventListener('change', e => {
        if (e.currentTarget.checked && e.currentTarget.getAttribute('id') == 'individual') {
          whtCheckbox.checked = false
          // trigger checkbox change
          if ('createEvent' in document) {
            var evt = document.createEvent('HTMLEvents')
              evt.initEvent('change', false, true)
              whtCheckbox.dispatchEvent(evt)
            } else {
              whtCheckbox.fireEvent('onchange')
            }
        }
      })
    })

    

    goPlaceOrderButton.addEventListener('click', e => {
      let isValid = validatePayerInfo();
      if (isValid) {
        Swal.fire({
          title: 'คุณกำลังส่งคำสั่งซื้อ',
          text: 'กรุณาตรวจสอบข้อมูลให้เรียบร้อยก่อน หากแน่ใจแล้วให้กดปุ่มยืนยันการสั่งซื้อเพื่อเริ่มรายการ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "ยืนยันการสั่งซื้อ",
          cancelButtonText: "ยกเลิก",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showClass: {
            popup: 'flip-in-hor-bottom',
            backdrop: 'swal2-noanimation'
          },
          hideClass: {
            popup: 'flip-out-hor-top',
            backdrop: 'swal2-noanimation'
          },
        }).then( (result) => {
          let upgradeForm = document.getElementById('order_summary_form')
          if (result.isConfirmed) {
            preloaderBlock.classList.add('active')
            upgradeForm.submit()
          }
        })
      }
    })

  }

  if (contactForm) {
    let name = document.getElementById('contact_name')
    let email = document.getElementById('contact_email')
    let number = document.getElementById('contact_number')
    let company = document.getElementById('contact_company')
    let message = document.getElementById('contact_message')
    let button = document.getElementById('contact_button')
    button.addEventListener('click', (e) => {
      if (e) e.preventDefault()
      if (!name.value || !email.value) {
        alertMessage('กรุณากรอกข้อมูลการติดต่อให้ครบ')
        return false
      }
      if ( !isEmailValid(email.value) ) {
        alertMessage('รูปแบบอีเมล์ที่กรอกไม่ถูกต้อง')
        return false
      }
      preloaderBlock.classList.add('active')
      axios.post('/contact/contact-form', {
        name: name.value,
        email: email.value,
        number: number.value,
        company: company.value,
        message: message.value
      })
      .then( response => {
        preloaderBlock.classList.remove('active')
        if (response.data.status == 200) {
          succeedMessage(response.data.payload)
          name.value = ''
          email.value = ''
          number.value = ''
          company.value = ''
          message.value = ''
        } else {
          alertMessage(response.data.payload)
        }
      })
      .catch( err => alertMessage(err) )
    })
  }

  if (confirmPaymentForm) {
    let orderNumber = document.getElementById('order_number')
    let amount = document.getElementById('amount')
    let date = document.getElementById('date')
    let time = document.getElementById('time')
    let name = document.getElementById('customer_name')
    let number = document.getElementById('customer_number')
    let slip = document.getElementById('slip')
    let button = document.getElementById('confirm_payment_button')
    button.addEventListener('click', e => {
      if (!orderNumber.value || !amount.value || !date.value || !time.value || !name.value || !number.value) {
        alertMessage('กรุณากรอกข้อมูลให้ครบทุกช่อง')
        return false
      }
      if (slip.files.length <= 0) {
        alertMessage('กรุณาแนบหลักฐานการโอนเงิน')
        return false
      }
      preloaderBlock.classList.add('active')
      let fd = new FormData()
      if ( document.getElementById('slip').files.length > 0 ) {
        fd.append('file', document.getElementById('slip').files[0])
      }
      fd.append('data', JSON.stringify(serialize(confirmPaymentForm, {hash: true, disabled: true})))
      axios({
        method: 'post',
        url: '/contact/confirm-form',
        data: fd,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then( response => {
        orderNumber.value = ''
        amount.value = ''
        date.value = ''
        time.value = ''
        name.value = ''
        number.value = ''
        slip.value = ''
        preloaderBlock.classList.remove('active')
        succeedMessage(response.data.payload)
      })
      .catch( err => alertMessage(err) )
    })
  }

  if (provinceComboSelect) {
    let hiddenField = document.getElementById('province_value')
    let districtHiddenField = document.getElementById('district_value')
    let scopeDistrictSelect = document.querySelector('.district-combo-select')
    provinceComboSelect.addEventListener('change', (e) => {
      hiddenField.value = e.currentTarget.options[e.currentTarget.selectedIndex].text
      let provinceId = e.currentTarget.value
      if (provinceId) {
        axios.post('/search-district-by-province', { province_id: provinceId })
        .then((response) => {
          if (response.status === 200) {
            scopeDistrictSelect.length = 0
            let districtOptions = response.data.payload
            // add blank option
            let option = `<option value=""></option>`
            option = document.createElement( 'option' );
            option.value = '';
            option.text = '';
            scopeDistrictSelect.add( option );
            // add dynamic option
            for (let i = 0; i < districtOptions.length; i++) {
              let option = `<option value="${districtOptions[i]}">${districtOptions[i]}</option>`
              option = document.createElement( 'option' );
              option.value = districtOptions[i];
              option.text = districtOptions[i];
              scopeDistrictSelect.add( option );
            }
          }
        })
        .catch(err => console.log(err))
      }
    })
    scopeDistrictSelect.addEventListener('change', (e) => {
      districtHiddenField.value = e.currentTarget.options[e.currentTarget.selectedIndex].text
    })
    if (hiddenField.value != null) {
      let districtValue = document.getElementById('district_value').value
      let evt = document.createEvent('HTMLEvents')
      evt.initEvent('change', false, true)
      provinceComboSelect.dispatchEvent(evt)
      setTimeout(() => {
        scopeDistrictSelect.value = districtValue
      }, 600)
    }
  }
  if (listingAuditorType) {
    let restrictOptionsForTA = document.querySelectorAll('.diploma-restricted')
    listingAuditorType.addEventListener('change', e => {
      let value =  e.currentTarget.value
      if (value === 'cpa') {
        // allow all options
        restrictOptionsForTA.forEach(cb => cb.removeAttribute('disabled'))
      } else {
        // restrict some option
        restrictOptionsForTA.forEach(cb => cb.setAttribute('disabled', 'disabled'))
      }
    })
    triggerCustomEventChange(listingAuditorType)
  }

  if (toggleCheckboxes && toggleCheckboxes.length > 0) {
    toggleCheckboxes.forEach(cb => {
      cb.addEventListener('change', e => {
        let parent = e.currentTarget.closest('.toggle-checkbox-wrapper')
        let checked = parent.querySelectorAll('input[type=checkbox]:checked')
        checked.forEach(checkedCB => {
          if (checkedCB != e.currentTarget) checkedCB.checked = false
        })
      })
    })
  }

  if (accessDashboardLink) {
    accessDashboardLink.addEventListener('click', e => {
      preloaderBlock.classList.add('active')
    })
  }

  if (moreAuthorBlogs) {
    moreAuthorBlogs.addEventListener('click', e => {
      let authorURL = `/blogs/author/${e.currentTarget.dataset.author}`
      window.location.href = authorURL
    })
  }

  if (moreCardItems) {
    moreCardItems.forEach(card => {
      card.addEventListener('click', e => {
        let moreLink = e.currentTarget.dataset.link
        window.location.href = moreLink
      })
    })
  }

  if (copyRegistrationInfoButton) {
    copyRegistrationInfoButton.addEventListener('click', async (e) => {
      // block screen
      preloaderBlock.classList.add('active')
      // get last listing info
      let form = document.getElementById('listing_form')
      let response = await axios.get('/copy-registration-info')
      if (response.data.status == 200) {
        let listing = JSON.parse(response.data.payload)
        // console.log(listing)
        // assign data to registration form
        assignDataToRegistrationForm(listing, form)
        // hide block
        let copyWrapper = copyRegistrationInfoButton.parentElement;
        copyWrapper.remove()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'การแจ้งเตือน',
          showClass: {
            popup: 'flip-in-hor-bottom',
            backdrop: 'swal2-noanimation'
          },
          hideClass: {
            popup: 'flip-out-hor-top',
            backdrop: 'swal2-noanimation'
          },
          text: response.data.payload,
        })
      }
      preloaderBlock.classList.remove('active')
    })
  }

}

// GLOBAL CONFIGURATIONS

window.validatePayerInfo = validatePayerInfo
window.initAllPagesScripts = initAllPagesScripts

document.addEventListener('turbolinks:before-visit', (e) => {
  let preloaderBlock = document.querySelector('.preloader-block')
  preloaderBlock.classList.add('active')
})

document.addEventListener("turbolinks:load", function() {
  let preloaderBlock = document.querySelector('.preloader-block')
  preloaderBlock.classList.remove('active')
  initAllPagesScripts()
})
