// prettier-ignore	

import { 
  checkFieldIsEmpty, 
  noCheckboxChecked, 
  noRadioboxChecked, 
  alertMessage, 
} from './utils'

const resetErrorFieldsForm = (form) => {
  let preloaderBlock = document.querySelector('.preloader-block')
  let fields = form.querySelectorAll('.form-control')
  fields.forEach( field => field.classList.remove('has-error') )
  return preloaderBlock
}

const businessOptionsCheck = (form) => {
  let errorFlag = false
  if ( noCheckboxChecked( form.querySelector('.location-choice-wrapper'), "คุณต้องเลือกพื้นที่ให้บริการอย่างน้อย 1 พื้นที่" ) ) errorFlag = true
  if ( noCheckboxChecked( form.querySelector('.langs-choice-wrapper'), "คุณต้องเลือกภาษาที่ให้บริการอย่างน้อย 1 ภาษา" ) ) errorFlag = true
  if ( noCheckboxChecked( form.querySelector('.vat-choice-wrapper'), "คุณต้องเลือกประเภท VAT ที่ให้บริการอย่างน้อย 1 ตัวเลือก" ) ) errorFlag = true
  if ( noCheckboxChecked( form.querySelector('.business-types-choice-wrapper'), "คุณต้องเลือกประเภทนิติบุคคลที่ต้องการให้บริการอย่างน้อย 1 ตัวเลือก" ) ) errorFlag = true
  if ( noCheckboxChecked( form.querySelector('.business-categories-choice-wrapper'), "คุณต้องเลือกประเภทธุรกิจที่ให้บริการอย่างน้อย 1 ตัวเลือก" ) ) errorFlag = true
  if ( noCheckboxChecked( form.querySelector('.revenues-choice-wrapper'), "คุณต้องเลือกขนาดบริษัท : รายได้รวมอย่างน้อย 1 ตัวเลือก" ) ) errorFlag = true
  if ( noCheckboxChecked( form.querySelector('.assets-choice-wrapper'), "คุณต้องเลือกขนาดบริษัท : สินทรัพย์รวมอย่างน้อย 1 ตัวเลือก" ) ) errorFlag = true
  if ( noCheckboxChecked( form.querySelector('.capitals-choice-wrapper'), "คุณต้องเลือกขนาดบริษัท : ทุนจดทะเบียนที่ชำระแล้วอย่างน้อย 1 ตัวเลือก" ) ) errorFlag = true
  return errorFlag
}

const serializeLocationData = (form) => {
  let areaSerialized = []
  let locationWrapper = form.querySelector('.location-choice-wrapper')
  let formGroups = locationWrapper.querySelectorAll('.form-group')
  formGroups.forEach(group => {
    let region = group.querySelector('.region-group')
    let cbChecked = group.querySelectorAll('.jd-checkbox-horizontal input[type=checkbox]:checked')
    let availableAreas = []
    if (cbChecked.length > 0 && region.dataset.name) {
      let key = region.dataset.name
      cbChecked.forEach( cb => availableAreas.push(cb.value) )
      areaSerialized.push( { [key]: availableAreas } )
    }
  })
  return areaSerialized
}

const serializeEducationData = (model, form) => {
  let educationsField = form.querySelector(`#${model}_educations`)
  let educationsWrapper = form.querySelector(`#${model}_education`)
  let educations = []
  let educationItems = educationsWrapper.querySelectorAll('.sequence-item')
  if (educationItems.length > 0) {
    educationItems.forEach(item => {
      let name = item.querySelector('.education-name')
      let degree = item.querySelector('.education-degree')
      let faculty = item.querySelector('.education-faculty')
      let year = item.querySelector('.education-year')
      if (degree && faculty && year) {
        educations.push({
          'name': name.value,
          'degree': degree.value,
          'faculty': faculty.value,
          'year': year.value,
        })
      }
    })
    educationsField.value = JSON.stringify(educations)
  }
}

const serializeExperienceData = (model, form) => {
  let experiencesField = form.querySelector(`#${model}_experiences`)
  let experiencesWrapper = form.querySelector(`#${model}_experience`)
  let experiences = []
  let experienceItems = experiencesWrapper.querySelectorAll('.sequence-item')
  if (experienceItems.length > 0) {
    experienceItems.forEach(item => {
      let company = item.querySelector('.experience-company')
      let role = item.querySelector('.experience-role')
      let type = item.querySelector('.experience-type')
      let start = item.querySelector('.experience-year-start')
      let end = item.querySelector('.experience-year-end')
      if (model === 'registration') {
        if (company && role && start && end) {
          experiences.push({
            'company' : company.value,
            'role': role.value,
            'start': start.value,
            'end': end.value
          })
        }  
      } else {
        if (company && role && type && start && end) {
          experiences.push({
            'company' : company.value,
            'role': role.value,
            'type': type.value,
            'start': start.value,
            'end': end.value
          })
        }
      }
    })
    experiencesField.value = JSON.stringify(experiences)
  }
}

const serializePackageData = (model, form) => {
  let packagesField = form.querySelector(`#${model}_packages`)
  let packagesWrapper = form.querySelector(`#${model}_package`)
  let packages = []
  let packageItems = packagesWrapper.querySelectorAll('.sequence-item')
  let validFlag = true
  if (packageItems.length > 0) {
    packageItems = Array.from(packageItems) //convert dom nodelist to array, so it can use some function
    packageItems.some(item => {
      let itemIndex = item.dataset.number
      let name = item.querySelector('.package-name')
      let price = item.querySelector('.package-price')
      let details = tinymce.get(`package_details_sequence_${itemIndex}`)
      if (name && price && details) {
        if (name.value && price.value && details.getContent() != '' ) {
          packages.push({
            'name' : name.value,
            'price': price.value,
            'details': details ? details.getContent() : ''
          })
        } else {
          alertMessage('กรุณาระบุข้อมูลแพ็คเกจในช่องที่กำหนด', name)
          validFlag = false
          return false
        }
      }
    })
    if (validFlag) packagesField.value = JSON.stringify(packages)
    return validFlag
  }
}

const serializeProgramPackageData = (model, form) => {
  let packagesField = form.querySelector(`#${model}_packages`)
  let packagesWrapper = form.querySelector(`#${model}_package`)
  let packages = []
  let packageItems = packagesWrapper.querySelectorAll('.sequence-item')
  if (packageItems.length > 0) {
    packageItems.forEach(item => {
      let itemIndex = item.dataset.number
      let name = item.querySelector('.program-package-name')
      let price = item.querySelector('.program-package-price')
      let details = tinymce.get(`package_details_${itemIndex}`)
      if (name && price && details) {
        packages.push({
          'name': name.value,
          'price': price.value,
          'details': details ? details.getContent() : ''
        })
      }
    })
    packagesField.value = JSON.stringify(packages)
  }
}

const serializeLecturerData = (model, form) => {
  let lecturersField = form.querySelector(`#${model}_lecturers`)
  let lecturersWrapper = form.querySelector(`#${model}_lecturer`)
  let lecturers = []
  let lecturerItems = lecturersWrapper.querySelectorAll('.sequence-item')
  if (lecturerItems.length > 0) {
    lecturerItems.forEach(item => {
      let name = item.querySelector('.lecturer-name')
      let title = item.querySelector('.lecturer-title')
      if (name && title) {
        lecturers.push({
          'name': name.value,
          'title': title.value,
        })
      }
    })
    lecturersField.value = JSON.stringify(lecturers)
  }
}

const serializeDateData = (model, form) => {
  let datesField = form.querySelector(`#${model}_dates`)
  let datesWrapper = form.querySelector(`#${model}_date`)
  let dates = []
  let dateItems = datesWrapper.querySelectorAll('.sequence-item')
  if (dateItems.length > 0) {
    dateItems.forEach(item => {
      let date = item.querySelector('.course-date')
      let start = item.querySelector('.course-start')
      let end = item.querySelector('.course-end')
      if (date && start && end) {
        dates.push({
          'date': date.value,
          'start': start.value,
          'end': end.value,
        })
      }
    })
    datesField.value = JSON.stringify(dates)
  }
}

const serializeAdvisorData = (model, form) => {
  let advisorsField = form.querySelector(`#${model}_advisors`)
  let advisorWrapper = form.querySelector(`#${model}_list`)
  let advisors = []
  let advisorItems = advisorWrapper.querySelectorAll(':scope > .sequence-item')

  if (advisorItems.length > 0) {
    advisorItems.forEach(item => {
      let advisorExperiences = []
      let advisorEducations = []
      let advisorCertificates = []
      // start new object
      let itemNumber = item.dataset.number
      let advisorObject = {}
      // serialize experience
      let experienceWrapper = item.querySelector(`#${model}_experience`)
      let experienceItems = experienceWrapper.querySelectorAll(':scope > .sequence-item')
      experienceItems.forEach(expItem => {
        let project = expItem.querySelector('.exp-project')
        let company = expItem.querySelector('.exp-company')
        let start = expItem.querySelector('.exp-project-start')
        let finish = expItem.querySelector('.exp-project-finish')
        if (project && company && start && finish) {
          advisorExperiences.push({
            'project': project.value,
            'company': company.value,
            'start': start.value,
            'finish': finish.value,
          })
        }
      })
      
      // serialize educations
      let educationWrapper = item.querySelector(`#${model}_education`)
      let educationItems = educationWrapper.querySelectorAll(':scope > .sequence-item')
      try {
        educationItems.forEach(eduItem => {
          let institution = eduItem.querySelector('.institution')
          let educationLevel = eduItem.querySelector('.education-level')
          let faculty = eduItem.querySelector('.faculty')
          let graduateYear = eduItem.querySelector('.graduate-year')
          if (institution.value === '') {
            alertMessage('กรุณาระบุสถาบันการศึกษา', institution)
            throw BreakException
          }
          if (educationLevel.value === '') {
            alertMessage('กรุณาระบุระดับการศึกษา', educationLevel)
            throw BreakException
          }
          if (faculty.value === '') {
            alertMessage('กรุณาระบุสาขา', educationLevel)
            throw BreakException
          }
          if (graduateYear.value === '') {
            alertMessage('กรุณาระบุปีพ.ศ.ที่จบ', educationLevel)
            throw BreakException
          }
          if (institution && educationLevel && faculty && graduateYear) {
            advisorEducations.push({
              'institution': institution.value,
              'education_level': educationLevel.value,
              'faculty': faculty.value,
              'graduate_year': graduateYear.value,
            })
          }
        })
      } catch( e ) {
        if ( e !== BreakException) return false
      }
      
      // serialize certificates
      let certificateWrapper = item.querySelector(`#${model}_certificate`)
      let certificateItems = certificateWrapper.querySelectorAll(':scope > .sequence-item')
      certificateItems.forEach(certItem => {
        let name = certItem.querySelector('.cert-name')
        let year = certItem.querySelector('.cert-year')
        if (name && year) {
          advisorCertificates.push({
            'name': name.value,
            'year': year.value
          })
        }
      })

      // assign each array value to object
      advisorObject['prefix'] = item.querySelector('.prefix').value
      advisorObject['first_name'] = item.querySelector('.first-name').value
      advisorObject['last_name'] = item.querySelector('.last-name').value
      advisorObject['gender'] = item.querySelector('.gender').value
      advisorObject['specialty'] = tinymce.get(`advisor_specialty_${itemNumber}`).getContent()
      advisorObject['experiences'] = advisorExperiences
      advisorObject['educations'] = advisorEducations
      advisorObject['certificates'] = advisorCertificates
      advisors.push(advisorObject)
    })
    advisorsField.value = JSON.stringify(advisors)
  }
  
}

const serializeFeatureData = (model, form) => {
  let featuresField = form.querySelector(`#${model}_features`)
  let featuresWrapper = form.querySelector(`#${model}_feature`)
  let features = []
  let featureItems = featuresWrapper.querySelectorAll('.sequence-item')
  if (featureItems.length > 0) {
    featureItems.forEach(item => {
      let name = item.querySelector('.feature-name')
      let description = item.querySelector('.feature-description')
      if (name && description) {
        features.push({
          'name': name.value,
          'description': description.value
        })
      }
    })
    featuresField.value = JSON.stringify(features)
  }
}

export const assignDataToRegistrationForm = (listing, form) => {
  let event = new Event('change')
  for (const [key, value] of Object.entries(listing)) {
    let input = document.querySelector(`[name='registration[${key}]'`);
    
    // normal and hidden input
    if (key == 'sub_type' || key == 'post_type' || key == 'title') {
      // do nothing
    } else if (input && input.tagName == 'SELECT') {
      // all selector [except province and district]
      input.value = value
      input.dispatchEvent(event)
    } else if (input && input.type != 'file') {
      input.value = value
    } else if (input && input.type == 'file') {
      if (key !== 'cover') {
        if (value && value.hasOwnProperty('url') && value.url != null) {
          let figure = input.parentNode
          let preview = figure.querySelector('.preview')
          // flag to check clone was cloned from previous document.
          let cloneChecker = figure.querySelector('.clone-image-checker')
          console.log(cloneChecker)
          cloneChecker.value = 'clone'
          // let removeButton = figure.querySelector('._reset')
          let mockupText = figure.querySelector('.mockup')
          if (preview) {
            preview.src = value.url
            preview.classList.add('exist')
          }
          if (mockupText) mockupText.classList.add('hide')
          // create remove button
          let removeButton = document.createElement('a')
          removeButton.dataset['id'] = listing.id
          removeButton.dataset['type'] = 'Registration'
          removeButton.dataset['imageType'] = key
          removeButton.classList.add('_remove')
          removeButton.href = '#'
          removeButton.innerHTML = 'ลบรูปภาพ'
          figure.insertBefore(removeButton, input)
          // set the event
          removeButton.addEventListener('click', e => {
            if (e) e.preventDefault()
            preview.setAttribute('src', '/img/placeholder800.jpg')
            input.value = ''
            if (mockupText) mockupText.classList.remove('hide')
            if (removeButton) removeButton.classList.add('hide')
            if (cloneChecker) cloneChecker.value = ''
          })
        }
      }
    }
    // mapping json educations, experiences
    if (key === 'educations') {
      let educations = JSON.parse(value)
      let educationList = document.getElementById('registration_education')
      educations.map( (education, i) => {
        let index = i + 1
        let item = null
        if (i == 0) {
          // 1 is always exists
          item = educationList.querySelector(`.sequence-item[data-number="1"]`)
        } else {
          let existItem = educationList.querySelector(`.sequence-item[data-number="1"]`)
          item = existItem.cloneNode(true)
          item.dataset.number = index
          educationList.append(item)
          // remove disabled on action button
          let action = item.querySelector('._action')
          if (action) action.classList.remove('disabled')
          // add remove event
          action.addEventListener('click', e => {
            if (e) e.preventDefault()
            item.remove()
          })
        }
        if (item) {
          let name = item.querySelector('.education-name')
          let degree = item.querySelector('.education-degree')
          let faculty = item.querySelector('.education-faculty')
          let year = item.querySelector('.education-year')
          if (name) name.value = education.name
          if (degree) degree.value = education.degree
          if (faculty) faculty.value = education.faculty
          if (year) year.value = education.year
        }
      })
    }
    if (key === 'experiences') {
      let experiences = JSON.parse(value)
      let experienceList = document.getElementById('registration_experience')
      experiences.map( (exp,i) => {
        let index = i + 1
        let item = null
        if (i == 0) {
          item = experienceList.querySelector(`.sequence-item[data-number="1"]`)
        } else {
          let existItem = experienceList.querySelector(`.sequence-item[data-number="1"]`)
          item = existItem.cloneNode(true)
          item.dataset.number = index
          experienceList.append(item)
          // remove disabled on action button
          let action = item.querySelector('._action')
          if (action) action.classList.remove('disabled')
          // add remove event
          action.addEventListener('click', e => {
            if (e) e.preventDefault()
            item.remove()
          })
        }
        if (item) {
          let company = item.querySelector('.experience-company')
          let role = item.querySelector('.experience-role')
          let type = item.querySelector('.experience-type')
          let start = item.querySelector('.experience-year-start')
          let end = item.querySelector('.experience-year-end')
          if (company) company.value = exp.company
          if (role) role.value = exp.role
          if (type) type.value = exp.type
          if (start) start.value = exp.start
          if (end) end.value = exp.end
        }
      })
    }
    // map languages options to checkboxes
    if (key === 'languages' && value.length > 0) {
      value.map(lang => {
        let cb = form.querySelector(`#lang_${lang}`)
        if (cb) {
          cb.checked = true
          cb.dispatchEvent(event)
        }
      })
    }
    // map location
    if (key === 'location' && value.length > 0) {
      value.map(region => {
        let provinces = Object.keys(region).map( r => region[r] ).shift()
        for (let i = 0; i < provinces.length; i++) {
          let cb = form.querySelector(`.province-checkbox[value="${provinces[i]}"]`)
          if (cb) {
            cb.checked = true
            cb.dispatchEvent(event)
          }
        }
      })
    }
  }
  let updateMapButton = form.querySelector('#map_update_action')
  if (updateMapButton) updateMapButton.click()
  // assign province and district from hidden
  let selectedProvince = form.querySelector(`[name='registration[province]'`);
  let provinceSelector = form.querySelector('.province-combo-select')
  for (let i = 0; i < provinceSelector.options.length; i++) {
    if (provinceSelector.options[i].text == selectedProvince.value) {
      provinceSelector.selectedIndex = i
      break;
    }
  }
  provinceSelector.dispatchEvent(event)
  let selectedDistrict = form.querySelector(`[name='registration[district]'`);
  let districtSelector = form.querySelector('.district-combo-select')
  setTimeout(() => {
    for (let i = 0; i < districtSelector.options.length; i++) {
      if (districtSelector.options[i].text == selectedDistrict.value) {
        districtSelector.selectedIndex = i
        break;
      }
    }
  }, 1200)
  // update map center
}

export const checkingAccountantListingObject = (form) => {
  // remove all errors value
  let preloaderBlock = resetErrorFieldsForm(form)  
  // validation start here
  if ( checkFieldIsEmpty( form.querySelector('#accountant_title'), "กรุณากรอกพาดหัวประกาศ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_prefix'), "กรุณาเลือกคำนำหน้าชื่อ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_first_name'), "กรุณากรอกชื่อของคุณ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_last_name'), "กรุณากรอกนามสกุล" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_gender'), "กรุณากรอกเพศ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_operating_hour'), "กรุณาเลือกประเภทการปฏิบัติงาน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_operating_status'), "กรุณาเลือกฐานะปฏิบัติงาน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_address'), "กรุณาระบุที่อยู่" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#district_value'), "กรุณาระบุอำเภอ/เขต" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#province_value'), "กรุณาระบุจังหวัด" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#accountant_lat'), "กรุณาเลือกตำแหน่งให้บริการบนแผนที่" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#accountant_lng'), "กรุณาเลือกตำแหน่งให้บริการบนแผนที่" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_email'), "กรุณาระบุอีเมล์ของท่าน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_phone_number'), "กรุณาระบุหมายเลขที่ใช้ติดต่อได้" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#accountant_company_name'), "กรุณาระบุชื่อสำนักงานของคุณ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_education_name'), "กรุณาระบุสถาบันการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_education_degree'), "กรุณาเลือกระดับการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_education_faculty'), "กรุณาเลือกสาขาที่จบการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#accountant_education_year'), "กรุณาเลือกปีพ.ศ.ที่จบการศึกษา" ) ) return false
  // checking all business options
  let businessOptionsFlag = businessOptionsCheck(form)
  if (businessOptionsFlag) return false
  // checking main content before save
  if ( !tinymce.get("accountant_content").getContent() ) {
    alertMessage("กรุณาแนะนำบริการของท่านเบื้องต้น", form.querySelector('#accountant_content'))
    form.querySelector('#accountant_content').focus()
    return false
  }
  // build location key value store
  let areaSerialized = []
  areaSerialized = serializeLocationData(form)
  document.getElementById('accountant_location').value = JSON.stringify(areaSerialized)
  // object serializer for education
  serializeEducationData('accountant', form)
  // object serializer for experiences
  serializeExperienceData('accountant', form)
  // object serializer for packages
  let validPackageData = serializePackageData('accountant', form)
  if (validPackageData) {
    preloaderBlock.classList.add('active')
    form.submit()
  } else {
    return false
  }
}

export const checkingAuditorListingObject = (form) => {
  let preloaderBlock = resetErrorFieldsForm(form)  
  // validation start here
  if ( checkFieldIsEmpty( form.querySelector('#auditor_title'), "กรุณากรอกพาดหัวประกาศ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_prefix'), "กรุณาเลือกคำนำหน้าชื่อ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_first_name'), "กรุณากรอกชื่อของคุณ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_last_name'), "กรุณากรอกนามสกุล" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_gender'), "กรุณากรอกเพศ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_auditor_type'), "กรุณาเลือกประเภทผู้สอบบัญชี" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_auditor_number'), "กรุณาระบุหมายเลขทะเบียนผู้สอบบัญชี" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_auditor_working_status'), "กรุณาเลือกสังกัดของผู้สอบบัญชี" ) ) return false
  if (document.getElementById('auditor_auditor_working_status').value === 'corporate'){
    if ( checkFieldIsEmpty( form.querySelector('#auditor_company_name'), "กรุณากรอกชื่อสำนักงานสอบบัญชี" ) ) return false
  }
  if ( checkFieldIsEmpty( form.querySelector('#auditor_address'), "กรุณาระบุที่อยู่" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#district_value'), "กรุณาระบุอำเภอ/เขต" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#province_value'), "กรุณาระบุจังหวัด" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#auditor_lat'), "กรุณาเลือกตำแหน่งให้บริการบนแผนที่" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#auditor_lng'), "กรุณาเลือกตำแหน่งให้บริการบนแผนที่" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_email'), "กรุณาระบุอีเมล์ของท่าน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_phone_number'), "กรุณาระบุหมายเลขที่ใช้ติดต่อได้" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_education_name'), "กรุณาระบุสถาบันการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_education_degree'), "กรุณาเลือกระดับการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_education_faculty'), "กรุณาเลือกสาขาที่จบการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#auditor_education_year'), "กรุณาเลือกปีพ.ศ.ที่จบการศึกษา" ) ) return false
  let businessOptionsFlag = businessOptionsCheck(form)
  if (businessOptionsFlag) return false
  if ( !tinymce.get("auditor_content").getContent() ) {
    alertMessage("กรุณากรอกคำแนะนำบริการของท่าน", form.querySelector('#auditor_content'))
    form.querySelector('#auditor_content').focus()
    return false
  }
  // serialize location data
  let areaSerialized = []
  areaSerialized = serializeLocationData(form)
  document.getElementById('auditor_location').value = JSON.stringify(areaSerialized)
  // serialize education data
  serializeEducationData('auditor', form)
  // serialize education data
  serializeExperienceData('auditor', form)
  // serialize education data
  let validPackageData = serializePackageData('auditor', form)
  if (validPackageData) {
    preloaderBlock.classList.add('active')
    form.submit()
  } else {
    return false
  }
}

export const checkingCourseListingObject = (form) => {
  let preloaderBlock = resetErrorFieldsForm(form)  
  let courseTypeSwitcher = document.getElementById('course_course_types')

  // validation start here
  if ( checkFieldIsEmpty( form.querySelector('#course_organizer'), "กรุณากรอกชื่อผู้จัดคอร์สอบรม" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#course_email'), "กรุณากรอกอีเมล์ของผู้จัด" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#course_phone_number'), "กรุณากรอกเบอร์ติดต่อ" ) ) return false

  if ( checkFieldIsEmpty( form.querySelector('#course_title'), "กรุณากรอกชื่อพาดหัวประกาศ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#course_total_hours'), "กรุณาระบุชั่วโมงอบรม" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#course_price'), "กรุณาระบุราคาของคอร์สอบรม" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#course_lecturer_name'), "กรุณาแจ้งชื่อวิทยากร" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#course_lecturer_title'), "กรุณาแจ้งตำแหน่งของวิทยากร" ) ) return false

  if ( form.querySelector('#course_total_hours').value.includes('_') ) {
    alertMessage("กรุณาระบุรูปแบบของชั่วโมงอบรมให้ถูกต้อง", form.querySelector('#course_total_hours'))
    return false
  }

  if ( noCheckboxChecked( form.querySelector('.outlines-choice-wrapper'), "คุณต้องเลือกหมวดหมู่ของคอร์สอบรมอย่างน้อย 1 ตัวเลือก" ) ) return false
  if ( !tinymce.get("course_content").getContent() ) {
    alertMessage("กรุณาระบุเนื้อหาคอร์สอบรมของท่าน", form.querySelector('#course_content'))
    form.querySelector('#course_content').focus()
    return false
  }
  
  if ( checkFieldIsEmpty( form.querySelector('#course_course_types'), "กรุณาระบุรูปแบบการอบรม" ) ) return false

  if (courseTypeSwitcher.value === 'offline') {
    if ( checkFieldIsEmpty( form.querySelector('#course_venue'), "กรุณาระบุสถานที่จัดอบรม" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_district'), "กรุณาระบุอำเภอ/เขต" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_province'), "กรุณาระบุจังหวัดที่จัดอบรม" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_date_start'), "กรุณาระบุวันที่อบรม" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_time_start'), "กรุณาระบุเวลาเริ่มงาน" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_time_finish'), "กรุณาระบุเวลาที่คาดว่าจะจบงาน" ) ) return false
  } else if (courseTypeSwitcher.value === 'e-learning') {
    if ( checkFieldIsEmpty( form.querySelector('#course_last_registration_date'), "กรุณาระบุวันสุดท้ายที่รับลงทะเบียน" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_course_access_period'), "กรุณาระบุระยะเวลาที่ให้สิทธิเข้าอบรม" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_course_how_to_apply'), "กรุณาระบุช่องทางการเข้าอบรม" ) ) return false
  } else {
    if ( checkFieldIsEmpty( form.querySelector('#course_last_registration_date'), "กรุณาระบุวันสุดท้ายที่รับลงทะเบียน" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_course_how_to_apply'), "กรุณาระบุช่องทางการเข้าอบรม" ) ) return false
  }
  
  if ( checkFieldIsEmpty( form.querySelector('#course_is_cpd_certificated'), "กรุณาระบุประเภทหลักสูตร" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#course_is_ta_certificated'), "กรุณาระบุประเภทหลักสูตร TA ก่อน" ) ) return false

  if ( document.getElementById('course_is_cpd_certificated').value == "true" ) {
    if ( checkFieldIsEmpty( form.querySelector('#course_cpd_accountant_number'), "กรุณาระบุรหัสหลักสูตร CPD" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_cpd_hour_accounting'), "กรุณาระบุชั่วโมงทำบัญชี CPD" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_cpd_hour_other'), "กรุณาระบุชั่วโมงอื่นๆ CPD (ถ้าไม่มีให้ใส่ 0)" ) ) return false
  }
  if ( document.getElementById('course_is_cpa_certificated').value == "true" ) {
    if ( checkFieldIsEmpty( form.querySelector('#course_cpd_cpa_number'), "กรุณาระบุรหัสหลักสูตร CPA" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_cpd_cpa_accounting'), "กรุณาระบุชั่วโมงทำบัญชี CPA" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_cpd_cpa_other'), "กรุณาระบุชั่วโมงอื่นๆ CPA (ถ้าไม่มีให้ใส่ 0)" ) ) return false
  }
  if ( document.getElementById('course_is_ta_certificated').value == "true" ) {
    if ( checkFieldIsEmpty( form.querySelector('#course_ta_number'), "กรุณาระบุรหัสหลักสูตร TA" ) ) return false
    if ( checkFieldIsEmpty( form.querySelector('#course_ta_hour_count'), "กรุณาระบุชั่วโมงอื่นๆ (ถ้าไม่มีให้ใส่ 0)" ) ) return false
  }
  
  // serialize lecturers data
  serializeLecturerData('course', form)
  // serialize dates data
  courseTypeSwitcher.value !== 'e-learning' ? serializeDateData('course', form) : document.getElementById('course_dates').value = ''
  preloaderBlock.classList.add('active')
  form.submit()
}

export const checkingInfrastructureListingObject = (form) => {
  let preloaderBlock = resetErrorFieldsForm(form) 

  // validation start here
  if ( checkFieldIsEmpty( form.querySelector('#infrastructure_title'), "กรุณาระบุพาดหัวประกาศที่ต้องการแสดง" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#infrastructure_company_name'), "กรุณาระบุชื่อสำนักงาน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#infrastructure_address'), "กรุณาระบุที่อยู่ของท่าน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#district_value'), "กรุณาระบุอำเภอ/เขต" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#province_value'), "กรุณาระบุจังหวัด" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#infrastructure_lat'), "กรุณาเลือกตำแหน่งให้บริการบนแผนที่" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#infrastructure_lng'), "กรุณาเลือกตำแหน่งให้บริการบนแผนที่" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#infrastructure_email'), "กรุณาระบุอีเมล์ของท่าน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#infrastructure_phone_number'), "กรุณาระบุเบอร์ติดต่อของท่าน" ) ) return false

  if ( !tinymce.get("infrastructure_content").getContent() ) {
    alertMessage("กรุณากรอกเนื้อหา รายละเอียด และขอบเขตที่ให้บริการ", form.querySelector('#infrastructure_content'))
    form.querySelector('#infrastructure_content').focus()
    return false
  }

  if ( noCheckboxChecked( form.querySelector('.business-categories-choice-wrapper'), "คุณต้องเลือกประเภทธุรกิจที่ให้บริการอย่างน้อย 1 ตัวเลือก" ) ) errorFlag = true
  if ( noCheckboxChecked( form.querySelector('.location-choice-wrapper'), "คุณต้องเลือกพื้นที่ให้บริการอย่างน้อย 1 พื้นที่" ) ) errorFlag = true

  if ( checkFieldIsEmpty( form.querySelector('#advisor_prefix'), "กรุณาเลือก คำนำหน้า ของที่ปรึกษาคนที่ 1 ก่อน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#advisor_first_name'), "กรุณาระบุ ชื่อจริง ของที่ปรึกษาคนที่ 1 ก่อน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#advisor_last_name'), "กรุณาระบุ นามสกุล ของที่ปรึกษาคนที่ 1 ก่อน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#advisor_gender'), "กรุณาเลือก เพศ ของที่ปรึกษาคนที่ 1 ก่อน" ) ) return false
  
  // build location key value store
  let areaSerialized = []
  areaSerialized = serializeLocationData(form)
  document.getElementById('infrastructure_location').value = JSON.stringify(areaSerialized)
  // object serializer for advisors
  serializeAdvisorData('infrastructure', form)
  // object serializer for packages
  let validPackageData = serializePackageData('infrastructure', form)
  if (validPackageData) {
    preloaderBlock.classList.add('active')
    form.submit()
  } else {
    return false
  }
}

export const checkingProgramListingObject = (form) => {
  let preloaderBlock = resetErrorFieldsForm(form) 
  // validation start here
  if ( checkFieldIsEmpty( form.querySelector('#program_title'), "กรุณาระบุพาดหัวประกาศที่ต้องการแสดง" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#program_program_name'), "กรุณาระบุชื่อของโปรแกรม" ) ) return false
  if ( noRadioboxChecked( form.querySelector('.program-seller-wrapper'), "กรุณาเลือกประเภทของผู้ลงประกาศก่อน" ) ) return false
  if ( noCheckboxChecked( form.querySelector('.program-types-wrapper'), "กรุณาเลือกประเภทของโปรแกรมอย่างน้อย 1 ตัวเลือก" ) ) return false
  if ( noCheckboxChecked( form.querySelector('.program-attributes-wrapper'), "กรุณาเลือกลักษณะของโปรแกรมอย่างน้อย 1 ตัวเลือก" ) ) return false

  if ( checkFieldIsEmpty( form.querySelector('#feature_name'), "กรุณาระบุชื่อฟังก์ชันของโปรแกรม" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#feature_description'), "กรุณาระบุคำอธิบายเบื้องต้นของโปรแกรม" ) ) return false

  if ( checkFieldIsEmpty( form.querySelector('#program_company_name'), "กรุณาระบุชื่อของบริษัท" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#program_address'), "กรุณาระบุที่อยู่" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#district_value'), "กรุณาระบุอำเภอ/เขต" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#province_value'), "กรุณาระบุจังหวัด" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#program_lat'), "กรุณาเลื่อนตำแหน่งบนแผนที่ก่อน" ) ) return false
  // if ( checkFieldIsEmpty( form.querySelector('#program_lng'), "กรุณาเลื่อนตำแหน่งบนแผนที่ก่อน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#program_email'), "กรุณาระบุอีเมล์ของท่าน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#program_phone_number'), "กรุณาระบุเบอร์ติดต่อของท่าน" ) ) return false
  
  // serailize package data
  serializeProgramPackageData('program', form)

  // serialize feature data
  serializeFeatureData('program', form)
  
  preloaderBlock.classList.add('active')
  form.submit()

}

export const checkingRegistrationListingObject = (form) => {
  // remove all errors value
  let preloaderBlock = resetErrorFieldsForm(form)  
  // validation start here
  if ( checkFieldIsEmpty( form.querySelector('#registration_title'), "กรุณากรอกพาดหัวประกาศ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_prefix'), "กรุณาเลือกคำนำหน้าชื่อ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_first_name'), "กรุณากรอกชื่อของคุณ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_last_name'), "กรุณากรอกนามสกุล" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_gender'), "กรุณากรอกเพศ" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_registant_type'), "กรุณาเลือกประเภทผู้ประกอบวิชาชีพบัญชี" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_address'), "กรุณาระบุที่อยู่" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#district_value'), "กรุณาระบุอำเภอ/เขต" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#province_value'), "กรุณาระบุจังหวัด" ) ) return false

  if ( checkFieldIsEmpty( form.querySelector('#registration_email'), "กรุณาระบุอีเมล์ของท่าน" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_phone_number'), "กรุณาระบุหมายเลขที่ใช้ติดต่อได้" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_education_name'), "กรุณาระบุสถาบันการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_education_degree'), "กรุณาเลือกระดับการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_education_faculty'), "กรุณาเลือกสาขาที่จบการศึกษา" ) ) return false
  if ( checkFieldIsEmpty( form.querySelector('#registration_education_year'), "กรุณาเลือกปีพ.ศ.ที่จบการศึกษา" ) ) return false

  // checking all business options
  if ( noCheckboxChecked( form.querySelector('.location-choice-wrapper'), "คุณต้องเลือกพื้นที่ให้บริการอย่างน้อย 1 พื้นที่" ) ) return false
  if ( noCheckboxChecked( form.querySelector('.langs-choice-wrapper'), "คุณต้องเลือกภาษาที่ให้บริการอย่างน้อย 1 ภาษา" ) ) return false

  // checking main content before save
  if ( !tinymce.get("registration_content").getContent() ) {
    alertMessage("กรุณาแนะนำบริการของท่านเบื้องต้น", form.querySelector('#registration_content'))
    form.querySelector('#registration_content').focus()
    return false
  }

  // checking process before save
  if ( !tinymce.get("registration_process").getContent() ) {
    alertMessage("กรุณาระบุขั้นตอนการทำงาน และเงื่อนไขการให้บริการ", form.querySelector('#registration_process'))
    form.querySelector('#registration_process').focus()
    return false
  }

  // build location key value store
  let areaSerialized = []
  areaSerialized = serializeLocationData(form)
  document.getElementById('registration_location').value = JSON.stringify(areaSerialized)
  // object serializer for education
  serializeEducationData('registration', form)
  // object serializer for experiences
  serializeExperienceData('registration', form)
  // object serializer for packages
  let validPackageData = serializePackageData('registration', form)
  if (validPackageData) {
    preloaderBlock.classList.add('active')
    form.submit()
  } else {
    return false
  }
}