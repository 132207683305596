import React from "react"
import ReactHtmlParser from 'react-html-parser'
import scrollToElement from 'scroll-to-element'

import { isObjectEmpty, setRequireFieldAfterRemoveSequenceItem } from './../helper/utils'

class SequenceAdvisor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentNumber: 1,
      currentExperience: 1,
      currentEducation: 1,
      currentCertificate: 1,
      editFirstItem: false,
      editExperienceFirstItem: false,
      editEducationFirstItem: false,
      editCertificateFirstItem: false,
      firstTimeLoaded: false,
      items: []
    }
  }

  componentDidMount() {
    // add on click event to first item
    if ( !isObjectEmpty(this.props.items) ) {
      if (JSON.parse(this.props.items).length > 0) {
        this.setState({ items: JSON.parse(this.props.items) }, () => {
          // add button event to all items
          let wrapper = document.getElementById(`${this.props.model}_list`)
          let items = wrapper.querySelectorAll(':scope > .sequence-item')
          let currentNumber = 0
          items.forEach((item, index) => {
            this.addButtonEventToItem(item)
            if (index === 0) item.querySelector('._action').classList.add('disabled')
            currentNumber += 1
          })
          // update currentnumber
          this.setState({currentNumber})
        })
      }
    } else {
      let wrapper = document.getElementById(`${this.props.model}_list`)
      let firstItem = wrapper.querySelector('.sequence-item[data-number="1"]')
      this.addButtonEventToItem(firstItem)
      // add remove and move event to first sub item
      // firstItem.querySelector('._action').classList.add('disabled')
      let actionSubItems = firstItem.querySelectorAll('._action')
      actionSubItems.forEach(action => {
        action.classList.add('disabled')
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let mainWrapper = document.getElementById(`${this.props.model}_list`)
    
    let firstItem = mainWrapper.querySelector('.sequence-item:first-child')
    nextState.editFirstItem
      ? firstItem.querySelector('._action').classList.remove('disabled')
      : firstItem.querySelector('._action').classList.add('disabled')
    return true
  }

  reOrderItems = (wrapperEl, mainScope = false) => {
    let items = wrapperEl.querySelectorAll(':scope > .sequence-item')
    let lastItemNumber = 1
    items.forEach((el, index) => {
      let lastIndex = index + 1
      let advisorTitleText = el.querySelector('.portlet-section-title:first-child')
      el.dataset.number = lastIndex
      lastItemNumber = lastIndex
      if (mainScope) {
        advisorTitleText.innerHTML = `<i class="icon icon-user"></i> ที่ปรึกษาคนที่ ${lastIndex}`
      }
      if (lastIndex === 1) {
        setRequireFieldAfterRemoveSequenceItem(el, '.prefix', 'advisor_prefix', 'คำนำหน้า <span class="req">*</span>')
        setRequireFieldAfterRemoveSequenceItem(el, '.first-name', 'advisor_first_name', 'ชื่อจริง <span class="req">*</span>')
        setRequireFieldAfterRemoveSequenceItem(el, '.last-name', 'advisor_last_name', 'นามสกุล <span class="req">*</span>')
        setRequireFieldAfterRemoveSequenceItem(el, '.gender', 'advisor_gender', 'เพศ <span class="req">*</span>')
      }
    })
    return lastItemNumber
  }

  sortItems = (wrapperEl) => {
    let items = wrapperEl.querySelectorAll(':scope > .sequence-item')
    items = [].map.call(items, (elm) => elm)
    items.sort((a, b) => {
      if (parseInt(a.dataset.number) > parseInt(b.dataset.number)) return 1
      if (parseInt(a.dataset.number) < parseInt(b.dataset.number)) return -1
      return 0
    })
    items.forEach(item => wrapperEl.appendChild(item))
  }

  addMoreItem = (e) => {
    if (e) e.preventDefault()
    let wrapper = e.currentTarget.closest('._body').querySelector(`#${this.props.model}_list`)
    let newNumber = parseInt(this.state.currentNumber) + 1
    let newItem = this.advisorSequenceItemTemplate(newNumber)
    wrapper.insertAdjacentHTML('beforeend', newItem)
    // add new event to last added element
    let recentAddedItem = wrapper.querySelector(`:scope > .sequence-item[data-number="${newNumber}"]`)
    this.addButtonEventToItem(recentAddedItem, newNumber)
    this.setState({ currentNumber: newNumber, editFirstItem: true })
    // scroll to new added item
    scrollToElement(recentAddedItem, { offset: -32, duration: 300 })
  }

  checkStateOfActionGroup = (wrapperEl) => {
    let scopeItems = wrapperEl.querySelectorAll('.sequence-item')
    let scopeFirstItem = wrapperEl.querySelector(':scope > .sequence-item:first-child')
    scopeItems.length <= 1
      ? scopeFirstItem.querySelector('._action').classList.add('disabled')
      : scopeFirstItem.querySelector('._action').classList.remove('disabled')
  }

  addRemoveEventToSubItem = (currentItem, wrapperEl) => {
    // when clicked on remove experience button
    let removeExperience = currentItem.querySelector('.remove-sequence')
    removeExperience.addEventListener('click', () => {
      currentItem.remove()
      this.checkStateOfActionGroup(wrapperEl)
      this.reOrderItems(wrapperEl)
    })
  }

  addMoveEventToSubItem = (currentItem, wrapperEl) => {
    let moveExperienceButtons = currentItem.querySelectorAll('.move-sequence')
    moveExperienceButtons.forEach(button => {
      button.addEventListener('click', () => {
        let thisSequence = parseInt(currentItem.dataset.number)
        let direction = button.dataset.direction
        let newSequence = direction === 'up' ? thisSequence - 1 : thisSequence + 1
        let targetRow = direction === 'up' ? currentItem.previousElementSibling : currentItem.nextElementSibling
        // if target existed, swap the number
        if (targetRow) {
          targetRow.setAttribute('data-number', thisSequence)
          currentItem.setAttribute('data-number', newSequence)
          this.sortItems(wrapperEl)
        }
      })
    })
  }

  addEventToSubItem = (button, wrapperEl, templateType, editStateFirstItem, currentStateType) => {
    button.addEventListener('click', e => {
      if (e) e.preventDefault()
      let wrapperId = wrapperEl.getAttribute('id')
      let mainScope = e.currentTarget.closest('._form').querySelector('#' + wrapperId)
      let newNumber = parseInt(mainScope.querySelectorAll('.sequence-item').length) + 1
      let addedItem = null
      switch (templateType) {
        case 'experience':
          addedItem = this.experienceSequenceItemTemplate(newNumber)
          break;
        case 'education':
          addedItem = this.educationSequenceItemTemplate(newNumber)
          break;
        default:
          addedItem = this.certificateSequenceItemTemplate(newNumber)
          break;
      }
      wrapperEl.insertAdjacentHTML('beforeend', addedItem)
      let currentItem = wrapperEl.querySelector(`:scope > .sequence-item[data-number="${newNumber}"]`)
      this.checkStateOfActionGroup(wrapperEl)
      this.addRemoveEventToSubItem(currentItem, wrapperEl, editStateFirstItem, currentStateType)
      this.addMoveEventToSubItem(currentItem, wrapperEl)
    })
  }

  addButtonEventToItem = (el, number = 1) => {
    // assign main sequence events
    let wrapperEl = el.closest(`#${this.props.model}_list`)
    let removeButton = el.querySelector('.remove-sequence')
    let moveButton = el.querySelectorAll('.move-sequence')
    this.initEditor(`#advisor_specialty_${number}`)
    removeButton.addEventListener('click', () => {
      el.remove()
      let lastItemNumber = this.reOrderItems(wrapperEl, true)
      wrapperEl.querySelectorAll(':scope > .sequence-item').length <= 1
        ? this.setState({ editFirstItem: false })
        : this.setState({ editFirstItem: true })
      this.setState({ currentNumber: lastItemNumber })
    })
    moveButton.forEach(button => {
      button.addEventListener('click', () => {
        let thisSequence = parseInt(el.dataset.number)
        let direction = button.dataset.direction
        let newSequence = direction === 'up' ? thisSequence - 1 : thisSequence + 1
        let targetRow = direction === 'up' ? el.previousElementSibling : el.nextElementSibling
        // if target existed, swap the number
        if (targetRow) {
          targetRow.setAttribute('data-number', thisSequence)
          el.setAttribute('data-number', newSequence)
          this.sortItems(wrapperEl)
          this.reOrderItems(wrapperEl, true)
          this.reinitEditor()
        }
      })
    })
    this.addButtonEventToSubItem(el)
  }

  addButtonEventToSubItem = (subItem) => {
    // assign sub-sequence events
    // new experience
    let subExperienceWrapper = subItem.querySelector(`#${this.props.model}_experience`)
    let addNewExperience = subItem.querySelector('.add-experience-item')
    this.addRemoveEventToSubItem(subExperienceWrapper.querySelector('.sequence-item:last-child'), subExperienceWrapper)
    this.addMoveEventToSubItem(subExperienceWrapper.querySelector('.sequence-item:last-child'), subExperienceWrapper)
    this.addEventToSubItem(addNewExperience, subExperienceWrapper, 'experience', 'editExperienceFirstItem', 'currentExperience')
    subExperienceWrapper.querySelector('._action').classList.add('disabled')
    // new education
    let subEducationWrapper = subItem.querySelector(`#${this.props.model}_education`)
    let addNewEducation = subItem.querySelector('.add-education-item')
    this.addRemoveEventToSubItem(subEducationWrapper.querySelector('.sequence-item:last-child'), subEducationWrapper)
    this.addMoveEventToSubItem(subEducationWrapper.querySelector('.sequence-item:last-child'), subEducationWrapper)
    this.addEventToSubItem(addNewEducation, subEducationWrapper, 'education', 'editEducationFirstItem', 'currentEducation')
    subEducationWrapper.querySelector('._action').classList.add('disabled')
    // new certificate
    let subCertificateWrapper = subItem.querySelector(`#${this.props.model}_certificate`)
    let addNewCertificate = subItem.querySelector('.add-certificate-item')
    this.addRemoveEventToSubItem(subCertificateWrapper.querySelector('.sequence-item:last-child'), subCertificateWrapper)
    this.addMoveEventToSubItem(subCertificateWrapper.querySelector('.sequence-item:last-child'), subCertificateWrapper)
    this.addEventToSubItem(addNewCertificate, subCertificateWrapper, 'certificate', 'editCertificateFirstItem', 'currentCertificate')
    subCertificateWrapper.querySelector('._action').classList.add('disabled')
  }

  /* template string goes here. */

  experienceSequenceItemTemplate = (number, item = null) => {
    let today = new Date()
    let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
    let yearOptions = Array.from({length: thaiShortYear}, (x, i) => i + 2500) // start from 2500
    return `
      <div class="sequence-item" data-number="${number}">
        <div class="_action">
          <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
        </div>
        <div class="_form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ชื่อโปรเจค/ชื่อตำแหน่ง</label>
                <input type="text" class="form-control exp-project" ${item ? `value="${item.project}"` : ''} />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ชื่อบริษัท</label>
                <input type="text" class="form-control exp-company" ${item ? `value="${item.company}"` : ''} />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">ปีพ.ศ.ที่เริ่ม</label>
                <select class="form-control exp-project-start">
                  <option value=""></option>
                  ${yearOptions.map(i => `<option value=${i} ${item && item.start == i ? `selected` : ''}>${i}</option>`)}
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ปีพ.ศ.ที่เริ่ม</label>
                <select  class="form-control exp-project-finish">
                  <option value=""></option>
                  ${yearOptions.map(i => `<option value=${i} ${item && item.finish == i ? `selected` : ''}>${i}</option>`)}
                  <option value="ปัจจุบัน">ถึงปัจจุบัน</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <p class="form-note">ไม่บังคับกรอก แต่การเปิดเผยจะช่วยเพิ่มความน่าเชื่อถือของท่าน</p>
            </div>
          </div>
        </div>
      </div>
    `
  }

  educationSequenceItemTemplate = (number, item = null) => {
    let today = new Date()
    let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
    let yearOptions = Array.from({length: thaiShortYear}, (x, i) => i + 2500); // start from 2500
    return `
      <div class="sequence-item" data-number="${number}">
        <div class="_action">
          <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
        </div>
        <div class="_form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">สถาบันการศึกษา <span class="req">*</span></label>
                <input type="text" class="form-control institution" ${item ? `value="${item.institution}"` : ''} />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ระดับการศึกษา <span class="req">*</span></label>
                <select name=""  class="form-control education-level">
                  <option value=""></option>
                  <option value="diploma" ${item && item.education_level == "diploma" ? `selected` : ''}>ปวส / อนุปริญญาตรี</option>
                  <option value="bachelor" ${item && item.education_level == "bachelor" ? `selected` : ''}>ปริญญาตรี</option>
                  <option value="master" ${item && item.education_level == "master" ? `selected` : ''}>ปริญญาโท</option>
                  <option value="phd" ${item && item.education_level == "phd" ? `selected` : ''}>ปริญญาเอก</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">สาขา <span class="req">*</span></label>
                <input type="text" class="form-control faculty" ${item ? `value="${item.faculty}"` : ''} />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ปีพ.ศ. ที่จบ <span class="req">*</span></label>
                <select class="form-control graduate-year">
                  <option value=""></option>
                  ${yearOptions.map(i => `<option value=${i} ${item && item.graduate_year == i ? `selected` : ''}>${i}</option>`)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  certificateSequenceItemTemplate = (number, item = null) => {
    let today = new Date()
    let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
    let yearOptions = Array.from({length: thaiShortYear}, (x, i) => i + 2500); // start from 2500
    return `
      <div class="sequence-item" data-number="${number}">
        <div class="_action">
          <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
        </div>
        <div class="_form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ใบอนุญาตหรือประกาศนียบัตร</label>
                <input type="text" class="form-control cert-name" ${item ? `value="${item.name}"` : ''} />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ปีพ.ศ.ที่ได้รับ</label>
                <select class="form-control cert-year">
                  <option value=""></option>
                  ${yearOptions.map(i => `<option value=${i} ${item && item.year == i ? `selected` : ''}>${i}</option>`)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  advisorSequenceItemTemplate = (number, item = null) => {
    let today = new Date()
    let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
    return `
      <div class="sequence-item" data-number="${number}">
        <div class="_action">
          <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
        </div>
        <div class="_form">
          <h4 class="portlet-section-title ${number === 1 ? 'no-top' : ''}"><i class="icon icon-user"></i> ที่ปรึกษาคนที่ ${number}</h4>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="" class="control-label">คำนำหน้า ${number === 1 ? '<span class="req">*</span>' : ''}</label>
                <select name="" class="form-control prefix" ${number === 1 ? 'id="advisor_prefix"' : ''}>
                  <option value=""></option>
                  <option value="mr" ${item && item.prefix === "mr" ? `selected` : ''}>นาย</option>
                  <option value="ms" ${item && item.prefix === "ms" ? `selected` : ''}>นางสาว</option>
                  <option value="mrs" ${item && item.prefix === "mrs" ? `selected` : ''}>นาง</option></select>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="" class="control-label">ชื่อจริง ${number === 1 ? '<span class="req">*</span>' : ''}</label>
                <input type="text" class="form-control first-name" ${number === 1 ? 'id="advisor_first_name"' : ''} ${item ? `value="${item.first_name}"` : ''} />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="" class="control-label">นามสกุล ${number === 1 ? '<span class="req">*</span>' : ''}</label>
                <input type="text" class="form-control last-name" ${number === 1 ? 'id="advisor_last_name"' : ''} ${item ? `value="${item.last_name}"` : ''} />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="" class="control-label">เพศ ${number === 1 ? '<span class="req">*</span>' : ''}</label>
                <select class="form-control gender" ${number === 1 ? 'id="advisor_gender"' : ''} >
                  <option value=""></option>
                  <option value="male" ${item && item.gender === "male" ? `selected` : ''}>ชาย</option>
                  <option value="female" ${item && item.gender === "female" ? `selected` : ''}>หญิง</option></select>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="advisor_specialty_${number}" class="control-label">ความเชี่ยวชาญ</label>
            <textarea class="form-control fe-editor specialty" id="advisor_specialty_${number}" ${item ? `value="${item.specialty}"` : ''} ></textarea>
          </div>
          <h4 class="portlet-section-title">ประสบการณ์ทำงาน</h4>
          <div class="sequence-wrapper" id="${this.props.model}_experience">
            ${
              item && item.experiences.length > 0 
                ? item.experiences.map( (item, index) => this.experienceSequenceItemTemplate(index+1, item) )
                : this.experienceSequenceItemTemplate(1)
            }
          </div>
          <div class="sequence-add-item">
            <button class="btn btn-block add-experience-item" type="button">
              <i class="icon icon-plus"></i> เพิ่มประสบการณ์
            </button>
          </div>
          <h4 class="portlet-section-title">ประวัติการศึกษา</h4>
          <div class="sequence-wrapper" id="${this.props.model}_education">
            ${
              item && item.educations.length > 0 
                ? item.educations.map( (item, index) => this.educationSequenceItemTemplate(index+1, item) )
                : this.educationSequenceItemTemplate(1)
            }
          </div>
          <div class="sequence-add-item">
            <button class="btn btn-block add-education-item" type="button">
              <i class="icon icon-plus"></i> เพิ่มประวัติการศึกษา
            </button>
          </div>
          <h4 class="portlet-section-title">ใบอนุญาตหรือประกาศนียบัตร</h4>
          <div class="sequence-wrapper" id="${this.props.model}_certificate">
          ${
            item && item.certificates.length > 0 
              ? item.certificates.map( (item, index) => this.certificateSequenceItemTemplate(index+1, item) )
              : this.certificateSequenceItemTemplate(1)
          }
          </div>
          <div class="sequence-add-item">
            <button class="btn btn-block add-certificate-item" type="button">
              <i class="icon icon-plus"></i> เพิ่มใบอนุญาตหรือประกาศนียบัตร
            </button>
          </div>
        </div>
      </div>
    `
  }

  reinitEditor = () => {
    let wrapper = document.getElementById(`${this.props.model}_list`)
    let items = wrapper.querySelectorAll(':scope > .sequence-item')
    items.forEach(item => {
      let editor = item.querySelector('.fe-editor')
      let editorId = editor.getAttribute('id')
      if (editor) {
        tinymce.remove(`#${editorId}`)
        this.initEditor(`#${editorId}`)
      }
    })
  }

  initEditor = (editorId) => {
    tinymce.init({
      selector: editorId,
      plugins: ['lists', 'table', 'link', 'paste'],
      menu: {
        edit: { title: 'แก้ไข', items: 'undo redo | cut copy paste | selectall' },
        insert: { title: 'แทรก', items: 'link inserttable charmap emoticons hr anchor' },
        format: { title: 'แบบอักษร', items: 'bold italic underline strikethrough superscript subscript formats | forecolor backcolor | removeformat' },
        table: { title: 'ตาราง', items: 'inserttable | cell row column | tableprops deletetable' },
      },
      menubar: 'edit insert format table',
      toolbar: 'undo redo | bold italic link | alignleft aligncenter alignright alignjustify | outdent indent numlist bullist',
      oninit : 'setPlainText',
      skin: false,
      branding: false,
      language: 'th_TH',
      body_class: 'editor-class',
      content_css: '/editor.css',
      content_style: "body { font-family: 'cs_chatthaiuiregular'; font-size: 16px; line-height: 1.8 }",
      min_height: 480,
      paste_as_text: true,
      block_formats: false,
      default_link_target: '_blank',
      rel_list: [
        {title: 'External Link', value: 'nofollow ugc'},
        {title: 'Follow Link', value: 'dofollow'},
        {title: 'Sponsored Link', value: 'sponsored'}
      ],
      target_list: [
        {title: 'เปิดหน้าต่างใหม่', value: '_blank'},
      ],
      style_formats: [
        {
          title: 'พาดหัว 1',
          block: 'h1',
          styles: { "font-size": "28px", "line-height": "1.8" }
        },
        {
          title: 'พาดหัว 2',
          block: 'h2',
          styles: { "font-size": "20px", "line-height": "1.8" }
        },
        {
          title: 'เน้นข้อความ',
          block: 'blockquote',
          styles: { "background-color": "#F4f4f4", "padding": "24px 16px", "margin": "0px", "border-left": "3px solid #ececec" }
        },
        {
          title: 'ไฮไลท์ส้ม',
          inline: 'span',
          styles: { "color": "#FB6900" }
        },
        {
          title: 'ไฮไลท์น้ำเงิน',
          inline: 'span',
          styles: { "color": "#1D72B2" }
        },
        {
          title: 'ตัวอักษรธรรมดา',
          block: 'p',
          styles: { "color": "#000000", "font-size": "16px", "line-height": "1.8" }
        },
      ]
    })
  }

  render () {
    return (
      <React.Fragment>
        <div className="sequence-wrapper" id={`${this.props.model}_list`}>
          {
            this.state.items.length > 0 
              ? this.state.items.map( (item,index) => {
                let today = new Date()
                let thaiShortYear = (today.getFullYear() - 1956) // 2000 - 542
                let yearOptions = Array.from({length: thaiShortYear}, (x, i) => i + 2500) // start from 2500
                let number = index + 1
                return (
                  <div className="sequence-item" data-number={number} key={number}>
                    <div className="_action">
                      <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
                    </div>
                    <div className="_form">
                      <h4 className={`portlet-section-title ${number === 1 ? 'no-top' : ''}`}><i className="icon icon-user"></i> ที่ปรึกษาคนที่ {number}</h4>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="control-label">คำนำหน้า {number === 1 ? <span className="req">*</span> : '' }</label>
                            <select name="" className="form-control prefix" id={number === 1 ? 'advisor_prefix' : ''} defaultValue={item.prefix}>
                              <option value=""></option>
                              <option value="mr">นาย</option>
                              <option value="ms">นางสาว</option>
                              <option value="mrs">นาง</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="control-label">ชื่อจริง {number === 1 ? <span className="req">*</span> : ''}</label>
                            <input type="text" className="form-control first-name" id={number === 1 ? 'advisor_first_name' : ''} defaultValue={item.first_name} />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="control-label">นามสกุล {number === 1 ? <span className="req">*</span> : ''}</label>
                            <input type="text" className="form-control last-name" id={number === 1 ? 'advisor_last_name' : ''} defaultValue={item.last_name} />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="control-label">เพศ {number === 1 ? <span className="req">*</span> : ''}</label>
                            <select className="form-control gender" id={number === 1 ? 'advisor_gender' : ''} defaultValue={item.gender}>
                              <option value=""></option>
                              <option value="male">ชาย</option>
                              <option value="female">หญิง</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor={`advisor_specialty_${number}`} className="control-label">ความเชี่ยวชาญ</label>
                        <textarea className="form-control fe-editor specialty" id={`advisor_specialty_${number}`} defaultValue={item.specialty} />
                      </div>
                      <h4 className="portlet-section-title">ประสบการณ์ทำงาน</h4>
                      <div className="sequence-wrapper" id={`${this.props.model}_experience`}>
                        {/* expereinces */}
                        {
                          item && item.experiences.length > 0 
                            ? item.experiences.map( (experience, eIndex) => {
                              let eNumber = eIndex + 1
                              return (
                                <div className="sequence-item" data-number={eNumber} key={eNumber}>
                                  <div className="_action">
                                    <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
                                  </div>
                                  <div className="_form">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">ชื่อโปรเจค/ชื่อตำแหน่ง</label>
                                          <input type="text" className="form-control exp-project" defaultValue={experience.project}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">ชื่อบริษัท</label>
                                          <input type="text" className="form-control exp-company" defaultValue={experience.company} />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">ปีพ.ศ.ที่เริ่ม</label>
                                          <select className="form-control exp-project-start" defaultValue={experience.start}>
                                            <option value=""></option>
                                            {yearOptions.map(i => <option value={i} key={i}>{i}</option>)}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">ปีพ.ศ.ที่เริ่ม</label>
                                          <select  className="form-control exp-project-finish" defaultValue={experience.finish}>
                                            <option value=""></option>
                                            {yearOptions.map(i => <option value={i} key={i}>{i}</option>)}
                                            <option value="ปัจจุบัน">ถึงปัจจุบัน</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <p className="form-note">ไม่บังคับกรอก แต่การเปิดเผยจะช่วยเพิ่มความน่าเชื่อถือของท่าน</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            } )
                            : null
                        }
                        {/* expereinces */}
                      </div>
                      <div className="sequence-add-item">
                        <button className="btn btn-block add-experience-item" type="button">
                          <i className="icon icon-plus"></i> เพิ่มประสบการณ์
                        </button>
                      </div>
                      <h4 className="portlet-section-title">ประวัติการศึกษา</h4>
                      <div className="sequence-wrapper" id={`${this.props.model}_education`}>
                        {/* educations */}
                        {
                          item && item.educations.length > 0 
                            ? item.educations.map( (education, eduIndex) => {
                              let eduNumber = eduIndex + 1
                              return (
                                <div className="sequence-item" data-number={eduNumber} key={eduNumber}>
                                  <div className="_action">
                                    <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
                                  </div>
                                  <div className="_form">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">สถาบันการศึกษา <span class="req">*</span></label>
                                          <input type="text" className="form-control institution" defaultValue={education.institution} />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">ระดับการศึกษา</label>
                                          <select className="form-control education-level" defaultValue={education.education_level}>
                                            <option value=""></option>
                                            <option value="diploma">ปวส / อนุปริญญาตรี</option>
                                            <option value="bachelor">ปริญญาตรี</option>
                                            <option value="master">ปริญญาโท</option>
                                            <option value="phd">ปริญญาเอก</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">สาขา</label>
                                          <input type="text" className="form-control faculty" defaultValue={education.faculty} />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">ปีพ.ศ. ที่จบ</label>
                                          <select className="form-control graduate-year" defaultValue={education.graduate_year}>
                                            <option value=""></option>
                                            {yearOptions.map(i => <option value={i} key={i}>{i}</option>)}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            } )
                            : null
                        }
                        {/* educations */}
                      </div>
                      <div className="sequence-add-item">
                        <button className="btn btn-block add-education-item" type="button">
                          <i className="icon icon-plus"></i> เพิ่มประวัติการศึกษา
                        </button>
                      </div>
                      <h4 className="portlet-section-title">ใบอนุญาตหรือประกาศนียบัตร</h4>
                      <div className="sequence-wrapper" id={`${this.props.model}_certificate`}>
                        {/* certs */}
                          {
                            item && item.certificates.length > 0 
                            ? item.certificates.map( (certificate, certIndex) => {
                              let certNumber = certIndex + 1
                              return (
                                <div className="sequence-item" data-number={certNumber} key={certNumber}>
                                  <div className="_action">
                                    <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
                                  </div>
                                  <div className="_form">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">ใบอนุญาตหรือประกาศนียบัตร</label>
                                          <input type="text" className="form-control cert-name" defaultValue={certificate.name} />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="control-label">ปีพ.ศ.ที่ได้รับ</label>
                                          <select className="form-control cert-year" defaultValue={certificate.year}>
                                            <option value=""></option>
                                            {yearOptions.map(i => <option value={i} key={i}>{i}</option>)}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                            : null
                          }
                        {/* certs */}
                      </div>
                      <div className="sequence-add-item">
                        <button className="btn btn-block add-certificate-item" type="button">
                          <i className="icon icon-plus"></i> เพิ่มใบอนุญาตหรือประกาศนียบัตร
                        </button>
                      </div>
                    </div>
                  </div>
                )
              } )
              : ReactHtmlParser( this.advisorSequenceItemTemplate(1) )
          }
        </div>
        <div className="sequence-add-item">
          <button className="btn btn-block add-sequence-item" type="button" onClick={(e) => this.addMoreItem(e)}>
            <i className="icon icon-plus"></i> เพิ่มที่ปรึกษา
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SequenceAdvisor
