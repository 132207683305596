import React from "react"
import Chart from 'chart.js'
import axios from 'axios'

let chart = ''

class MemberStatsChart extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      pageviews: this.props.pageviews,
      months: this.props.months
    }
  }

  updateStats = (e) => {
    let selectField = document.getElementById('listing_stats')
    let button = e.currentTarget
    let listingId = selectField.value
    let listingType = selectField.options[selectField.selectedIndex].dataset.type
    let pageviews = []
    // preloaderBlock.classList.add('active')
    button.setAttribute('disabled', 'disabled')
    axios.post('/member/update-stats', { listing_id: listingId, listing_type: listingType })
    .then((response) => {
      if (response.status === 200 ) pageviews = response.data.payload 
      this.setState({pageviews}, () => {
        // remove exist data
        chart.data.datasets.forEach((dataset) => {
          dataset.data.pop()
        })
        // then add new data
        chart.data.datasets[0].data = pageviews
        // update the chart
        chart.update()
        // preloaderBlock.classList.remove('active')
        setTimeout(() => {
          button.removeAttribute('disabled')
        }, 600)
      })
    })
    .catch(err => console.log(err))
  }
  
  componentDidMount() {
    let ctx = document.getElementById('member_stats_dashboard')
    chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.state.months,
        datasets: [
          { 
            label: 'ยอดผู้เข้าชมแต่ละเดือน (6 เดือนย้อนหลัง)',
            data: this.state.pageviews,
            backgroundColor: 'rgba(29, 114, 178, 0.5)',
            borderColor: 'rgba(29, 114, 178, 1)',
            borderWidth: 2,
            pointBorderWidth: 5,
            fill: 'start',
            lineTension: 0.1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return `มียอดเข้าชมเดือน ${tooltipItem.label} ทั้งหมดอยู่ที่ ${tooltipItem.value} ครั้ง`
            }
          }
        },
        plugins: {
          filler: {
            propagate: true
          }
        }
      },
    })
  }
  
  render () {
    return (
      <React.Fragment>
        <form className="form chart-form" id="member_stats_form">
          <div className="form-group">
            <label htmlFor="" className="control-label">เลือกประกาศที่ต้องการดูสถิติ</label>
            <div className="row">
              <div className="col-md-8">
                <select name="" className="form-control" id="listing_stats">
                  <option value="">แสดงรวมทุกประกาศ</option>
                  {
                    this.props.listings && this.props.listings.length > 0
                    ? this.props.listings.map( (listing, index) => <option key={index} value={listing.id} data-type={listing.post_type}>{listing.title}</option>)
                    : null
                  }
                </select>
              </div>
              <div className="col-md-4">
                <button type="button" onClick={this.updateStats} className="btn btn-warning btn-block">
                  อัพเดทข้อมูล
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* chart goes here */}
        <canvas id="member_stats_dashboard" className="member-chart"></canvas>
      </React.Fragment>
      );
    }
  }
  
  export default MemberStatsChart
  