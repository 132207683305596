import React from "react"
import ReactHtmlParser from 'react-html-parser'

import { isObjectEmpty, setRequireFieldAfterRemoveSequenceItem } from './../helper/utils'

class SequenceInstructor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentNumber: 1,
      editFirstItem: false,
      items: []
    }
  }

  componentDidMount() {
    // add on click event to first item
    if ( !isObjectEmpty(this.props.items) ) {
      if (JSON.parse(this.props.items).length > 0) {
        this.setState({ items: JSON.parse(this.props.items) }, () => {
          // add button event to all items
          let wrapper = document.getElementById(`${this.props.model}_lecturer`)
          let items = wrapper.querySelectorAll('.sequence-item')
          let currentNumber = 0
          items.forEach((item, index) => {
            this.addButtonEventToItem(item)
            if (index === 0) item.querySelector('._action').classList.add('disabled')
            currentNumber += 1
          })
          // update currentnumber
          this.setState({currentNumber})
        })
      }
    } else {
      let wrapper = document.getElementById('course_lecturer')
      let firstItem = wrapper.querySelector('.sequence-item[data-number="1"]')
      this.addButtonEventToItem(firstItem)
      firstItem.querySelector('._action').classList.add('disabled')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let wrapper = document.getElementById('course_lecturer')
    let firstItem = wrapper.querySelector('.sequence-item:first-child')
    nextState.editFirstItem
      ? firstItem.querySelector('._action').classList.remove('disabled')
      : firstItem.querySelector('._action').classList.add('disabled')
    return true
  }

  instructorSequenceItemTemplate = (number, item = null) => {
    return `
      <div class="sequence-item" data-number="${number}">
        <div class="_action">
          <button type="button" class="remove-sequence"><i class="icon icon-close"></i></button>
        </div>
        <div class="_form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ชื่อวิทยากร ${number === 1 ? `<span class="req">*</span>` : ''}</label>
                <input type="text" class="form-control lecturer-name" ${number === 1 ? `id="course_lecturer_name"` : ''} ${item ? `value="${item.name}"` : ''} />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="" class="control-label">ตำแหน่งวิทยากร</label>
                <input type="text" class="form-control lecturer-title" ${item ? `value="${item.title}"` : ''} />
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  reOrderItems = () => {
    let wrapper = document.getElementById('course_lecturer')
    let items = wrapper.querySelectorAll('.sequence-item')
    let lastItemNumber = 1
    items.forEach((el, index) => {
      let lastIndex = index + 1
      el.dataset.number = lastIndex
      lastItemNumber = lastIndex
      // add id and require filed to first item
      if (lastIndex === 1) {
        setRequireFieldAfterRemoveSequenceItem(el, '.lecturer-name', 'course_lecturer_name', 'ชื่อวิทยากร <span class="req">*</span>')
      }
    })
    return lastItemNumber
  }

  sortItems = () => {
    let wrapper = document.getElementById('course_lecturer')
    let items = wrapper.querySelectorAll('.sequence-item')
    items = [].map.call(items, (elm) => elm)
    items.sort((a, b) => {
      if (parseInt(a.dataset.number) > parseInt(b.dataset.number)) return 1
      if (parseInt(a.dataset.number) < parseInt(b.dataset.number)) return -1
      return 0
    })
    items.forEach(item => wrapper.appendChild(item))
  }

  addMoreItem = (e) => {
    if (e) e.preventDefault()
    let newNumber = parseInt(this.state.currentNumber) + 1
    let wrapper = document.getElementById('course_lecturer')
    let newItem = this.instructorSequenceItemTemplate(newNumber)
    wrapper.insertAdjacentHTML('beforeend', newItem)
    // add new event to last added element
    let recentAddedItem = wrapper.querySelector(`:scope > .sequence-item[data-number="${newNumber}"]`)
    this.addButtonEventToItem(recentAddedItem)
    this.setState({ currentNumber: newNumber, editFirstItem: true })
  }

  addButtonEventToItem = (el) => {
    let wrapper = document.getElementById('course_lecturer')
    let removeButton = el.querySelector('.remove-sequence')
    let moveButton = el.querySelectorAll('.move-sequence')
    // remove event
    removeButton.addEventListener('click', () => {
      el.remove()
      let lastItemNumber = this.reOrderItems()
      wrapper.querySelectorAll('.sequence-item').length <= 1
        ? this.setState({ editFirstItem: false })
        : this.setState({ editFirstItem: true })
      this.setState({ currentNumber: lastItemNumber })
    })
    // move event
    moveButton.forEach(button => {
      button.addEventListener('click', () => {
        let thisSequence = parseInt(el.dataset.number)
        let direction = button.dataset.direction
        let newSequence = direction === 'up' ? thisSequence - 1 : thisSequence + 1
        let targetRow = direction === 'up' ? el.previousElementSibling : el.nextElementSibling
        // if target existed, swap the number
        if (targetRow) {
          targetRow.setAttribute('data-number', thisSequence)
          el.setAttribute('data-number', newSequence)
          this.sortItems()
        }
      })
    })
  }
  
  render () {
    return (
      <React.Fragment>
        <div className="sequence-wrapper" id="course_lecturer">
          {
            this.state.items.length > 0
            ? this.state.items.map( (item,index) => {
              let number = index + 1
              return (
                <div className="sequence-item" data-number={number} key={number}>
                  <div className="_action">
                    <button type="button" className="remove-sequence"><i className="icon icon-close"></i></button>
                  </div>
                  <div className="_form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">ชื่อวิทยากร {number === 1 ? <span className="req">*</span> : ''}</label>
                          <input type="text" className="form-control lecturer-name" id={number === 1 ? "course_lecturer_name" : ""} defaultValue={item.name} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">ตำแหน่งวิทยากร</label>
                          <input type="text" className="form-control lecturer-title" defaultValue={item.title} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            } )
            : ReactHtmlParser(this.instructorSequenceItemTemplate(1))
          }
        </div>
        <div className="sequence-add-item">
          <button className="btn btn-block add-sequence-item" type="button" onClick={(e) => this.addMoreItem(e)}>
            <i className="icon icon-plus"></i> เพิ่มวิทยากรใหม่
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SequenceInstructor
