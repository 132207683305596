import React from "react"
import Slider from "react-slick"
import imagesLoaded from 'imagesloaded'
import axios from 'axios'

import { alertMessage } from './../helper/utils'

class DetailsSlider extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      saved: (this.props.saved && this.props.saved.length > 0) ? this.props.saved : []
    }
  }

  componentDidMount() {
    let preloaderBlock = document.querySelector('.preloader-block')
    if (preloaderBlock) {
      preloaderBlock.classList.add('active')
      imagesLoaded( document.querySelector('.details-gallery-block'), function(i) {
        preloaderBlock.classList.remove('active')
      })
    }
  }

  toggleFavourite = (type, id, e) => {
    if (e) e.preventDefault()
    let button = e.currentTarget
    axios.post('/listings/favourite', { listing_type: type, listing_id: id })
    .then((response) => {
      if (response.data.status === 200) {
        button.classList.contains('active') ? button.classList.remove('active') : button.classList.add('active')
      } else {
        alertMessage('ไม่สามารถบันทึกประกาศนี้ได้ กรุณาลองอีกครั้ง')
      }
    })
    .catch(err => console.log(err))
  }

  render () {
    let sliderSettings = {
      dots: true,
      arrows: true,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 6000,
      focusOnSelect: false,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1
    }
    let isSaved = this.state.saved.includes(this.props.listingId)
    return (
      <React.Fragment>
        <Slider {...sliderSettings}>
          {this.props.cover ? <div><img src={this.props.cover} alt="Copyright by accountingcenter.co" /></div> : null }
          {
            this.props.gallery && this.props.gallery.length > 0
              ? this.props.gallery.map((img, i) => <div key={i}><img src={img} alt="Copyright by accountingcenter.co" /></div> )
              : null
          }
        </Slider>
        {/* favourite button */}
        <div className="details-favourite-button">
          <a href="#" className={`${isSaved ? "active" : ""} ${this.props.currentUserId ? "" : "go-login"}`} onClick={e => this.props.currentUserId ? this.toggleFavourite(this.props.listingType, this.props.listingId, e) : false}>
            <i className="icon icon-heart"></i>
            <span>บันทึก</span>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default DetailsSlider