import React from "react"

import { isEmailValid, alertMessage } from './../helper/utils'

class RegisterModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      province: '',
      csrfToken: ''
    }
  }

  componentDidMount() {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    this.setState({ csrfToken })
  }

  handleChange = (e) => {
    let inputName = e.target.dataset.id
    this.setState({ [inputName]: e.target.value })
  }

  togglePassword = (e) => {
    let button = e.target
    let group = button.closest('.form-group')
    let passwordField = group.querySelector('input[data-id="password"]')
    if (passwordField.type === 'password') {
      passwordField.setAttribute('type', 'text')
      button.classList.add('active')
    } else {
      passwordField.setAttribute('type', 'password')
      button.classList.remove('active')
    }
  }

  submitForm = (e) => {
    let form = document.getElementById('register_form')
    let consent = document.getElementById('consent_terms')
    let popup = document.getElementById('register_modal')
    let preloader = popup.querySelector('.content-preloader')
    if (!consent.checked) {
      alertMessage('กรุณาติ๊กยอมรับข้อตกลงการใช้บริการของเราก่อนสมัคร')
      return false;
    }
    if (!this.state.email) {
      alertMessage('กรุณาระบุ อีเมล์ ของคุณ')
      return false;
    }
    if ( !isEmailValid(this.state.email) ) {
      alertMessage('รูปแบบอีเมล์ที่คุณกรอกไม่ถูกต้อง')
      return false;
    }
    if (!this.state.password || this.state.password.length < 6) {
      alertMessage('กรุณาระบุ รหัสผ่านอย่างน้อย 6 ตัวอักษร')
      return false;
    }
    if (!this.state.first_name) {
      alertMessage('กรุณาระบุ ชื่อจริง ของคุณก่อน')
      return false;
    }
    if (!this.state.last_name) {
      alertMessage('กรุณาระบุ นามสกุล ของคุณก่อน')
      return false;
    }
    preloader.classList.add('active')
    form.submit()
  }

  submitFacebookButton = (e) => {
    if (e) e.preventDefault()
    let form = document.getElementById('register_facebook_form')
    form.submit()
  }

  render () {
    return (
      <div className="jd-modal-block" id="register_modal">
        <div className="modal-white-box large">
          <div className="content-preloader">
            <div className="_content-backdrop"></div>
            <img src="/img/preloader.gif" alt="Preloader" />
          </div>
          <a href="#" className="close-modal-button" title="ปิด">
            <i className="icon icon-close"></i>
          </a>
          <div className="modal-wrapper">
            <h3 className="modal-title text-left">สมัครสมาชิก</h3>
            <div className="modal-action-pre-form">
              <a href="#" className="btn btn-facebook" onClick={e => this.submitFacebookButton(e)}>
                <i className="icon icon-facebook"></i> สมัครสมาชิกด้วย Facebook
              </a>
            </div>
            <form action="/registration" method="post" className="form devise-form" id="register_form">
              <input type="hidden" name="authenticity_token" value={this.state.csrfToken} />
              <div className="form-row">
                <div className="form-cols">
                  <div className="form-group form-label-upper">
                    <label htmlFor="" className="control-label">
                      อีเมล์ <i className="icon icon-user"></i> <span className="req">*</span> 
                    </label>
                    <input type="email" className="form-control" name="user[email]" autoComplete="off" onChange={(e) => this.handleChange(e)} value={this.state.email} data-id="email" />
                  </div>
                </div>
                <div className="form-cols">
                  <div className="form-group form-label-upper">
                    <label htmlFor="" className="control-label">
                      รหัสผ่าน <i className="icon icon-lock"></i> <span className="req">*</span> 
                    </label>
                    <input type="password" className="form-control" name="user[password]" onChange={(e) => this.handleChange(e)} value={this.state.password} data-id="password" />
                    <button type="button" className="toggle-password-button" onClick={this.togglePassword}>
                      <img src="/img/icon-eye.png" alt="toggle password" />
                    </button>
                  </div>
                </div>
                <div className="form-cols">
                  <div className="form-group form-label-upper">
                    <label htmlFor="" className="control-label">
                      ชื่อจริง <span className="req">*</span> 
                    </label>
                    <input type="text" className="form-control" id="first_name" name="user[first_name]" autoComplete="off" onChange={(e) => this.handleChange(e)} value={this.state.first_name} data-id="first_name" />
                  </div>
                </div>
                <div className="form-cols">
                  <div className="form-group form-label-upper">
                    <label htmlFor="" className="control-label">
                      นามสกุล <span className="req">*</span> 
                    </label>
                    <input type="text" className="form-control" id="last_name" name="user[last_name]" autoComplete="off" onChange={(e) => this.handleChange(e)} value={this.state.last_name} data-id="last_name" />
                  </div>
                </div>
                <div className="form-cols">
                  <div className="form-group form-label-upper">
                    <label htmlFor="" className="control-label">
                      ประเภทผู้ใช้
                    </label>
                    <select name="user[use_type]" data-id="use_type" className="form-control">
                      <option value="provider">นักบัญชี / ผู้ให้บริการ</option>
                      <option value="consumer">ผู้ประกอบการ / ผู้ค้นหา</option>
                    </select>
                  </div>
                </div>
                <div className="form-cols">
                  <div className="form-group form-label-upper">
                    <label htmlFor="" className="control-label">
                      เบอร์โทร
                    </label>
                    <input type="text" className="form-control" id="phone_number" name="user[phone_number]" onChange={(e) => this.handleChange(e)} value={this.state.phone_number} data-id="phone_number" />
                  </div>
                </div>
                <div className="form-cols">
                  <div className="form-group form-label-upper">
                    <label htmlFor="" className="control-label">
                      จังหวัด
                    </label>
                    <select name="user[province]" id="province" className="form-control" onChange={(e) => this.handleChange(e)} defaultValue="" data-id="province">
                      <option value="" disabled></option>
                      {
                        this.props.provinces 
                          ? this.props.provinces.map(province => <option key={province.id} value={`${province.name}`}>{province.name}</option>)
                          : null
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <input type="checkbox" name="consent_terms" id="consent_terms" className="jd-checkbox" />
                <label htmlFor="consent_terms" className="jd-checkbox-label large">
                  ฉันได้อ่าน และจะยอมรับ <a href="/terms" title="ข้อตกลงการใช้บริการ" target="_blank" style={{textDecoration: "underline"}}>ข้อตกลงการใช้บริการ</a> ของเว็บไซต์ accountingcenter.co
                </label>
              </div>
              <div className="form-group action-group">
                <button className="btn btn-secondary" type="button" onClick={this.submitForm}>สมัครสมาชิก</button>
              </div>
              <div className="modal-footer-form">
                <p>หากมีบัญชีสมาชิกอยู่แล้ว <a href="#" className="go-login text-orange" title="เข้าสู่ระบบ">เข้าสู่ระบบ</a> ที่นี่</p>
                
              </div>
            </form>
            <form action="/auth/facebook" method="post" id="register_facebook_form" style={{display: 'none'}}>
              <input type="hidden" name="authenticity_token" value={this.state.csrfToken} />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterModal
